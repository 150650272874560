










































import Vue from 'vue'

// Don't convert this vue component to class base component. cause it can not build recursive component
export default Vue.extend({
  name: 'TextObjectTable',
  props: {
    textObject: {
      type: Object,
      default: () => {
        return {}
      }
    },
    useFirstPropAsTitle: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    hasObjectOnly (): boolean {
      return Object.keys(this.textObject).every(prop => {
        return typeof this.textObject[prop] === 'object'
      })
    }
  },
  methods: {
    isObject (textOrObject: string | Dictionary<any>): boolean {
      return !!textOrObject && typeof textOrObject === 'object' && !!Object.keys(textOrObject).length
    }
  }
})
