




















































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { WalletPresentation } from '@/presentation'
import { ElMessageOptions } from 'element-ui/types/message'
import { IWalletPresentation } from '@/presentation/wallet/model/IWalletPresentation'

@Component
export default class AdjustWalletBalance extends Vue {
  @Prop(String) corpId!: string

  presentation: IWalletPresentation = new WalletPresentation()

  isReady = false

  get isInitialized () {
    return this.isReady && this.presentation.wallet
  }

  get memoGuideLabel (): string {
    const email = localStorage.getItem('email') || ''
    return `금액 조정 진행 시 관리자 계정이 기록됩니다 (${email})`
  }

  get walletOperation (): string {
    return this.presentation.adjustWalletField.isSubtract ? '-' : '+'
  }

  onClickMax () {
    this.presentation.adjustWalletField.balance = this.presentation.wallet?.balance || 0
  }

  onClickSwitchDisabled () {
    this.$message({ type: 'info', message: '현재 월렛 증가는 불가능합니다.' })
  }

  async onClickAdjustWallet () {
    if (!Number(this.presentation.adjustWalletField.balance)) {
      this.$message({ type: 'info', message: '금액을 확인해 주세요' })
      return
    }
    const adjustTypeMessage = this.presentation.adjustWalletField.isSubtract ? '차감' : '증가'
    const message = `${Number(this.presentation.adjustWalletField.balance).toLocaleString()} ${this.presentation.wallet?.currency} ${adjustTypeMessage}을 진행 하시겠습니까?`
    const isConfirm: boolean = await this.$confirm(message, {
      confirmButtonText: adjustTypeMessage,
      cancelButtonText: '취소',
      dangerouslyUseHTMLString: true,
      center: true
    }).then(() => true).catch(() => false)
    if (!isConfirm) return
    const isSuccessResponse = await this.presentation.refundFromWallet()
    const messageOption: ElMessageOptions = {
      type: isSuccessResponse ? 'success' : 'error',
      message: isSuccessResponse ? `월렛 ${adjustTypeMessage} 되었습니다.` : `월렛 ${adjustTypeMessage}에 실패하였습니다.`
    }
    if (isSuccessResponse) {
      await this.presentation.getWallet()
      this.presentation.adjustWalletField.balance = 0
      this.presentation.adjustWalletField.memo = ''
      this.$emit('refresh')
    }
    this.$message(messageOption)
    this.$emit('close')
  }

  onClickCancel () {
    this.$emit('close')
  }

  async created () {
    this.presentation.setCorpId(this.corpId)
    await this.presentation.getWallet()
    this.isReady = true
  }
}
