import { CorpActions } from '@/adapters/affiliate/CorpActions'
import { AffiliateDocument, BusinessCategory, CorpMemberPermission } from '@/adapters/affiliate/Corp'
import { DeleteDocuments } from '@/adapters/affiliate/DeleteDocuments'
import { GetDocuments } from '@/adapters/affiliate/GetDocuments'
import { AffiliatedDocumentType } from '@/enums/AffiliatedDocumentType'
import { CorpStatus } from '@/enums/CorpStatus'
import { koEnum } from '@/lokalise/enumLocale/ko'
import DataTableOption, { ButtonType } from '@/types/DataTableOption'
import dayjs from 'dayjs'
import { AffiliateCompanyTableElement } from './AffiliateCompany'
import SuperAffiliateList from './SuperAffiliateList'
import { Message } from 'element-ui'
import { SignUpDocumentFile, SignUpDocuments } from '@/adapters/affiliate/SignUpDocuments'
import { SetSignUpDocuments } from '@/adapters/affiliate/SetSignUpDocuments'
import { ApproveCorpParams } from '@/adapters/affiliate/model/ApproveCorpParams'

export default class AffiliateCompanyList extends SuperAffiliateList {
  tableData: Array<AffiliateCompanyTableElement> = []

  tableOptions: Dictionary<DataTableOption> = {
    corp_name: { type: 'link', label: 'Company Name', width: 100 },
    documents: { type: 'object', width: 700 },
    download_all: { type: 'button', label: '모두 다운로드' },
    created_at: { type: 'text', label: 'Registration Date' },
    representation_account: { type: 'text', label: 'Manager Account' },
    memo: { type: 'object', label: 'Memo' },
    confirm: { type: 'button', label: 'Approval' },
    deactivate: { type: 'button', label: 'Deactivate' }
  }

  private readonly corperationDocumentTypes = [
    AffiliatedDocumentType.USAGE_AGREEMENT,
    AffiliatedDocumentType.TRADE_CONFIRMATION,
    AffiliatedDocumentType.PRIVACY_AGREEMENT,
    AffiliatedDocumentType.BUSINESS_REGISTRATION,
    AffiliatedDocumentType.OWNER_ID_CARD,
    AffiliatedDocumentType.INVOICE_CONTRACT,
    AffiliatedDocumentType.CORP_ACCOUNT,
    AffiliatedDocumentType.CORP_CERTIFIED,
    AffiliatedDocumentType.CORP_SIGNATURE,
    AffiliatedDocumentType.CORP_SHAREHOLDERS
  ]

  private readonly entrepreneurDocumentTypes = [
    AffiliatedDocumentType.USAGE_AGREEMENT,
    AffiliatedDocumentType.TRADE_CONFIRMATION,
    AffiliatedDocumentType.PRIVACY_AGREEMENT,
    AffiliatedDocumentType.BUSINESS_REGISTRATION,
    AffiliatedDocumentType.OWNER_ID_CARD,
    AffiliatedDocumentType.INVOICE_CONTRACT,
    AffiliatedDocumentType.ENTRE_ACCOUNT,
    AffiliatedDocumentType.ENTRE_SIGNATURE
  ]

  private async setDocumentsSelected (): Promise<void> {
    const response = await GetDocuments.getInstance().request({ corp_id: this.selectedCorpId })
    this.selectedCorpDocuments = response.list
  }

  private async getDocument (type: AffiliatedDocumentType): Promise<AffiliateDocument | undefined> {
    const hasChangedCorpIdDocument = !this.selectedCorpDocuments.length || this.selectedCorpDocuments[0].corp_id !== this.selectedCorpId
    if (hasChangedCorpIdDocument) await this.setDocumentsSelected()
    return this.selectedCorpDocuments.find(doc => {
      return Number(doc.type) === Number(type)
    })
  }

  async deleteFile (type: AffiliatedDocumentType): Promise<void> {
    const document = await this.getDocument(type)
    if (!document || !document.file) return
    const id = Number(document.id)
    await DeleteDocuments.getInstance().request({ id, corp_id: document.corp_id })
    await this.initializeTableData()
  }

  async updateFile (file: any, type: AffiliatedDocumentType, fileName: string): Promise<void> {
    const corpId = this.selectedCorpId
    const document = await this.getDocument(type)
    if (!document || !document.id) throw new Error('can not find document')
    const base64File = await this.fileTransferHandler.blobToBase64(file)
    if (!base64File || typeof base64File !== 'string') throw new Error('there is no file has selected')
    const signUpDocumentFile: SignUpDocumentFile = {
      id: document.id || '0',
      corp_id: corpId,
      type: type,
      file_name: fileName,
      file: base64File
    }
    const isSuccessResponse = await this.setDocument(signUpDocumentFile)
    if (isSuccessResponse) {
      this.selectedCorpDocuments = []
      return
    }
    throw new Error()
  }

  async createFile (file: any, type: AffiliatedDocumentType, fileName: string): Promise<void> {
    const corpId = this.selectedCorpId
    const base64File = await this.fileTransferHandler.blobToBase64(file)
    if (!base64File || typeof base64File !== 'string') throw new Error('there is no file has selected')
    const signUpDocumentFile: SignUpDocumentFile = {
      corp_id: corpId,
      type: type,
      file_name: fileName,
      file: base64File
    }
    const isSuccessResponse = await this.setDocument(signUpDocumentFile)
    if (isSuccessResponse) {
      this.selectedCorpDocuments = []
      return
    }
    throw new Error()
  }

  async setDocument (signUpDocumentFile: SignUpDocumentFile): Promise<boolean> {
    const signUpDocumentsParams: SignUpDocuments = {
      corp_id: signUpDocumentFile.corp_id,
      list: [signUpDocumentFile]
    }
    const response = await SetSignUpDocuments.getInstance().request(signUpDocumentsParams)
    const isSuccessResponse = response.code === 200
    return isSuccessResponse
  }

  async downloadFile (type: AffiliatedDocumentType): Promise<void> {
    const document = await this.getDocument(type)
    if (!document || !document.file) return
    const blobUrl: string = this.fileTransferHandler.getBase64ObjectUrl(document.file)
    if (!blobUrl) return
    this.fileTransferHandler.download(blobUrl, document.file_name)
  }

  async viewFile (type: AffiliatedDocumentType): Promise<void> {
    const document = await this.getDocument(type)
    if (!document || !document.file) return
    const blobUrl: string = this.fileTransferHandler.getBase64ObjectUrl(document.file)
    if (!blobUrl) return
    this.fileTransferHandler.view(blobUrl)
  }

  setTableData () {
    const corpList = this.corpList.map(corp => {
      const documents: Dictionary<ButtonType> = {}
      let hasAllDocuments: boolean = true
      const isEntrepreneur = Number(corp.biz_category) === Number(BusinessCategory.ENTREPRENEUR)
      const documentTypes = isEntrepreneur ? this.entrepreneurDocumentTypes : this.corperationDocumentTypes
      documentTypes.forEach(type => {
        const documentName = koEnum[AffiliatedDocumentType[type]]
        const hasDocument = corp.documents.some(doc => {
          const isTypeMatched = Number(doc.type) === Number(type)
          return isTypeMatched && doc.file_name
        })
        hasAllDocuments = hasAllDocuments && hasDocument
        documents[`_popover_${AffiliatedDocumentType[type]}`] = {
          label: documentName,
          outerClass: 'document-column',
          class: !hasDocument ? 'empty-document' : '',
          buttons: [
            {
              icon: 'el-icon-view',
              disabled: !hasDocument,
              callback: async () => {
                this.selectedCorpId = corp.corp_id
                await this.viewFile(type)
              }
            },
            {
              icon: 'el-icon-download',
              disabled: !hasDocument,
              callback: async () => {
                this.selectedCorpId = corp.corp_id
                await this.downloadFile(type)
              }
            },
            {
              label: 'upload',
              icon: 'el-icon-upload2',
              callback: async (files: any) => {
                this.selectedCorpId = corp.corp_id
                const invalidFile = !(files && files.raw)
                if (invalidFile) return
                try {
                  const file = files.raw
                  const fileName = files.name
                  if (hasDocument) await this.updateFile(file, type, fileName)
                  else await this.createFile(file, type, fileName)
                } catch (error) {
                  Message({ message: error.message, type: 'error' })
                  return
                }
                Message({ message: '업로드 되었습니다.', type: 'success' })
                this.initializeTableData()
              }
            },
            {
              icon: 'el-icon-delete',
              disabled: !hasDocument,
              callback: async () => {
                this.selectedCorpId = corp.corp_id
                const message = `${corp.name}의 ${documentName}을 삭제하시겠습니까?`
                const isConfirm: boolean = await this.confirm(message, {
                  confirmButtonText: '서류 삭제하기',
                  cancelButtonText: '취소',
                  dangerouslyUseHTMLString: true,
                  center: true
                }).then(() => true).catch(() => false)
                if (!isConfirm) return
                await this.deleteFile(type)
              }
            }
          ]
        }
      })
      const memoCallback = () => {
        this.selectedCorpId = corp.corp_id
        this.corpMemoModalContent = corp.memo
        this.isCorpMemoModalOpen = true
      }
      const buttonMemo: ButtonType = {
        icon: 'el-icon-plus',
        size: 'mini',
        circle: true,
        plain: true,
        callback: memoCallback
      }
      const textMemo: ButtonType = {
        size: 'mini',
        type: 'text',
        label: corp.memo,
        class: 'memo',
        callback: memoCallback
      }
      const memo: Dictionary<ButtonType> = corp.memo ? { _link: textMemo } : { _button: buttonMemo }
      const hasAdministrator = corp.members.some(member => {
        return Number(member.permission) === Number(CorpMemberPermission.ADMIN)
      })
      const hasAllFields: boolean = !!corp.base_currency && !!corp.symbol && !!corp.fees[0]?.country && !!corp.biz_item && !!corp.biz_type && !!corp.assigned_virtual_account
      const validateAll: boolean = hasAllDocuments && hasAdministrator && hasAllFields
      return {
        corp_name: {
          label: corp.name,
          callback: () => {
            this.selectedCorpId = corp.corp_id
            this.isCorpModalOpen = true
          }
        },
        documents,
        created_at: dayjs(corp.created_at).format('YYYY-MM-DD'),
        representation_account: hasAdministrator ? 'O' : 'X',
        memo,
        confirm: {
          label: 'Approval',
          disabled: !(validateAll && corp.status !== Number(CorpStatus.CONFIRMED)),
          callback: async () => {
            const message = `${corp.name} 승인하시겠습니까? Approve?`
            const isConfirm: boolean = await this.confirm(message, {
              confirmButtonText: 'Approve',
              cancelButtonText: 'Cancel',
              dangerouslyUseHTMLString: true,
              center: true
            }).then(() => true).catch(() => false)
            if (!isConfirm) return
            this.selectedCorpId = corp.corp_id
            const params: ApproveCorpParams = { id: corp.corp_id, base_currency: corp.base_currency }
            const response = await CorpActions.getInstance().approve(params)
            if (response.code !== 200) return
            this.message({ message: 'Successfully completed', type: 'success' })
            await this.initializeTableData()
          }
        },
        deactivate: {
          label: 'Deactivate',
          disabled: corp.status === Number(CorpStatus.CONFIRMED),
          callback: async () => {
            const message = `${corp.name}을 비활성화 하시겠습니까? Deactivate?`
            const isConfirm: boolean = await this.confirm(message, {
              confirmButtonText: 'Deactivate',
              cancelButtonText: 'Cancel',
              dangerouslyUseHTMLString: true,
              center: true
            }).then(() => true).catch(() => false)
            if (!isConfirm) return
            this.selectedCorpId = corp.corp_id
            const response = await CorpActions.getInstance().deactivate(corp.corp_id)
            const isSuccess = response.code === 200
            const messageType = isSuccess ? 'success' : 'error'
            const popupMessage = isSuccess ? 'Successfully completed' : 'Failed'
            this.message({ message: popupMessage, type: messageType })
            await this.initializeTableData()
          }
        }
      }
    })
    this.tableData = Array(...corpList)
  }

  async setRadioOptionsSuffix () {
    for (let index = 0; index < this.radioOptions.length; index++) {
      const response = await this.getSearchCorp(this.radioOptions[index].value)
      this.radioOptions[index].label = this.radioLabelList[index] + `(${response.count}건)`
    }
  }

  async initializeTableData (): Promise<void> {
    const response = await this.getSearchCorp(this.statusFormField.status)
    this.totalCount = response.count
    this.corpList = response.list
    await this.setTableData()
    await this.setRadioOptionsSuffix()
  }
}
