









import Vue from 'vue'
import LayoutSideButton from '@/dome/components/fraudTransactions/buttons/LayoutSideButton.vue'
import { SheetHandler } from '@/lib/SheetHandler'
import { mapActions, mapGetters } from 'vuex'
import {
  FraudTransactionActions,
  FraudTransactionGetters
} from '@/dome/store/fraudTransactionState'
import { IFraudTransaction } from '@/dome/types/FraudTypes'
import dayjs from 'dayjs'
import { locales } from '@/dome/constants/locales'
import { Dictionary } from 'vue-router/types/router'

export default Vue.extend({
  name: 'FraudExcelContainer',
  components: { LayoutSideButton },
  computed: {
    ...mapGetters('fraudTransactionState', {
      transactions: FraudTransactionGetters.FraudTransactionsForSheet
    })
  },
  methods: {
    ...mapActions('fraudTransactionState', {
      getTransactionsForSheet: FraudTransactionActions.FetchFraudTransactionsForSheet
    }),
    convertTransactionSheet (): Array<Dictionary<string | number>> {
      const columnKeys = Object.keys(locales.transactionColumns)
      return this.transactions.map((transaction: IFraudTransaction) => {
        const fraudScore = Math.round(transaction.fraud_score)
        const systemStatus = locales.systemStatus[transaction.system_status]
        const fraudStatus = locales.fraudStatus[transaction.fraud_status]
        const createdAt = dayjs(transaction.created_at).format('YYYY-MM-DD HH:mm')
        const paymentType = locales.payment_type[transaction.payment_type]
        const sheetTransaction: Dictionary<string | number> = {
          ...transaction,
          fraud_score: fraudScore,
          system_status: systemStatus,
          fraud_status: fraudStatus,
          created_at: createdAt,
          payment_type: paymentType
        }
        const sheetJson: Dictionary<string | number> = {}
        columnKeys.forEach(key => {
          const koreanColumnName = locales.transactionColumns[key]
          sheetJson[koreanColumnName] = sheetTransaction[key]
        })
        return sheetJson
      })
    },
    async onDownloadExcel () {
      await this.getTransactionsForSheet()
      if (!this.transactions.length) {
        alert('다운로드할 거래내역이 없습니다.')
      }
      const sheetHandler = SheetHandler.getInstance()
      sheetHandler.sheetJson = { transactions: this.convertTransactionSheet() }
      const dateTime = dayjs().format('YYMMDD_HHmm')
      sheetHandler.saveSheetByJson(`transactions_${dateTime}`, 'xlsx')
    }
  }
})
