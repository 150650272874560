import { GetRemittanceBase } from '@/adapters/remittanceBase/GetRemittanceBase'
import Country from '@/enums/Country'

export class ReceivableCountries {
  private static receivableCountries: Array<keyof typeof Country> = []
  public static async get(): Promise<Array<keyof typeof Country>> {
    if (!this.receivableCountries.length) {
      const remittanceBaseList = await GetRemittanceBase.getInstance().request()
      if (!remittanceBaseList || remittanceBaseList.length) return this.receivableCountries
      this.receivableCountries = remittanceBaseList.map(remittanceBase => {
        return remittanceBase.country
      })
    }
    return this.receivableCountries
  }
}
