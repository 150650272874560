





















import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { FraudTransactionActions, FraudTransactionGetters } from '@/dome/store/fraudTransactionState'
import { IFraudCorp } from '@/dome/types/FraudTypes'

export default Vue.extend({
  name: 'SelectCorpContainer',
  data () {
    return {
      selectedCorp: 0
    }
  },
  computed: {
    ...mapGetters('fraudTransactionState', {
      fraudCorps: FraudTransactionGetters.Corps
    }),
    corps (): Array<IFraudCorp> {
      return this.fraudCorps
    }
  },
  async created () {
    await this.getFraudCorps()
  },
  methods: {
    ...mapActions('fraudTransactionState', {
      getFraudCorps: FraudTransactionActions.FetchCorps,
      updateCorpId: FraudTransactionActions.UpdateCorpId
    }),
    onChangeCorp (value: number) {
      this.selectedCorp = value
      this.updateCorpId(this.selectedCorp)
    }
  }
})
