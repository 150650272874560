import { koEnum } from '@/lokalise/enumLocale/ko'

const numberFormatter = (source: number | string): string => {
  if (typeof source === 'string' && !parseFloat(source)) return source
  source = typeof source === 'number' ? source : parseFloat(source)
  return (source).toLocaleString()
}

const countryWithMethodFormatter = (source: string, showMethod = false): string => {
  const countryWithMethod: Array<string> = source.split('_')
  const country: string = countryWithMethod[0]
  const method: string = source.replace(`${country}_`, '')
  const formats = {
    country,
    method
  }
  return `${formats.country} ${showMethod ? formats.method : ''}`
}

export const formattersMap: Dictionary<any> = {
  number: numberFormatter,
  countryWithMethod: countryWithMethodFormatter
}

export const getEnumValueByKey = (enumerator: any, key: string): any => {
  const enumKeys = Object.keys(enumerator)
  const half = enumKeys.length / 2
  const valueKey = enumKeys.findIndex(value => value === key)
  return enumKeys[valueKey - half]
}

export const getEnumValueArray = (enumerator: any): Array<any> => {
  const enumKeys = Object.keys(enumerator)
  return enumKeys.filter(key => !isNaN(Number(key)))
}

export const getEnumOptions = (enumerator: any, enumArray: Array<any>, isPropAsValue = false, useLocaleLabel = true) => {
  return enumArray.map(enumItem => {
    const value = isPropAsValue ? enumerator[enumItem] : enumItem
    const label = useLocaleLabel ? koEnum[enumerator[enumItem]] || enumerator[enumItem] : enumerator[enumItem]
    return { label, value }
  })
}

export const removeEmptyData = (data: any): any => {
  if (!data) {
    return null
  } else if (typeof data === 'object') {
    if (Array.isArray(data)) {
      return data.filter(child => {
        if (typeof child === 'object') child = removeEmptyData(child)
        return !!child
      })
    }
    const deleteKeys: Array<string> = []
    Object.keys(data).forEach(prop => {
      data[prop] = removeEmptyData(data[prop])
      if (!data[prop]) deleteKeys.push(prop)
    })
    deleteKeys.forEach(key => delete data[key])
    return data
  } else {
    return data
  }
}
