import { IApiRequest } from '@/adapters/axios/IApiRequest'
import { sentbizApi } from '@/adapters/axios'

export interface ResendSignUpEmailParams {
  id: string
  temp_email: string
}

export interface ResendSignUpEmail extends IApiRequest {
  getInstance (): ResendSignUpEmail
  request (params: ResendSignUpEmailParams): Promise<any>
}
export class ResendSignUpEmail implements ResendSignUpEmail {
  private static instance: ResendSignUpEmail
  private constructor () {}
  public static getInstance (): ResendSignUpEmail {
    if (!this.instance) this.instance = new ResendSignUpEmail()
    return this.instance
  }

  async request (params: ResendSignUpEmailParams): Promise<any> {
    const requestOption: RequestOption = {
      url: 'hades/guidance',
      method: 'put',
      data: params
    }
    const response = await sentbizApi.request(requestOption)
    return response
  }
}
