









import Vue, { PropType } from 'vue'
import { IDomeTransactionalTables } from '@/dome/types/IDomeTransactionalInfo'
import DetailsInfoTable from '@/dome/components/DomeTransactionalInfo/DetailsInfoTable.vue'
import MemoTimelineSection from '@/dome/container/DomeTransactionalInfo/MemoTimelineSection.vue'

export default Vue.extend({
  components: { DetailsInfoTable, MemoTimelineSection },
  props: {
    tableData: {
      type: Object as PropType<IDomeTransactionalTables>,
      required: true
    }
  }
})
