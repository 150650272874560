import { Remittance } from '@/types'
import { RecipientWithComment } from '@/types/Recipient'
import { RootState } from '@/types/RootState'
import { Message } from 'element-ui'
import { Module } from 'vuex'

interface TransfersState {
  transferDetailModal: boolean
  transferDetailField: Dictionary<RecipientWithComment>
  textObjectStatus: {
    textObjectTitle: string
    textObjectContent: Dictionary<any>
    textObjectModal: boolean
  }
  clickedTransferDetailId: string
}

export const transfersStore: Module<TransfersState, RootState> = {
  state: {
    transferDetailModal: false,
    transferDetailField: {},
    textObjectStatus: {
      textObjectTitle: '',
      textObjectContent: {},
      textObjectModal: false
    },
    clickedTransferDetailId: ''
  },
  mutations: {
    showTransferDetailModal(state, detailPayload: { transferId: string; remittanceList: Array<Remittance> }) {
      state.clickedTransferDetailId = detailPayload.transferId
      const transferDetail: Remittance | undefined = detailPayload.remittanceList.find(remittance => {
        return remittance.id === detailPayload.transferId
      })
      if (!transferDetail) return Message('수취인 정보가 없습니다.')
      state.clickedTransferDetailId = transferDetail.id
      const transferDetailField = {
        detail: {
          ...transferDetail.recipient,
          analogue_comment: transferDetail.analogue_comment
        }
      }
      state.transferDetailField = transferDetailField
      state.transferDetailModal = true
    },
    closeTransferDetailModal(state) {
      state.transferDetailModal = false
    },
    closeTextObjectContent(state) {
      state.textObjectStatus.textObjectModal = false
    },
    setClickedTransferDetailId(state, value: string) {
      state.clickedTransferDetailId = value
    },
    setTextObjectContent(state, settingTextObjectParam: { title: string; content: Dictionary<any>; modal: boolean }) {
      state.textObjectStatus.textObjectTitle = settingTextObjectParam.title
      state.textObjectStatus.textObjectContent = settingTextObjectParam.content
      state.textObjectStatus.textObjectModal = settingTextObjectParam.modal
    }
  },
  actions: {},
  modules: {},
  getters: {
    getTransferDetailModal(state) {
      return state.transferDetailModal
    },
    getTransferDetailField(state) {
      return state.transferDetailField
    },
    getClickedTransferDetailId(state) {
      return state.clickedTransferDetailId
    },
    getTextObjectStatus(state) {
      return state.textObjectStatus
    }
  }
}
