import GroupRemittanceStatus from '@/types/apiRequest/GroupRemittanceStatus'
import { sentbizApi } from '../axios'

export interface IUpdateGroupRemittanceStatus {
  request (params: GroupRemittanceStatus): Promise<any>
}
export class UpdateGroupRemittanceStatus implements IUpdateGroupRemittanceStatus {
  private static instance: UpdateGroupRemittanceStatus
  private constructor () {}

  public static getInstance (): UpdateGroupRemittanceStatus {
    if (!this.instance) this.instance = new UpdateGroupRemittanceStatus()
    return this.instance
  }

  async request (params: GroupRemittanceStatus): Promise<any> {
    const option: RequestOption = {
      url: '/hades/remittance/group/status',
      method: 'put',
      data: params
    }
    const response: any = await sentbizApi.request(option)
    return response
  }
}
