








import { Vue, Component, Prop } from 'vue-property-decorator'
import RouterOption from '@/types/RouterOption'

@Component
export default class MenuItem extends Vue {
  @Prop(String) readonly path!: string
  @Prop(Object) readonly nav!: RouterOption

  get getPath (): string {
    const path: string = this.path || this.nav.path
    return path.replace('/:', '/')
  }

  get suffix (): string {
    return this.nav.suffix ? ` (${this.nav.suffix})` : ''
  }
}
