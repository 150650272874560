



























































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import SearchComponent from '@/components/shared/SearchComponent.vue'
import BatchAction from '@/components/shared/BatchAction.vue'
import DataTable from '@/components/shared/DataTable.vue'
import { getEnumValueByKey } from '@/lib/Utils'
import AdminMenuTypeStatusGroup from '@/enums/AdminMenuTypeStatusGroup'
import { Remittance } from '@/types'
import FormItems from '@/components/shared/FormItems.vue'
import DataTableOption from '../types/DataTableOption'
import SearchAmountModal from '@/components/shared/SearchAmountModal.vue'
import { RemittanceByGroup, TableElementByGroup } from '@/types/RemittanceByGroup'
import dayjs from 'dayjs'
import RemittanceGroupSearch from '../types/apiRequest/RemittanceGroupSearch'
import FxIdModal from '@/components/shared/FxIdModal.vue'
import GroupRemittanceStatus from '../types/apiRequest/GroupRemittanceStatus'
import { TransactionReceiptHandler, TransactionReceiptParam } from '@/lib/pdf/cert/TransactionReceiptHandler'
import { Decimal } from 'decimal.js'
import { FxRates } from '@/adapters/fxRates/FxRates'
import StatusType from '@/enums/RemittanceStatusType'
import { RemittanceGroupDetail, RemittanceGroupDetailParams } from '@/adapters/remittance/RemittanceGroupDetail'
import { GetFxRates } from '@/adapters/fxRates/GetFxRates'
import { CorpInformation } from '@/types/CorpInformation'
import { GetCorpInformation } from '@/adapters/corp/GetCorpInformation'
import { KrwFxRates } from '@/adapters/fxRates/KrwFxRates'
import { UpdateGroupRemittanceStatus } from '@/adapters/trasnferGroups/UpdateGroupRemittanceStatus'
import { SearchGroupTransfer } from '@/adapters/trasnferGroups/SearchGroupTransfers'
import {
  CurrencyBalance,
  TransactionCurrencyReceiptParam,
  TransactionReceiptCurrencyHandler
} from '@/lib/pdf/cert/TransactionReceiptCurrencyHandler'
import { CalculateResult } from '@/lib/calculator/CalculateResult'
import { CalculatorSourceType, Calculator, ICalculatorSource } from '@/lib/calculator/Calculator'


interface ListCountSelectorTypes {
  TEN: number,
  THIRTY: number,
  FIFTY: number
}

@Component({
  components: { SearchComponent, BatchAction, DataTable, FormItems, SearchAmountModal, FxIdModal }
})
export default class TransferGroups extends Vue {
  doneStatusType: Array<number> = [
    StatusType.DONE,
    StatusType.INPROGRESS,
    StatusType.WAITING_TRANSFER,
    StatusType.RECEIVABLE,
    StatusType.REFUNDED,
    StatusType.WAITING_RECEIVE,
    StatusType.FAILED_TRANSFER
  ]

  ratesModal = false
  amountModal = false
  routeParam = ''

  remittanceList: Array<RemittanceByGroup> = []
  tableData: Array<TableElementByGroup> = []

  transferGroupTitles: Dictionary<string> = {
    waiting: '입금 대기 (Status 00)',
    ongoing: '진행중(완료 대기)',
    cancel: '취소',
    expired: '만료',
    finish: '완료 (송금완료&환불됨)'
  }

  transferStatusGroup: Dictionary<any> = {
    confirm: 2,
    cancel: 3
  }

  selectedTransfers: Array<TableElementByGroup> = []

  tableOptions: Dictionary<DataTableOption> = {
    group_code: { type: 'link', label: '송금 그룹 ID' },
    fx_task_id: { type: 'link', label: 'Fx Id' },
    affiliate: { type: 'object', label: '고객사' },
    base_amount: { type: 'object', label: '기준금액 합계' },
    total_fee: { type: 'text', label: '수수료' },
    total_amount: { type: 'object', label: '입금 금액' },
    created_at: { type: 'text', label: '신청 시각' },
    payment_completed_at: { type: 'text', label: '입금 완료 시각' },
    total_count: { type: 'object', label: '송금 수' },
    status: { type: 'object', label: '송금 상태' },
    analogue: { type: 'object', label: '' }
  }

  unit = 10
  totalCount = 0
  fxId = ''

  public LIST_COUNT_SELECTOR_TYPES: ListCountSelectorTypes = {
    TEN: 10,
    THIRTY: 30,
    FIFTY: 50
  }    

  remittanceSearchParam: RemittanceGroupSearch = {
    status: getEnumValueByKey(AdminMenuTypeStatusGroup, this.routeParam),
    page: 1,
    unit: this.unit
  }

  setUnit (value: number):void {
    this.unit = value;
    this.remittanceSearchParam.unit = value;
  } 

  requestTransferWithChangedUnit(selectedValue:number):void {
    this.setUnit(selectedValue)
    this.requestTransfer()
  };

  refreshSearchParams() {
    this.remittanceSearchParam.page = 1
    this.remittanceSearchParam.group_code = undefined
    this.remittanceSearchParam.fx_task_id = undefined
    this.remittanceSearchParam.corp_id = undefined
    this.remittanceSearchParam.corp_name = undefined
    this.remittanceSearchParam.total_amount_from = undefined
    this.remittanceSearchParam.total_amount_to = undefined
  }

  getComma(amount: number) {
    return amount.toLocaleString()
  }

  getCurrencyRemittance(
    detailList: Array<Remittance>,
    statusType: StatusType | Array<StatusType>,
    rates: FxRates
  ): Dictionary<CurrencyBalance> {
    const currenciesBalance: Dictionary<CurrencyBalance> = {}
    const remittances: Array<Remittance> = Array.isArray(statusType)
      ? detailList.filter(element => statusType.includes(element.status_type))
      : detailList.filter(element => element.status_type === statusType)
    const calculator: Calculator = new Calculator(rates)
    remittances.forEach(remittance => {
      const calculatorSource: ICalculatorSource = {
        type: CalculatorSourceType.TRANSFER_CURRENCY_RECEIPT,
        remittanceHistoryGroupDetail: remittance
      }
      calculator.setSource(calculatorSource)
      const calculated: CalculateResult = calculator.calculate()
      const baseAmountCurrency = remittance.base_amount.currency
      if (!currenciesBalance[baseAmountCurrency]) {
        currenciesBalance[baseAmountCurrency] = {
          amount: 0,
          amountKrw: 0,
          feeKrw: 0,
          subTotalAmountKrw: 0,
          transactionNo: 0
        }
      }
      Decimal.set({ precision: 32 })
      const bigDecimalAmount: Decimal = new Decimal(currenciesBalance[baseAmountCurrency].amount)
      const bigDecimalCommission: Decimal = new Decimal(currenciesBalance[baseAmountCurrency].feeKrw)
      const bigDecimalSendAmount: Decimal = new Decimal(currenciesBalance[baseAmountCurrency].subTotalAmountKrw)
      const bigDecimalAmountKrw: Decimal = new Decimal(currenciesBalance[baseAmountCurrency].amountKrw)
      currenciesBalance[baseAmountCurrency].amount = bigDecimalAmount.plus(calculated.baseAmount).toNumber()
      currenciesBalance[baseAmountCurrency].feeKrw = bigDecimalCommission.plus(calculated.commission).toNumber()
      currenciesBalance[baseAmountCurrency].subTotalAmountKrw = bigDecimalSendAmount
        .plus(calculated.sendAmount)
        .toNumber()
      currenciesBalance[baseAmountCurrency].amountKrw = bigDecimalAmountKrw
        .plus(calculated.sendAmount)
        .minus(calculated.commission)
        .toNumber()
      currenciesBalance[baseAmountCurrency].transactionNo++
    })
    return currenciesBalance
  }

  async printCurrencyReceipt(remittanceByGroup: RemittanceByGroup, statusType: StatusType | Array<StatusType>) {
    const isRefundReceipt = statusType === StatusType.REFUNDED
    const typeMessage = isRefundReceipt ? '환불' : '송금 완료'
    const emptyComplete = !isRefundReceipt && !remittanceByGroup.completed_count
    const emptyRefunded = isRefundReceipt && !remittanceByGroup.refunded_count
    if (emptyComplete || emptyRefunded) {
      this.$message(`${typeMessage}된 내역이 없습니다.`)
      return
    }
    const corpInfo: CorpInformation = await GetCorpInformation.get({ corp_id: remittanceByGroup.corp_id.toString() })
    const params: RemittanceGroupDetailParams = { remittance_group_id: remittanceByGroup.group_id.toString() }
    const remittanceGroupDetailList: Array<Remittance> = await RemittanceGroupDetail.getInstance().request(params)
    const fxTaskId = remittanceByGroup.fx_task_id.toString()
    const fxRates: FxRates = await GetFxRates.get(fxTaskId)
    const krwFxRates: KrwFxRates = await GetFxRates.getKrwMidRatesV3(fxTaskId)
    const currenciesBalance: Dictionary<CurrencyBalance> = this.getCurrencyRemittance(
      remittanceGroupDetailList,
      this.doneStatusType,
      fxRates
    )
    const transactionReceiptParam: TransactionCurrencyReceiptParam = {
      corpInfo,
      currenciesBalance,
      krwFxRates,
      remittanceGroup: remittanceByGroup,
      fxTaskId: remittanceByGroup.fx_task_id.toString(),
      statusType
    }
    const transactionReceipt = new TransactionReceiptCurrencyHandler(transactionReceiptParam)
    await transactionReceipt.printDocument()
  }

  async printReceipt(remittanceGroup: RemittanceByGroup, statusType: StatusType | Array<StatusType>) {
    const isRefundReceipt = statusType === StatusType.REFUNDED
    const typeMessage = isRefundReceipt ? '환불' : '송금 완료'
    const isEmptyDeposit =
      !remittanceGroup.completed_count && !remittanceGroup.inprogress_count && !remittanceGroup.refunded_count
    const isEmptyCompleteProgress = !isRefundReceipt && isEmptyDeposit
    const isEmptyRefunded = isRefundReceipt && !remittanceGroup.refunded_count
    if (isEmptyCompleteProgress || isEmptyRefunded) {
      this.$message(`${typeMessage}된 내역이 없습니다.`)
      return
    }
    const corpInfo: CorpInformation = await GetCorpInformation.get({ corp_id: remittanceGroup.corp_id.toString() })
    const params: RemittanceGroupDetailParams = { remittance_group_id: remittanceGroup.group_id.toString() }
    const remittanceDetails: Array<Remittance> = await RemittanceGroupDetail.getInstance().request(params)
    const fxTaskId = remittanceGroup.fx_task_id.toString()
    const fxRates: FxRates = await GetFxRates.get(fxTaskId)
    const krwFxRates: KrwFxRates = await GetFxRates.getKrwMidRatesV3(fxTaskId)
    const transactionRemittances = remittanceDetails.filter(remittance => {
      if (Array.isArray(statusType)) return statusType.some(status => +status === +remittance.status_type)
      return +remittance.status_type === +statusType
    })
    const transactionReceiptParam: TransactionReceiptParam = {
      corpInfo,
      remittanceGroup,
      remittanceDetails: transactionRemittances,
      fxRates,
      krwFxRates,
      statusType
    }
    const transactionReceipt = new TransactionReceiptHandler(transactionReceiptParam)
    await transactionReceipt.printDocument()
  }

  convertRemittanceTableElement(remittanceByGroup: RemittanceByGroup, paramRoute: string): TableElementByGroup {
    let tableElementRemittance: TableElementByGroup = {
      group_id: remittanceByGroup.group_id,
      group_code: {
        label: remittanceByGroup.group_code,
        callback: () => {
          this.onClickGroupCode(remittanceByGroup.group_id)
        }
      },
      fx_task_id: {
        label: remittanceByGroup.fx_task_id,
        callback: () => {
          this.onClickFxId(remittanceByGroup.fx_task_id)
        }
      },
      affiliate: {
        id: remittanceByGroup.corp_id,
        name: remittanceByGroup.corp_name
      },
      base_amount: [],
      total_fee: `KRW ${this.getComma(remittanceByGroup.total_fee)}`,
      total_amount: {
        _text: `KRW ${this.getComma(remittanceByGroup.total_amount)}`
      },
      created_at: dayjs(remittanceByGroup.created_at).format('YYYY-MM-DD HH:mm'),
      total_count: {
        _text: remittanceByGroup.total_count.toString()
      }
    }
    if (paramRoute === 'ongoing') {
      tableElementRemittance.payment_completed_at = remittanceByGroup.payment_completed_at
        ? dayjs(remittanceByGroup.payment_completed_at).format('YYYY-MM-DD HH:mm')
        : ''
      tableElementRemittance.status = {
        inprogress_count: remittanceByGroup.inprogress_count,
        failed_count: remittanceByGroup.failed_count,
        completed_count: remittanceByGroup.completed_count,
        refunded_count: remittanceByGroup.refunded_count
      }
      tableElementRemittance.total_amount._button = {
        label: '입금 내역 영수증',
        callback: async () => {
          await this.printReceipt(remittanceByGroup, this.doneStatusType)
        }
      }
      tableElementRemittance.total_count._button = {
        label: '환 별 입금 내역 영수증',
        callback: async () => {
          await this.printCurrencyReceipt(remittanceByGroup, this.doneStatusType)
        }
      }
      if (remittanceByGroup.refunded_count && !remittanceByGroup.inprogress_count) {
        tableElementRemittance.status._button = {
          label: '환불 내역 영수증',
          callback: async () => {
            await this.printReceipt(remittanceByGroup, StatusType.REFUNDED)
          }
        }
      }
    } else if (paramRoute === 'finish') {
      tableElementRemittance.payment_completed_at = remittanceByGroup.payment_completed_at
        ? dayjs(remittanceByGroup.payment_completed_at).format('YYYY-MM-DD HH:mm')
        : ''
      tableElementRemittance.status = {
        completed_count: remittanceByGroup.completed_count,
        refunded_count: remittanceByGroup.refunded_count
      }
      tableElementRemittance.total_amount._button = {
        label: '입금 내역 영수증',
        callback: async () => {
          await this.printReceipt(remittanceByGroup, this.doneStatusType)
        }
      }
      tableElementRemittance.total_count._button = {
        label: '환 별 입금 내역 영수증',
        callback: async () => {
          await this.printCurrencyReceipt(remittanceByGroup, this.doneStatusType)
        }
      }
      if (remittanceByGroup.refunded_count) {
        tableElementRemittance.status._button = {
          label: '환불 내역 영수증',
          callback: async () => {
            await this.printReceipt(remittanceByGroup, StatusType.REFUNDED)
          }
        }
      }
    } else {
      const deadline: string = `(${dayjs(remittanceByGroup.created_at).add(6, 'hour').format('H:mm')})`
      tableElementRemittance.created_at = `${dayjs(remittanceByGroup.created_at).format(
        'YYYY-MM-DD HH:mm:ss'
      )} ${deadline}`
    }
    tableElementRemittance.base_amount = remittanceByGroup.base_amount.map(amount => {
      return `${amount.currency} ${this.getComma(amount.balance)}`
    })
    if (remittanceByGroup.analogue) {
      tableElementRemittance.analogue = {
        _tag: {
          label: 'analogue',
          type: 'danger',
          size: 'mini'
        }
      }
    }
    return tableElementRemittance
  }

  changedSelect(value: string) {
    if (value === 'byAmount') this.amountModal = true
  }

  onClickClose() {
    this.amountModal = false
  }

  onSelectRow(selectedRows: Array<TableElementByGroup>) {
    this.selectedTransfers = selectedRows
  }

  async updateRemittanceStatus(value: any) {
    const data: GroupRemittanceStatus = {
      group_info: this.selectedTransfers.map(transfer => {
        return Object.assign({}, { group_id: transfer.group_id, corp_id: transfer.affiliate.id })
      }),
      status: this.transferStatusGroup[value]
    }
    const response = await UpdateGroupRemittanceStatus.getInstance().request(data)
    const isSuccessResponse: boolean = response.code === 200
    if (!isSuccessResponse) return
    this.$message({ message: 'Update Complete.', type: 'success' })
    await this.requestTransfer()
  }

  onTransferStatusAction(value: any) {
    if (!this.isRemittanceSelected) return
    const message = `<span class="color-primary">${this.selectedTransfers.length}</span> rows you selected will be ${value}ed <br>Is it Right ?`
    this.$confirm(message, { dangerouslyUseHTMLString: true })
      .then(async () => {
        await this.updateRemittanceStatus(value)
      })
      .catch()
  }

  async onClickSearch(value: any) {
    this.remittanceSearchParam.total_amount_from = Number(value.inputFrom)
    this.remittanceSearchParam.total_amount_to = Number(value.inputTo)
    this.amountModal = false
    await this.requestTransfer()
  }

  onClickGroupCode(value: any) {
    this.$router.push({ path: '/transfers/all', query: { remittance_group_id: value } })
  }

  onClickFxId(value: any) {
    this.fxId = value
    this.ratesModal = true
  }

  onUnvisible() {
    this.ratesModal = false
  }

  async clickedSearch(clickedObject: Object) {
    this.remittanceSearchParam = Object.assign({}, this.remittanceSearchParam, clickedObject)
    await this.requestTransfer()
  }

  get isRemittanceSelected(): boolean {
    const isEmpty = !this.selectedTransfers.length
    if (isEmpty) this.$message('No Transfer has selected')
    return !isEmpty
  }

  get getTitle(): string {
    return `송금그룹별 조회 - ${this.transferGroupTitles[this.routeParam]}`
  }

  @Watch('$route.params', { immediate: true })
  async setDataResource(value: any) {
    const paramKey: string = Object.keys(value)[0]
    this.routeParam = value[paramKey]
    this.refreshSearchParams()
    await this.requestTransfer()
  }

  async onChangePage(page: number) {
    this.remittanceSearchParam.page = page
    await this.requestTransfer()
  }

  async requestTransfer(): Promise<void> {
    const paramRoute = this.routeParam
    const status = getEnumValueByKey(AdminMenuTypeStatusGroup, paramRoute.toUpperCase())
    const response = await SearchGroupTransfer.getInstance().request(
      Object.assign({}, this.remittanceSearchParam, { status })
    )
    const isSuccessResponse: boolean = response.code === 200
    if (!isSuccessResponse) return
    this.remittanceList = response.data.list
    this.tableData = this.remittanceList.map(remittance => {
      return this.convertRemittanceTableElement(remittance, paramRoute)
    })
    this.totalCount = response.data.total_count
  }
}
