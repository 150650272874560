import { FxRates } from '@/adapters/fxRates/FxRates'
import { KrwFxRates } from '@/adapters/fxRates/KrwFxRates'
import { CalculateResult } from '@/lib/calculator/CalculateResult'
import { formattersMap } from '@/lib/Utils'
import dayjs, { Dayjs } from 'dayjs'
import { CertDocumentPdfHandler } from './CertDocumentPdfHandler'
import { Remittance } from '@/types'
import { CorpInformation } from '@/types/CorpInformation'
import { CalculatorSourceType, Calculator, ICalculatorSource } from '@/lib/calculator/Calculator'

export interface IndividualReceiptParam {
  remittance: Remittance
  fxRates: FxRates
  krwFxRates: KrwFxRates
  corpInfo: CorpInformation
  isRefund: boolean
}

export class IndividualReceiptHandler extends CertDocumentPdfHandler {
  private readonly receiptParam: IndividualReceiptParam
  private readonly textContents: Dictionary<any>
  private readonly isRefund: boolean
  private receiptList: Array<Dictionary<any>>

  constructor(receiptParam: IndividualReceiptParam) {
    super('landscape')
    this.receiptParam = receiptParam
    this.isRefund = receiptParam.isRefund
    this.receiptList = this.makeReceiptTable()
    this.textContents = this.makeTextContents()
  }

  private makeReceiptTable(): Array<Array<string>> {
    const receiptDetail: Remittance = this.receiptParam.remittance
    const remittanceGroupCode = receiptDetail.group_code
    const isVersion4 = receiptDetail.version === 4
    const transactionDateAndTime: dayjs.Dayjs = dayjs(receiptDetail.created_at)
    const fxRates: FxRates = this.receiptParam.fxRates
    const krwFxRates: KrwFxRates = this.receiptParam.krwFxRates
    const calculatorSource: ICalculatorSource = {
      type: CalculatorSourceType.RECEIPT_INDIVIDUAL_RECEIPT,
      remittanceHistoryGroupDetail: receiptDetail
    }
    const calculator: Calculator = new Calculator(fxRates)
    calculator.setSource(calculatorSource)
    const calculated: CalculateResult = calculator.calculate()
    const baseAmountCurrency: string = receiptDetail.base_amount.currency
    const receiveAmountCurrency: string = receiptDetail.receive_amount.currency
    const sendCurrency = receiptDetail.send_amount.currency
    const tableFxRates: number =
      receiveAmountCurrency === 'KRW'
        ? 1
        : Number(krwFxRates[`${receiveAmountCurrency.toLowerCase()}_krw` as keyof KrwFxRates])
    const baseAmountBalance: number = calculated.baseAmount
    const fee: number = isVersion4 ? calculated.fixedCommission : calculated.commission
    const receiverFee = calculated.receiverCommissionSendCurrency
    const totalSendAmount: number = calculated.sendAmount
    const amountKrw = totalSendAmount - fee
    return [
      [
        'Transaction Date',
        transactionDateAndTime.format('YYYY-MM-DD HH:mm:ss'),
        'FX Rate',
        `${formattersMap.number(tableFxRates.toFixed(2))} ${receiveAmountCurrency}KRW`
      ],
      [
        'Transaction Group No.',
        remittanceGroupCode,
        'Sending',
        `${baseAmountCurrency} ${formattersMap.number(baseAmountBalance)}`
      ],
      ['Transaction No.', receiptDetail.id, 'Amount', `${sendCurrency} ${formattersMap.number(amountKrw)}`],
      [
        'Recipient Name',
        `${receiptDetail.recipient.first_name} ${receiptDetail.recipient.last_name}`,
        'Fee',
        `${sendCurrency} ${formattersMap.number(fee)}`
      ],
      [
        'Recipient Fee',
        `${sendCurrency} ${formattersMap.number(receiverFee)}`,
        'Deposit',
        `${sendCurrency} ${formattersMap.number(totalSendAmount)}`
      ]
    ]
  }

  private makeTextContents(): Dictionary<any> {
    const receiptType: string = this.isRefund ? 'Refund' : 'Deposit'
    const title: string = `${receiptType} Receipt`.toUpperCase()
    const remittance = this.receiptParam.remittance
    const paymentCompletedAt: Dayjs = dayjs(remittance.payment_completed_at)
    const updatedAt: Dayjs = dayjs(remittance.updated_at)
    const corpInfo = this.receiptParam.corpInfo
    const refundMaximumString: string = 'Refund process could take maximum 1 business day from the issuing date'
    const fileNamePrefix = this.isRefund ? dayjs().format('YYYYMMDD') : paymentCompletedAt.format('YYYYMMDD')
    const fileNameSuffix = `${remittance.id}_${receiptType.toLowerCase()}_receipt.pdf`
    return {
      title,
      issueInfo: {
        DATE: this.isRefund ? updatedAt.format('YYYY-MM-DD') : paymentCompletedAt.format('YYYY-MM-DD'),
        TO: corpInfo.corp_name
      },
      refundMaximumString,
      bankInfo: {
        'Bank Name': corpInfo.bank_account_name,
        'Account Number': corpInfo.bank_account_number
      },
      fileName: `${fileNamePrefix}_${fileNameSuffix}`
    }
  }

  private makeCustomTableLines(length = 5): void {
    const lineWidth: number = 28
    const middleColumnLine = length * 13 - 1.5
    this.doc.setLineWidth(0.35)
    this.doc.setDrawColor(208, 208, 208)
    this.makeCustomRowLines(lineWidth, 0)
    this.doc.line(
      this.pageStandard.width / 2,
      this.getNextPositionY(0),
      this.pageStandard.width / 2,
      this.getNextPositionY(0) + middleColumnLine
    )
    for (let index = 0; index < length; index++) this.makeCustomRowLines(lineWidth, 1.27)
  }

  private getAutoTableOption(tableList: any): Dictionary<any> {
    return {
      startY: this.getNextPositionY(0.25),
      theme: 'plain',
      styles: { halign: 'center', valign: 'middle', fontSize: 12, cellPadding: 4 },
      columnStyles: {
        0: { cellWidth: 61, halign: 'left' },
        1: { cellWidth: 65, halign: 'left' },
        2: { cellWidth: 61, halign: 'right' },
        3: { halign: 'right' }
      },
      margin: { left: 25, right: 30 },
      body: this.createdTableHeaderAndBody(tableList).body
    }
  }

  async printDocument(): Promise<void> {
    await this.printLogoHeader()

    const title = this.textContents.title
    this.doc.setFontSize(22)
    this.doc.text(title, this.getAlignCenterPositionX(title) - 5, this.getNextPositionY(2))

    const issueInfoTexts = this.textContents.issueInfo
    this.finalY = this.printTextObject(issueInfoTexts, 30, this.getNextPositionY(1.3))

    const autoTableOption = this.getAutoTableOption(this.receiptList)
    this.doc.autoTable(autoTableOption)

    this.makeCustomTableLines()

    if (this.isRefund) {
      this.finalY = this.pageStandard.height - 55
      const refundMaximumString = this.textContents.refundMaximumString
      this.doc.setFontSize(10)
      this.doc.text(refundMaximumString, 20, this.getNextPositionY(0))

      const bankInfoText = this.textContents.bankInfo
      const lineHeight = 2.5
      this.printTextObject(bankInfoText, 20, this.getNextPositionY(0.5), 10, lineHeight)
      this.doc.setFontSize(11)
    }

    const footerMessage: string = 'This is a computer-generated document. No signature is required.'
    this.printFooter(footerMessage, 45)

    this.doc.save(this.textContents.fileName)
  }
}
