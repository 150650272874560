















import Vue from 'vue'
import FilterRadioGroup from '@/dome/components/filter/inputs/FilterRadioGroup.vue'
import dayjs, { Dayjs } from 'dayjs'
import FilterDatePicker from '@/dome/components/filter/inputs/FilterDatePicker.vue'

export default Vue.extend({
  name: 'FilterPeriodContainer',
  components: { FilterDatePicker, FilterRadioGroup },
  data () {
    return {
      value: {
        from: dayjs().startOf('day').toDate(),
        to: dayjs().endOf('day').toDate()
      },
      dates: {
        '오늘': dayjs(),
        '1주일': dayjs().add(-1, 'week'),
        '3개월': dayjs().add(-3, 'month'),
        '직접입력': dayjs()
      },
      isManualInput: false
    }
  },
  computed: {
    labels (): Array<string> {
      return Object.keys(this.dates)
    }
  },
  methods: {
    onChange (value: string) {
      if (value === '직접입력') {
        this.isManualInput = true
        return
      }
      const dates = this.dates as Dictionary<Dayjs>
      this.value.from = dates[value].startOf('day').toDate()
      this.$emit('onChange', this.value)
    },
    onChangeFrom (value: Date) {
      this.value.from = dayjs(value).startOf('day').toDate()
      this.$emit('onChange', this.value)
    },
    onChangeTo (value: Date) {
      this.value.to = dayjs(value).endOf('day').toDate()
      this.$emit('onChange', this.value)
    }
  }
})
