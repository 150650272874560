import { GetWalletActivitiesParam } from './model/GetWalletActivitiesParam'
import { IApiRequest } from '../axios/IApiRequest'
import { WalletActivity } from '@/types/wallet/WalletActivity'
import { sentbizApi } from '@/adapters/axios'

export interface GetWalletActivities extends IApiRequest {
    getInstance (): GetWalletActivities;
    request (params: GetWalletActivitiesParam): Promise<Array<WalletActivity>>;
}

export class GetWalletActivities implements GetWalletActivities {
    private static instance: GetWalletActivities

    private constructor () {}

    public static getInstance (): GetWalletActivities {
      if (!this.instance) this.instance = new GetWalletActivities()
      return this.instance
    }

    async request (params: GetWalletActivitiesParam): Promise<Array<WalletActivity>> {
      const requestOption: RequestOption = {
        url: 'hades/wallet/activity',
        method: 'post',
        data: params
      }
      const response = await sentbizApi.request(requestOption)
      return response.data.list
    }
}
