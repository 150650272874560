import { sentbizApi } from '../axios'
import { IApiRequest } from '@/adapters/axios/IApiRequest'

export interface UpdateCorpNameParams {
  id: string;
  name: string;
}

export interface UpdateCorpName extends IApiRequest {
  getInstance (): UpdateCorpName;

  request (params: UpdateCorpNameParams): Promise<any>;
}

export class UpdateCorpName implements UpdateCorpName {
  private static instance: UpdateCorpName

  private constructor () {
  }

  public static getInstance (): UpdateCorpName {
    if (!this.instance) this.instance = new UpdateCorpName()
    return this.instance
  }

  async request (params: UpdateCorpNameParams): Promise<any> {
    const requestOption: RequestOption = {
      url: 'hades/corp/name',
      method: 'put',
      data: params
    }
    const response = await sentbizApi.request(requestOption)
    return response
  }
}
