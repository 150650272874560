import Currency from '@/enums/Currency'
import { AffiliatedDocumentType } from '@/enums/AffiliatedDocumentType'
import { CorpStatus } from '@/enums/CorpStatus'
import { CorpFee } from '@/presentation/affiliated/CorpFee'

export enum BusinessCategory {
  CORPORATE_BUSINESS = 1,
  ENTREPRENEUR
}

export enum CorpMemberPermission {
  ADMIN = 1,
  MEMBER
}

export interface Member {
  corp_id: string
  created_at?: null
  email: string
  id?: string
  otp?: string
  otp_expired_at?: null
  password?: string
  permission: CorpMemberPermission
  phone_code: number
  phone_number: string
  status: CorpStatus
  updated_at?: null
}

export interface Wallet {
  id: string
  corp_id: string
  balance: number
  currency: keyof typeof Currency
}

export interface AffiliateDocument {
  id: string
  corp_id: string
  type: AffiliatedDocumentType
  file_name: string
  file: string
}

export interface Corp {
  assigned_virtual_account: string
  base_currency: keyof typeof Currency
  biz_item: string
  biz_license_number: string
  biz_category: BusinessCategory
  biz_type: string
  corp_id: string
  created_at: Date
  approved_at: Date
  cut_off_time: string
  documents: Array<AffiliateDocument>
  fee_terms: string
  fees: Array<CorpFee>
  members: Array<Member>
  memo: string
  name: string
  special_terms: string
  status: CorpStatus
  symbol: string
  temp_email: string
  analogue: boolean
  remittance_count: number
  remittance_group_count: number
  total_usd_amount: number
  wallets: Array<Wallet>
  kor_corp: boolean
  deactivated_at: Date
}
