import { IApiRequest } from '@/adapters/axios/IApiRequest'
import { sentbizApi } from '@/adapters/axios'
import { RefundWalletParam } from '@/adapters/wallet/model/RefundWalletParam'

export interface RefundFromWallet extends IApiRequest {
  getInstance (): RefundFromWallet;
  request (data: RefundWalletParam): Promise<any>;
}

export class RefundFromWallet implements RefundFromWallet {
  private static instance: RefundFromWallet
  private constructor () {}
  public static getInstance (): RefundFromWallet {
    if (!this.instance) this.instance = new RefundFromWallet()
    return this.instance
  }

  async request (data: RefundWalletParam): Promise<any> {
    const requestOption: RequestOption = {
      url: 'hades/wallet/refund',
      method: 'put',
      data
    }
    const response = await sentbizApi.request(requestOption)
    return response
  }
}
