import { TransferRequestParam } from '@/usecase/TransfersUseCase'
import { sentbizApi } from '../axios'

interface ITransferRequest {
  request (params: TransferRequestParam): Promise<any>
}
export class SearchTransfer implements ITransferRequest {
  private static instance: SearchTransfer
  private constructor () {}

  public static getInstance (): SearchTransfer {
    if (!this.instance) this.instance = new SearchTransfer()
    return this.instance
  }

  async request (params: TransferRequestParam): Promise<any> {
    const option: RequestOption = {
      url: 'hades/remittance/search',
      method: 'post',
      data: params
    }
    const response = await sentbizApi.request(option)
    return response.data
  }
}
