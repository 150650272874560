












import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'FilterButton',
  props: {
    label: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: false,
      default: ''
    },
    color: {
      type: String as PropType<'default' | 'normal' | 'warning' | 'danger' | 'watch'>,
      required: false,
      default: 'default'
    },
    size: {
      type: String as PropType<'default' | 'medium' | 'small' | 'large'>,
      required: false,
      default: 'default'
    },
    isPlain: {
      type: Boolean,
      required: false,
      default: true
    }
  }
})
