














import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import FormItems from '@/components/shared/FormItems.vue'
import FormItemsOption from '../../types/FormItemsOption'
import RemittanceStatusEnum from '@/enums/RemittanceStatus'

@Component({
  components: { FormItems }
})
export default class BatchAction extends Vue {
  batchFormItems: Array<FormItemsOption> = []

  waitingBatchAction: FormItemsOption = {
    prop: 'waiting',
    type: 'select',
    value: undefined,
    placeHolder: 'Transfer Status',
    emitHandlerName: 'onTransferStatusAction',
    options: [
      { label: '입금 확인(Status 02)', value: 'confirm' },
      { label: 'Cancel(20)', value: 'cancel' }
    ]
  }

  onTransferStatusAction (value: RemittanceStatusEnum) {
    this.waitingBatchAction.value = undefined
    this.$emit('onTransferStatusAction', value)
  }

  created () {
    this.batchFormItems = [this.waitingBatchAction]
    this.batchFormItems = Array(...this.batchFormItems)
  }
}
