

































import Vue from 'vue'
import Component from 'vue-class-component'
import { WalletActivitiesPresentation } from '@/presentation'
import DataTable from '@/components/shared/DataTable.vue'
import { Prop } from 'vue-property-decorator'
import FormItems from '@/components/shared/FormItems.vue'
import FormItemsOption from '@/types/FormItemsOption'
import { WalletActivityEnum } from '@/enums/WalletActivityEnum'
import FormItemsModelInput from '@/components/shared/FormItemsModelInput.vue'
import { Corp } from '@/adapters/affiliate/Corp'
import { IWalletActivitiesPresentation } from '@/presentation/wallet/model/IWalletActivitiesPresentation'

@Component({
  components: { FormItemsModelInput, FormItems, DataTable }
})
export default class CorpWalletActivities extends Vue {
  @Prop(Object) corp!: Corp

  presentation: IWalletActivitiesPresentation = new WalletActivitiesPresentation()

  walletFilterField: { filter: Array<WalletActivityEnum> } = { filter: [] }
  walletFilterForms: Array<FormItemsOption> = [
    {
      prop: 'filter',
      model: 'filter',
      label: 'Filter',
      type: 'select',
      size: 'mini',
      placeHolder: 'Show All',
      useSelectAll: true,
      selectAllLabel: 'Show All',
      selectAllValue: [],
      value: undefined,
      options: [
        {
          label: 'Deposits (입금)',
          value: [
            WalletActivityEnum.DEPOSIT_BY_ADMIN,
            WalletActivityEnum.DEPOSIT_BY_AUTO_DEBIT,
            WalletActivityEnum.DEPOSIT_BY_PAYMENT,
            WalletActivityEnum.DEPOSIT_BY_PROMOTION,
            WalletActivityEnum.DEPOSIT_BY_SYSTEM,
            WalletActivityEnum.DEPOSIT_BY_VIRTUAL_ACCOUNT
          ],
          key: 'deposit'
        },
        { label: 'Remittance (송금)', value: [WalletActivityEnum.WITHDRAW_BY_REMITTANCE], key: 'remittance' },
        { label: 'Withdrawals (출금)', value: [WalletActivityEnum.WITHDRAW_BY_WALLET_REFUND], key: 'withdrawals' }
      ],
      emitHandlerName: 'onSelectWalletActivityType'
    }
  ]

  async onSelectWalletActivityType () {
    this.presentation.setWalletActivityType(this.walletFilterField.filter)
    await this.presentation.setWalletActivities()
  }

  async onClickExcelDownload () {
    this.presentation.downloadExcel(this.corp)
  }

  async created () {
    this.presentation.setCorpId(this.corp.corp_id)
    await this.presentation.setWalletActivities()
  }
}
