











































































import Vue from 'vue'
import Component from 'vue-class-component'
import BasicDialog from '@/components/commons/BasicDialog.vue'
import { GetRemittanceBase } from '@/adapters/remittanceBase/GetRemittanceBase'
import { IRemittanceBase } from '@/adapters/remittanceBase/model/IRemittanceBase'
import { KoRemittanceBase } from '@/data/KoRemittanceBase'
import Currency from '@/enums/Currency'
import { RemittanceBase } from '@/types/RemittanceBase'
import { koEnum } from '@/lokalise/enumLocale/ko'
import { UpdateRemittanceBase } from '@/adapters/remittanceBase/UpdateRemittanceBase'

@Component({
  components: { BasicDialog }
})
export default class AnalogMoneyTypes extends Vue {
  koTypeData: Array<KoRemittanceBase> = []
  responseData: Array<IRemittanceBase> = []
  /**
   * 수정 모달의 select data.
   * 
   * 수정 모달이 열리지 않았을때는 모든 국가에 대해 false.
   */
  currencyWithBool: Array<{[key: string]: boolean}> = []
  updateParam: Array<RemittanceBase> = []

  isModalOpen: boolean = false
  selectedData: KoRemittanceBase = {
    remittanceBase: {
      country: 'KR',
      limit_amount: 0,
      supported_currency: []
    },
    koCountry: ''
  }

  /**
   * '수정' 버튼을 눌렀을 때 selectedData에 선택된 KoRemittanceBase를 할당하고 selectedData의 remittanceBase supported_currency에 존재하는 currency에 대해 currencyWithBool을 true로 변환한다.
   * 
   * 그 후, isModalOpen을 true로 변환하여 모달을 연다.
   * 
   * 
   * @param {KoRemittanceBase} targetData '수정' 버튼이 눌린 국가의 KoRemittanceBase
   */
  onClickModify (targetData: KoRemittanceBase) {
    this.selectedData = targetData
    this.selectedData.remittanceBase.supported_currency.forEach(type => {
      this.currencyWithBool.forEach(moneyType => {
        if (this.getKeyOfCurrencies(moneyType) === type) {
          moneyType[type] = true
        }
      })
    })

    this.isModalOpen = true
  }

  /**
   * BasicDialog에서 'X' 버튼을 눌러 모달 닫기를 시도할 때 (visible을 emit할 때) currencyWithBool을 초기화하고 모달을 닫는다.
   */
  onClickX () {
    this.setMoneyTypes()
    this.isModalOpen = false
  }

  /**
   * 수정 모달에서 '저장' 버튼을 눌렀을 때, currencyWithBool 내 true로 선택된 환에 대해 updateParam supported_currency에 추가하고 updateRemittanceBase를 호출하여 수취환 업데이트를 시도한다.
   * 
   * - updateParam의 supported_currency가 빈 배열일 때, 업데이트를 시도하지 않고 에러 메시지 표출
   * - 그렇지 않을 경우, 업데이트를 시도하고 currencyWithBool을 초기화
   * - 모달을 닫음
   */
  async onCloseModal () {
    for (let index = 0; index < this.updateParam.length; index++) {
      if (this.updateParam[index].country === this.selectedData.remittanceBase.country) {
        this.updateParam[index].supported_currency = []
        this.currencyWithBool.forEach(typeObject => {
          if (this.getValueOfCurrencies(typeObject) === true) {
            this.updateParam[index].supported_currency.push(this.getKeyOfCurrencies(typeObject))
          }
        })
        if (this.updateParam[index].supported_currency.length === 0) {
          this.$message({ message: '하나 이상의 환을 선택해주세요.', type: 'error' })
          return
        } else break
      }
    }
    await this.updateRemittanceBase(this.updateParam)
    this.setMoneyTypes()
    this.isModalOpen = !this.isModalOpen
  }

  /**
   * target 객체의 첫번째 key를 리턴한다.
   * 
   * @param {Object} target target 객체
   * @return {String} target 객체의 첫번째 key
   */
  getKeyOfCurrencies (target: Object): String {
    return Object.keys(target)[0]
  }

  /**
   * target 객체의 첫번째 value를 리턴한다.
   * 
   * @param {Object} target target 객체
   * @return {Boolean} target 객체의 첫번째 value
   */
  getValueOfCurrencies (target: Object): Boolean {
    return Object.values(target)[0]
  }


  /**
   * koTypeData의 각 remittanceBase 값으로 updateParam을 설정한다.
   */
  setMoneyTypeData () {
    this.koTypeData.forEach((data, index) => {
      this.updateParam[index] = JSON.parse(JSON.stringify(data.remittanceBase))
    })
  }

  /**
   * currencyWithBool의 각 데이터를 전부 false로 초기화한다.
   * 
   * 이후, 국가명의 ABC 순으로 이를 정렬한다.
   * 그리고 KRW를 삭제한다.
   */
  setMoneyTypes () {
    this.currencyWithBool = []
    for (let currencyType in Currency) {
      if (isNaN(Number(currencyType))) {
        this.currencyWithBool.push({ [`${currencyType}`]: false })
      }
    }
    this.currencyWithBool.sort((former, latter) => {
      const formerType = Object.keys(former)
      const latterType = Object.keys(latter)
      return formerType[0] < latterType[0] ? -1 : (formerType[0] > latterType[0] ? 1 : 0)
    })
    this.currencyWithBool.splice(this.currencyWithBool.findIndex(type => Object.keys(type).includes('KRW')), 1)
  }

  /**
   * RemittanceBase 배열로 RemittanceBase를 업데이트한다.
   * 
   * * Update Remittance Base API는 PUT 메소드이므로, 한 국가에 대해서 수정을 하더라도 반드시 모든 국가에 대한 remittanceBase 정보를 한 번에 파라미터로 전송한다. *
   * 
   * API를 호출했을 때, 성공할 경우 성공 메시지를 함께 표출한다.
   * 
   * 이후 업데이트된 RemittanceBase 정보를 가져와 화면 내 타입, 데이터를 초기화한다.
   * 
   * @param {Array<RemittanceBase>} currencyDataField 업데이트 당시의 국가별 RemittanceBase 정보 배열
   */
  async updateRemittanceBase (currencyDataField: Array<RemittanceBase>) {
    const data = {
      list: currencyDataField
    }
    const response: any = await UpdateRemittanceBase.getInstance().request(data)
    if (response.code === 200) { this.$message({ message: '저장되었습니다.', type: 'success' }) }
    this.getRemittanceBase()
  }

  /**
   * API로부터 국가별 RemittanceBase 정보를 가져와 화면 내 관련 타입, 데이터에 할당한다.
   * 
   * 이후, koTypeData를 koCountry의 가나다 순으로 정렬한다.
   * 
   * 그리고 setMoneyTypeData를 호출하여 updateParam을 koTypeData로 설정한다.
   */
  async getRemittanceBase () {
    const response = await GetRemittanceBase.getInstance().request()
    this.responseData = response
    this.koTypeData = []
    this.responseData.forEach(data => {
      this.koTypeData.push({ remittanceBase: data, koCountry: koEnum[data.country] })
    })
    this.koTypeData.sort((former, latter) => {
      const formerName = former.koCountry
      const latterName = latter.koCountry
      return formerName < latterName ? -1 : (formerName > latterName ? 1 : 0)
    })
    this.setMoneyTypeData()
  }

  /**
   * AnalogMoneyTypes의 created life cycle에 RemittanceBase 정보를 가져와 관련 변수들에 할당하고, currencyWithBool를 초기화한다.
   */
  created () {
    this.getRemittanceBase()
    this.setMoneyTypes()
  }
}
