

































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { FeeModal } from '@/presentation/affiliated/FeeModal'
import FormItems from '../shared/FormItems.vue'
import { CorpFee } from '@/presentation/affiliated/CorpFee'

@Component({
  components: { FormItems }
})
export default class FeeModalDialog extends Vue {
  @Prop(Array) fees!: Array<CorpFee>
  feeModal: FeeModal = new FeeModal()

  onSubmit() {
    const fees: Array<CorpFee> = this.feeModal.onSubmit()
    const isInValidFee = fees.some(fee => !fee.currency || !fee.country)
    if (isInValidFee) return this.$message({ message: '국가 및 수취환을 선택해주세요.', type: 'error' })
    const countryMap: Map<string, string> = new Map<string, string>()
    let isDuplicatedCountry = false
    for (const fee of fees.filter(feeElement => feeElement.country !== 'ZZ')) {
      if (!fee.country || !fee.currency) continue
      if (fee.currency === countryMap.get(fee.country)) {
        isDuplicatedCountry = true
        break
      } else {
        countryMap.set(fee.country, fee.currency)
      }
    }
    const duplicateErrorMsg = '중복 선택된 국가 환이 있습니다. 다시 확인해주세요.'
    if (isDuplicatedCountry) return this.$message({ message: duplicateErrorMsg, type: 'error' })
    const isCurrencyExist = fees.every(fee => fee.fee_currency)
    if (!isCurrencyExist)
      return this.$message({ message: '수수료 환이 선택되지 않았습니다. 모두 선택해주세요.', type: 'error' })
    fees.forEach(fee => {
      fee.rate = Number((fee.rate / 100).toPrecision(5))
      fee.ratio = Number((fee.ratio / 100).toPrecision(5))
      delete fee.receiver_ratio
    })
    this.$emit('submitFees', fees)
  }

  created() {
    this.feeModal.setFees(this.fees)
  }
}
