import { render, staticRenderFns } from "./FilterButton.vue?vue&type=template&id=38a0d2a1&scoped=true&"
import script from "./FilterButton.vue?vue&type=script&lang=ts&"
export * from "./FilterButton.vue?vue&type=script&lang=ts&"
import style0 from "./FilterButton.vue?vue&type=style&index=0&id=38a0d2a1&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38a0d2a1",
  null
  
)

export default component.exports