import CountryEnum from '@/enums/Country'
import CurrencyEnum from '@/enums/Currency'
import Country from '@/types/Country'
import Currency from '@/types/Currency'
import { enEnum } from '@/lokalise/enumLocale/en'

const eurCurrency: Currency = { id: CurrencyEnum.EUR, value: 'EUR', sign: '€' }
const usdCurrency: Currency = { id: CurrencyEnum.USD, value: 'USD', sign: '$' }
export const countries: Array<Country> = [
  {
    id: CountryEnum.KR,
    phone_number_code: 82,
    iso: 'KR',
    name: 'Korea',
    currency: [
      {
        id: 1,
        value: 'KRW',
        sign: '₩'
      }
    ]
  },
  {
    id: CountryEnum.US,
    phone_number_code: 1,
    iso: 'US',
    name: 'United States',
    currency: [usdCurrency]
  },
  {
    id: CountryEnum.KH,
    phone_number_code: 855,
    iso: 'KH',
    name: 'Cambodia',
    currency: [
      usdCurrency,
      {
        id: CurrencyEnum.KHR,
        value: 'KHR',
        sign: '៛'
      }
    ]
  },
  {
    id: CountryEnum.AU,
    phone_number_code: 61,
    iso: 'AU',
    name: 'Australia',
    currency: [
      {
        id: CurrencyEnum.AUD,
        value: 'AUD',
        sign: '$'
      }
    ]
  },
  {
    id: CountryEnum.CA,
    phone_number_code: 1,
    iso: 'CA',
    name: 'Canada',
    currency: [
      {
        id: CurrencyEnum.CAD,
        value: 'CAD',
        sign: 'C$'
      }
    ]
  },
  {
    id: CountryEnum.BD,
    phone_number_code: 880,
    iso: 'BD',
    name: 'Bangladesh',
    currency: [
      {
        id: CurrencyEnum.BDT,
        value: 'BDT',
        sign: 'Tk'
      }
    ]
  },
  {
    id: CountryEnum.CN,
    phone_number_code: 86,
    iso: 'CN',
    name: 'China',
    currency: [
      {
        id: CurrencyEnum.CNY,
        value: 'CNY',
        sign: '¥'
      },
      usdCurrency
    ]
  },
  {
    id: CountryEnum.IN,
    phone_number_code: 91,
    iso: 'IN',
    name: 'India',
    currency: [
      {
        id: CurrencyEnum.INR,
        value: 'INR',
        sign: 'Rs'
      }
    ]
  },
  {
    id: CountryEnum.ID,
    phone_number_code: 62,
    iso: 'ID',
    name: 'Indonesia',
    currency: [
      {
        id: CurrencyEnum.IDR,
        value: 'IDR',
        sign: 'Rp'
      }
    ]
  },
  {
    id: CountryEnum.JP,
    phone_number_code: 81,
    iso: 'JP',
    name: 'Japan',
    currency: [
      {
        id: CurrencyEnum.JPY,
        value: 'JPY',
        sign: '¥'
      }
    ]
  },
  {
    id: CountryEnum.MY,
    phone_number_code: 60,
    iso: 'MY',
    name: 'Malaysia',
    currency: [
      {
        id: CurrencyEnum.MYR,
        value: 'MYR',
        sign: 'RM'
      }
    ]
  },
  {
    id: CountryEnum.NP,
    phone_number_code: 977,
    iso: 'NP',
    name: 'Nepal',
    currency: [
      {
        id: CurrencyEnum.NPR,
        value: 'NPR',
        sign: 'Rs'
      }
    ]
  },
  {
    id: CountryEnum.PK,
    phone_number_code: 92,
    iso: 'PK',
    name: 'Pakistan',
    currency: [
      {
        id: CurrencyEnum.PKR,
        value: 'PKR',
        sign: 'Rs'
      }
    ]
  },
  {
    id: CountryEnum.PH,
    phone_number_code: 63,
    iso: 'PH',
    name: 'Philippines',
    currency: [
      {
        id: CurrencyEnum.PHP,
        value: 'PHP',
        sign: '₱'
      }
    ]
  },
  {
    id: CountryEnum.LK,
    phone_number_code: 94,
    iso: 'LK',
    name: 'SriLanka',
    currency: [
      {
        id: CurrencyEnum.LKR,
        value: 'LKR',
        sign: 'Rs'
      }
    ]
  },
  {
    id: CountryEnum.TH,
    phone_number_code: 66,
    iso: 'TH',
    name: 'Thailand',
    currency: [
      {
        id: CurrencyEnum.THB,
        value: 'THB',
        sign: '฿'
      }
    ]
  },
  {
    id: CountryEnum.VN,
    phone_number_code: 84,
    iso: 'VN',
    name: 'Vietnam',
    currency: [
      {
        id: CurrencyEnum.VND,
        value: 'VND',
        sign: '₫'
      },
      usdCurrency
    ]
  },
  {
    id: CountryEnum.GB,
    phone_number_code: 44,
    iso: 'GB',
    name: 'United Kingdom',
    currency: [
      {
        id: CurrencyEnum.GBP,
        value: 'GBP',
        sign: '£'
      }
    ]
  },
  {
    id: CountryEnum.RU,
    phone_number_code: 7,
    iso: 'RU',
    name: 'Russia',
    currency: [usdCurrency]
  },
  {
    id: CountryEnum.TJ,
    phone_number_code: 992,
    iso: 'TJ',
    name: 'Tajikistan',
    currency: [usdCurrency]
  },
  {
    id: CountryEnum.UZ,
    phone_number_code: 998,
    iso: 'UZ',
    name: 'Uzbekistan',
    currency: [usdCurrency]
  },
  {
    id: CountryEnum.UA,
    phone_number_code: 380,
    iso: 'UA',
    name: 'Ukraine',
    currency: [eurCurrency]
  },
  {
    id: CountryEnum.KZ,
    phone_number_code: 7,
    iso: 'KZ',
    name: 'Kazakhstan',
    currency: [usdCurrency]
  },
  {
    id: CountryEnum.KG,
    phone_number_code: 996,
    iso: 'KG',
    name: 'Kyrgyzstan',
    currency: [usdCurrency]
  },
  {
    id: CountryEnum.MN,
    phone_number_code: 976,
    iso: 'MN',
    name: 'Mongolia',
    currency: [usdCurrency]
  },
  {
    id: CountryEnum.SA,
    phone_number_code: 966,
    iso: 'SA',
    name: 'Saudi Arabia',
    currency: []
  },
  {
    id: CountryEnum.NL,
    phone_number_code: 31,
    iso: 'NL',
    name: 'Netherlands',
    currency: [eurCurrency]
  },
  {
    id: CountryEnum.LA,
    phone_number_code: 856,
    iso: 'LA',
    name: 'Laos',
    currency: []
  },
  {
    id: CountryEnum.PT,
    phone_number_code: 351,
    iso: 'PT',
    name: 'Portugal',
    currency: [eurCurrency]
  },
  {
    id: CountryEnum.IE,
    phone_number_code: 353,
    iso: 'IE',
    name: 'Ireland',
    currency: [eurCurrency]
  },
  {
    id: CountryEnum.FI,
    phone_number_code: 358,
    iso: 'FI',
    name: 'Finland',
    currency: [eurCurrency]
  },
  {
    id: CountryEnum.LU,
    phone_number_code: 352,
    iso: 'LU',
    name: 'Luxembourg',
    currency: [eurCurrency]
  },
  {
    id: CountryEnum.ES,
    phone_number_code: 34,
    iso: 'ES',
    name: 'Spain',
    currency: [eurCurrency]
  },
  {
    id: CountryEnum.BG,
    phone_number_code: 359,
    iso: 'BG',
    name: 'Bulgaria',
    currency: [eurCurrency]
  },
  {
    id: CountryEnum.DK,
    phone_number_code: 45,
    iso: 'DK',
    name: 'Denmark',
    currency: [eurCurrency]
  },
  {
    id: CountryEnum.DE,
    phone_number_code: 49,
    iso: 'DE',
    name: 'Germany',
    currency: [eurCurrency]
  },
  {
    id: CountryEnum.LV,
    phone_number_code: 371,
    iso: 'LV',
    name: 'Latvia',
    currency: [eurCurrency]
  },
  {
    id: CountryEnum.PL,
    phone_number_code: 48,
    iso: 'PL',
    name: 'Poland',
    currency: [eurCurrency]
  },
  {
    id: CountryEnum.SG,
    phone_number_code: 65,
    iso: 'SG',
    name: 'Singapore',
    currency: [
      {
        id: CurrencyEnum.SGD,
        value: 'SGD',
        sign: 'S$'
      }
    ]
  },
  {
    id: CountryEnum.HK,
    phone_number_code: 852,
    iso: 'HK',
    name: 'Hong Kong',
    currency: [
      {
        id: CurrencyEnum.HKD,
        value: 'HKD',
        sign: 'HK$'
      }
    ]
  },
  {
    id: CountryEnum.TR,
    phone_number_code: 90,
    iso: 'TR',
    name: 'Turkey',
    currency: [
      {
        id: CurrencyEnum.TRY,
        value: 'TRY',
        sign: '₺'
      }
    ]
  },
  {
    id: CountryEnum.PE,
    phone_number_code: 51,
    iso: 'PE',
    name: 'Peru',
    currency: [
      {
        id: CurrencyEnum.PEN,
        value: 'PEN',
        sign: 'S.$'
      }
    ]
  },
  {
    id: CountryEnum.IT,
    phone_number_code: 39,
    iso: 'IT',
    name: 'Italy',
    currency: [eurCurrency]
  },
  {
    id: CountryEnum.SE,
    phone_number_code: 46,
    iso: 'SE',
    name: 'Sweden',
    currency: [eurCurrency]
  },
  {
    id: CountryEnum.MT,
    phone_number_code: 356,
    iso: 'MT',
    name: 'Malta',
    currency: [eurCurrency]
  },
  {
    id: CountryEnum.AT,
    phone_number_code: 43,
    iso: 'AT',
    name: 'Austria',
    currency: [eurCurrency]
  },
  {
    id: CountryEnum.EE,
    phone_number_code: 372,
    iso: 'EE',
    name: 'Estonia',
    currency: [eurCurrency]
  },
  {
    id: CountryEnum.CY,
    phone_number_code: 357,
    iso: 'CY',
    name: 'Cyprus',
    currency: [eurCurrency]
  },
  {
    id: CountryEnum.FR,
    phone_number_code: 33,
    iso: 'FR',
    name: 'France',
    currency: [eurCurrency]
  },
  {
    id: CountryEnum.BE,
    phone_number_code: 32,
    iso: 'BE',
    name: 'Belgium',
    currency: [eurCurrency]
  },
  {
    id: CountryEnum.MC,
    phone_number_code: 377,
    iso: 'MC',
    name: 'Monaco',
    currency: [eurCurrency]
  },
  {
    id: CountryEnum.LT,
    phone_number_code: 370,
    iso: 'LT',
    name: 'Lithuania',
    currency: [eurCurrency]
  }
]

const disabledCountryEnums: Array<CountryEnum> = [
  CountryEnum.KR,
  CountryEnum.LA,
  CountryEnum.SA,
  CountryEnum.TJ,
  CountryEnum.RU,
  CountryEnum.UZ,
  CountryEnum.UA,
  CountryEnum.KG,
  CountryEnum.KZ,
  CountryEnum.MN
]

const disabledCountryIso = [
  'KR',
  'LA',
  'SA',
  'TJ',
  'RU',
  'UZ',
  'UA',
  'KG',
  'KZ',
  'MN',
]

const countriesWithUsdAnywhere =  Object.keys(enEnum)

export const receivableCountriesWithUsdAnywhere: Array<string> = countriesWithUsdAnywhere.filter((id ) => {
  return !disabledCountryIso.includes(id)
})

export const receivableCountries: Array<Country> = countries.filter(({ id }) => {
  return !disabledCountryEnums.includes(id)
})
const duplicatedPhoneCodes: Array<Array<CountryEnum>> = [
  [CountryEnum.US, CountryEnum.CA],
  [CountryEnum.RU, CountryEnum.KZ]
]
export const phoneCodeKeyCountries: Array<Country> = JSON.parse(JSON.stringify(countries)).filter(
  (country: Country) => {
    const duplicatedPair: Array<CountryEnum> | undefined = duplicatedPhoneCodes.find(countries =>
      countries.includes(country.id)
    )
    if (duplicatedPair) country.iso = `${CountryEnum[duplicatedPair[0]]} / ${CountryEnum[duplicatedPair[1]]}`
    return !duplicatedPair || (duplicatedPair && duplicatedPair[0] !== country.id)
  }
)
