import { sentbizApi } from './axios'

export interface ISignOut {
  request (): Promise<any>
}

export class SignOut implements ISignOut {
  async request (): Promise<any> {
    const option: RequestOption = {
      url: 'sign-out',
      method: 'post'
    }
    const response: any = await sentbizApi.request(option)
    return response
  }
}
