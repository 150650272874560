import { RemittanceBase } from '@/types/RemittanceBase'
import { sentbizApi } from '../axios'

export interface UpdateRemittanceBaseParam {
  list: Array<RemittanceBase>
}

interface IUpdateRemittanceBase {
  request (params: UpdateRemittanceBaseParam): Promise<any>;
}

export class UpdateRemittanceBase implements IUpdateRemittanceBase {
  private static instance: UpdateRemittanceBase

  private constructor () {
  }

  public static getInstance (): UpdateRemittanceBase {
    if (!this.instance) this.instance = new UpdateRemittanceBase()
    return this.instance
  }

  async request (params: UpdateRemittanceBaseParam): Promise<any> {
    const requestOption: RequestOption = {
      url: 'hades/remittance/base',
      method: 'put',
      data: params
    }
    const response: any = await sentbizApi.request(requestOption)
    return response
  }
}
