import { IApiRequest } from '@/adapters/axios/IApiRequest'
import { sentbizApi } from '@/adapters/axios'
import { Member } from '@/adapters/affiliate/Corp'

export interface CreateCorpMembersParams {
  list: Array<Member>;
}

export interface DeleteCorpMemberParam {
  corp_member_id: string;
}

export interface CorpMembers extends IApiRequest {
  getInstance (): CorpMembers;
  get (params: { corp_id: string }): Promise<Dictionary<any>>;
  put (params: CreateCorpMembersParams): Promise<Dictionary<any>>;
  delete (params: DeleteCorpMemberParam): Promise<Dictionary<any>>;
}

export class CorpMembers implements CorpMembers {
  private static instance: CorpMembers
  private constructor () {}
  public static getInstance (): CorpMembers {
    if (!this.instance) this.instance = new CorpMembers()
    return this.instance
  }

  async get (params: { corp_id: string }): Promise<Dictionary<any>> {
    const requestOption: RequestOption = {
      url: 'corpmembers',
      method: 'get',
      params
    }
    const response: any = await sentbizApi.request(requestOption)
    return response?.data
  }

  async put (params: CreateCorpMembersParams): Promise<Dictionary<any>> {
    const requestOption: RequestOption = {
      url: 'corpmembers',
      method: 'put',
      data: params
    }
    const response: any = await sentbizApi.request(requestOption)
    return response
  }

  async delete (params: DeleteCorpMemberParam): Promise<Dictionary<any>> {
    const requestOption: RequestOption = {
      url: 'corpmember',
      method: 'delete',
      data: params
    }
    const response: any = await sentbizApi.request(requestOption)
    return response
  }
}
