enum Currency {
  KRW = 1,
  USD,
  PHP,
  VND,
  IDR,
  THB,
  INR,
  PKR,
  CNY,
  BDT,
  JPY,
  MYR,
  LKR,
  NPR,
  AUD,
  CAD,
  GBP,
  KHR,
  EUR,
  SGD,
  HKD,
  TRY,
  PEN
}

export default Currency
