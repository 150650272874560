




























































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { regexToPatternString } from '@/data/Regex'
import SignInResponseCode from '@/enums/SignInResponseCode'
import { RequestOtp } from '@/adapters/otpSignIn/RequestOtp'
import { ConfirmOtp } from '@/adapters/otpSignIn/ConfirmOtp'
import { ChangePasswordParam } from '@/types/apiRequest/ChangePasswordParam'
import { ChangePassword } from '@/adapters/otpSignIn/ChangePassword'

@Component
export default class OtpSignIn extends Vue {
  authTimer = 0
  isOtpRequested = false
  timeInterval = 0
  corpMemberId = ''

  isSignUp: boolean = false
  isChangePassword: boolean = false

  formField: Dictionary<string> = {}

  emailRule: FormRule = {
    required: true,
    pattern: regexToPatternString('email'),
    trigger: 'blur',
    message: ' '
  }

  passwordRule: FormRule = {
    required: true,
    trigger: 'blur',
    message: ' '
  }

  otpRule: FormRule = {
    required: true,
    validator: this.checkTimer,
    trigger: 'blur'
  }

  signUpPasswordRule: FormRule = {
    required: true,
    pattern: regexToPatternString('password'),
    trigger: 'blur',
    message: '영문, 숫자, 특수문자가 포함되어야합니다.'
  }
  passwordConfirmRule: FormRule = {
    validator: this.checkPasswordConfirm,
    trigger: 'blur',
    message: '비밀번호가 일치하지 않습니다.'
  }

  signInRules: Dictionary<Array<FormRule>> = {
    email: [this.emailRule],
    password: [this.passwordRule]
  }

  otpRules: Dictionary<Array<FormRule>> = {
    otp: [this.otpRule]
  }

  passwordConfirmRules: Dictionary<Array<FormRule>> = {
    password: [this.passwordRule, this.signUpPasswordRule],
    passwordConfirm: [this.passwordRule, this.signUpPasswordRule, this.passwordConfirmRule]
  }

  signErrorMessages: { [T in SignInResponseCode]: string } = {
    [SignInResponseCode.NOT_FOUND_USER]: '이메일을 다시 확인해 주세요',
    [SignInResponseCode.PW_NOT_MATCH]: 'Something’s wrong. Please try again.',
    [SignInResponseCode.OTP_TOKEN_NOTMATCH]: 'Something’s wrong. Please try again.',
    [SignInResponseCode.SMS_ERROR]: '메세지 오류입니다. 다시 시도 해 주세요. 계속 된다면 CS 센터로 문의해 주세요',
    [SignInResponseCode.INVALID_LOGIN]: 'Invalid login information. please check again.',
    [SignInResponseCode.PW_EXPIRED]:
      'For secure account, password change is required every 90 days. Going to the Change Password page.',
    [SignInResponseCode.PW_LOCK]: 'Your account is deactivated. Please contact the customer center.',
    [SignInResponseCode.PW_DUPLICATE]:
      '최근 4건의 패스워드는 입력할 수 없습니다. (You cannot set the same password as the previous four passwords.)'
  }

  get getTimerCountdown(): string {
    return `${Math.floor(this.authTimer / 60)} : ${this.authTimer % 60}`
  }

  get getSubmitMethod(): () => void {
    if (this.isChangePassword) return this.changePassword
    return this.isOtpRequested ? this.confirmOtp : this.requestSignIn
  }

  get getSubmitLabel(): string {
    if (this.$route.path === '/signUp') return '다음'
    return 'Login'
  }

  get getRules(): Dictionary<Array<FormRule>> {
    if (this.isChangePassword) return this.passwordConfirmRules
    return this.isOtpRequested ? this.otpRules : this.signInRules
  }

  get showOtpForm(): boolean {
    if (this.isChangePassword) return false
    return this.isOtpRequested
  }

  checkTimer(rule: any, value: any, callback: (error?: Error) => void): void {
    if (this.authTimer) {
      return callback()
    } else {
      return callback(new Error())
    }
  }

  checkPasswordConfirm(rule: any, value: any, callback: (error?: Error) => void): void {
    const isEqualPassword = this.formField.password && this.formField.password === this.formField.passwordConfirm
    if (isEqualPassword) {
      return callback()
    } else {
      return callback(new Error('비밀번호가 일치하지 않습니다.'))
    }
  }

  setAuthorizeInLocalStorage(auth: string): void {
    localStorage.setItem('auth', auth)
    localStorage.setItem('email', this.formField.email)
  }

  setAuthenticateTimer(timer = 3 * 60): void {
    this.authTimer = timer
    clearInterval(this.timeInterval)
    this.timeInterval = setInterval(() => {
      --this.authTimer
      if (this.authTimer <= 0) {
        clearInterval(this.timeInterval)
      }
    }, 1000)
  }

  async formValidate(): Promise<any> {
    try {
      return await (this.$refs.signForm as Vue & { validate: () => boolean | any }).validate()
    } catch (error) {
      return error
    }
  }

  async requestSignIn(): Promise<void> {
    const signInValidation: boolean = await this.formValidate()
    if (!signInValidation) return
    await this.requestOtp()
  }

  async initialSignUp(): Promise<void> {
    this.isSignUp = true
    await this.$alert(
      '안전한 계정 관리를 위하여 90일마다 비밀번호 변경이 필요합니다.<br>확인을 누르시면 비밀번호 등록을 시작합니다.',
      '비밀번호 변경 필요',
      {
        dangerouslyUseHTMLString: true
      }
    )
    await this.requestSignUp()
  }

  showErrorMessage(responseCode: SignInResponseCode): void {
    const message = this.signErrorMessages[responseCode] ? this.signErrorMessages[responseCode] : 'Unknown ERROR'
    this.$message({ message: `${message} [${responseCode}]`, type: 'error' })
  }

  async requestOtp(): Promise<void> {
    const data: SignRequest = {
      email: this.formField.email,
      password: this.formField.password,
      audience: 'manager'
    }
    const response = await RequestOtp.getInstance().signin(data)
    const isSuccessResponse: boolean = response.code === 200
    if (!isSuccessResponse) {
      const responseCode: SignInResponseCode = response.code
      this.showErrorMessage(responseCode)
      const isPwExpired = responseCode === SignInResponseCode.PW_EXPIRED
      if (isPwExpired) this.initialSignUp()
      return
    }
    this.corpMemberId = response.data?.corp_member_id
    this.isOtpRequested = true
    const hasOtpForm = !!this.formField.otp
    if (!hasOtpForm) this.formField = Object.assign({}, this.formField, { otp: '' })
    this.setAuthenticateTimer()
  }

  async requestSignUp(): Promise<void> {
    const data: SignRequest = {
      email: this.formField.email,
      password: this.formField.password,
      audience: 'temporal_manager'
    }
    const response = await RequestOtp.getInstance().signup(data)
    const isSuccessResponse: boolean = response.code === 200
    if (!isSuccessResponse) {
      const responseCode: SignInResponseCode = response.code
      this.showErrorMessage(responseCode)
      return
    }
    this.corpMemberId = response.data?.member_id
    this.isOtpRequested = true
    const hasOtpForm = !!this.formField.otp
    if (!hasOtpForm) this.formField = Object.assign({}, this.formField, { otp: '' })
    this.setAuthenticateTimer()
  }

  async confirmOtp(): Promise<void> {
    const signUpValidation: boolean = await this.formValidate()
    if (!signUpValidation) return
    const data: Otp = {
      member_id: this.corpMemberId,
      token: this.formField.otp,
      audience: this.isSignUp ? 'temporal_manager' : 'manager'
    }
    const response = await ConfirmOtp.getInstance().request(data)
    const isSuccessResponse: boolean = response.code === 200
    if (!isSuccessResponse) {
      this.showErrorMessage(response.code)
      return
    }
    this.$message({ message: '인증되었습니다.', type: 'success' })
    const auth: string = response.data?.auth
    if (!auth) return
    this.setAuthorizeInLocalStorage(auth)
    if (this.isSignUp) {
      this.formField = Object.assign({}, this.formField, { passwordConfirm: '' })
      this.isChangePassword = true
      this.isSignUp = false
      return
    }
    this.$emit('otpConfirmed')
  }

  async changePassword(): Promise<void> {
    let signUpValidation: boolean = await this.formValidate()
    if (!signUpValidation) return
    const changePasswordParam: ChangePasswordParam = {
      member_id: this.corpMemberId,
      password: this.formField.password
    }
    const response = await ChangePassword.getInstance().password(changePasswordParam)
    const isSuccessResponse: boolean = response.code === 200
    if (!isSuccessResponse) {
      this.showErrorMessage(response.code)
      return
    }
    this.$message({ message: '새 패스워드 등록 완료', type: 'success' })
    window.location.reload()
  }

  setFormField(): void {
    const emailField = { email: '' }
    const passwordField = { password: '' }
    this.formField = Object.assign({}, emailField, passwordField)
  }

  created(): void {
    this.setFormField()
  }
}
