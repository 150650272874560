export const regexpOptions: Dictionary<RegExp> = {
  number: /^[0-9]*$/,
  numberComma: /^[0-9, ]*$/,
  numberEnglish: /^[0-9a-zA-Z ]*$/,
  english: /^[a-zA-Z ]*$/,
  englishLower: /^[a-z]*$/,
  englishUpper: /^[A-Z]*$/,
  corps_id: /^[0-9]*$/,
  name_first: /^[a-zA-Z\-. ]*$/,
  name_middle: /^[a-zA-Z\-. ]*$/,
  name_last: /^[a-zA-Z\-. ]*$/,
  amount: /^[0-9.]*$/,
  date: /([12]\d{3}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01]))/,
  phone: /^[0-9]*$/,
  account_number: /^[0-9a-zA-Z ]*$/,
  bsb_code: /^[0-9]*$/,
  transit_code: /^[0-9]*$/,
  bank_branch: /^[0-9]*$/,
  routing_number: /^[0-9]*$/,
  corp_name: /^[0-9a-zA-Z()\-.,_/ ]*$/,
  address_line1: /^[0-9a-zA-Z()\-.,_/ ]*$/,
  birth_date: /^([12]\d{3}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01]))$/,
  email: /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/,
  password: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()<>=+_`~?])[A-Za-z\d!@#$%^&*()<>=+_`~?]{8,}$/
}

export const regexToPatternString = (regexName: string) => {
  const regexString: string = regexpOptions[regexName].toString()
  return regexString.substring(1, regexString.length - 1)
}
