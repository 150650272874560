import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { RootState } from '@/types/RootState'
import { transfersStore } from '@/store/modules/TransfersState'
import { fraudTransactionState } from '@/dome/store/fraudTransactionState'

Vue.use(Vuex)

const loadingText = '...Loading'

export const store: StoreOptions<RootState> = {
  state: {
    isLoading: false,
    loadingText
  },
  mutations: {
    disableLoading (state) {
      state.isLoading = false
    },
    enableLoading (state, receivedLoadingText?: string) {
      state.isLoading = true
      state.loadingText = receivedLoadingText || state.loadingText
    }
  },
  actions: {},
  modules: {
    transfersStore,
    fraudTransactionState
  },
  getters: {}
}

export default new Vuex.Store<RootState>(store)
