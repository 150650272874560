enum Country {
  KR = 209,
  US = 239,
  AU = 14,
  AT = 15,
  BD = 19,
  BE = 22,
  BG = 36,
  KH = 39,
  CA = 41,
  CN = 47,
  CY = 57,
  DK = 60,
  EE = 70,
  FI = 75,
  FR,
  DE = 83,
  HK = 99,
  IN = 102,
  ID = 103,
  IE = 106,
  IT = 109,
  JP = 112,
  KZ = 115,
  KG = 120,
  LA,
  LV,
  LT = 128,
  LU,
  MY = 134,
  MT = 137,
  MC = 146,
  MN = 147,
  NP = 155,
  NL,
  PK = 168,
  PH = 175,
  PL = 177,
  PT,
  RU = 184,
  SA = 196,
  SG = 201,
  ES = 211,
  LK = 212,
  SE = 217,
  TJ = 221,
  TH = 223,
  TR = 229,
  UA = 235,
  GB = 237,
  UZ = 241,
  VN = 245,
  PE = 300
}
export default Country
