import { IApiRequest } from '@/adapters/axios/IApiRequest'
import { sentbizApi } from '@/adapters/axios'

export interface UpdateCorpMemo extends IApiRequest {
  getInstance (): UpdateCorpMemo
  request (params: { id: number; memo: string }): Promise<any>
}
export class UpdateCorpMemo implements UpdateCorpMemo {
  private static instance: UpdateCorpMemo
  private constructor () {}
  public static getInstance (): UpdateCorpMemo {
    if (!this.instance) this.instance = new UpdateCorpMemo()
    return this.instance
  }

  async request (params: { id: number; memo: string }): Promise<any> {
    const requestOption: RequestOption = {
      url: 'hades/corp/memo',
      method: 'put',
      data: params
    }
    const response = await sentbizApi.request(requestOption)
    return response
  }
}
