import RouterOption from '@/types/RouterOption'
import SignIn from '@/views/SignIn.vue'

const Routes: Array<RouterOption> = [
  {
    path: '/',
    redirect: '/transfers/all',
    show: false
  },
  {
    label: '로그인',
    path: '/signIn',
    show: false,
    component: SignIn
  },
  {
    label: '송금건별 Transfer',
    path: '/transfers',
    redirect: '/transfers/all',
    show: true,
    component: () => import('@/views/Transfers.vue'),
    children: [
      { label: '송금 진행 중 (Status 02)', path: ':processing', show: true },
      { label: '송금 실패 (Status 03)', path: ':failed', show: true },
      { label: '송금 완료 (Status 10)', path: ':complete', show: true },
      { label: 'AML Filtered (Status -4)', path: ':aml', show: true },
      { label: '아날로그 송금', path: ':analogue', show: true },
      { label: 'All', name: 'transfers', path: ':all', show: true }
    ]
  },
  {
    label: '송금그룹별 Transfers by Group',
    path: '/transferGroups',
    redirect: '/transferGroups/waiting',
    show: true,
    component: () => import('@/views/TransferGroups.vue'),
    children: [
      { label: '입금 대기', path: ':waiting', show: true },
      { label: '진행중(완료 대기)', path: ':ongoing', show: true },
      { label: '완료', path: ':finish', show: true },
      { label: '취소', path: ':cancel', show: true },
      { label: '기한 만료', path: ':expired', show: true }
    ]
  },
  {
    label: '거래내역 DOME',
    path: '/dome',
    show: true,
    component: () => import('@/dome/container/DomeTransactionalInfo/DomeTransactionalInfo.vue')
  },
  {
    label: '고객사 Manage Companies',
    path: '/affiliate',
    redirect: '/affiliate/register',
    show: true,
    component: () => import('@/views/affiliate/AffiliatedWrapper.vue'),
    children: [
      {
        label: 'Company List',
        path: 'completedList',
        show: true,
        component: () => import('@/views/affiliate/CompletedAffiliatedList.vue')
      },
      {
        label: 'Sign Up In Progress',
        path: 'list',
        show: true,
        component: () => import('@/views/affiliate/AffiliatedList.vue')
      },
      {
        label: 'Sign Up Registration',
        path: 'register',
        show: true,
        component: () => import('@/views/affiliate/AffiliatedRegister.vue')
      }
    ]
  },
  {
    label: '아날로그 송금 기준 관리',
    path: '/analog',
    redirect: '/analog/moneyTypes',
    show: true,
    component: () => import('@/views/analog/AnalogWrapper.vue'),
    children: [
      {
        label: '국가별 지원환',
        path: 'moneyTypes',
        show: true,
        component: () => import('@/views/analog/AnalogMoneyTypes.vue')
      },
      {
        label: '국가별 커트라인 금액',
        path: 'moneyLimit',
        show: true,
        component: () => import('@/views/analog/AnalogMoneyLimit.vue')
      }
    ]
  },
  {
    label: '어드민 계정 생성',
    path: '/adminCreateAccount',
    show: true,
    component: () => import('@/views/AdminCreateAccount.vue')
  },
  {
    path: '*',
    redirect: '/',
    show: false
  }
]

export default Routes
