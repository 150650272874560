






































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import FormItems from '@/components/shared/FormItems.vue'
import FormItemsOption from '@/types/FormItemsOption'
import { getEnumOptions, getEnumValueArray } from '@/lib/Utils'
import Partner from '@/enums/Partner'
import { SheetHandler } from '@/lib/SheetHandler'
import dayjs from 'dayjs'
import { AccountingField } from '@/types/AccountingField'
import { ExportingParam } from '@/types/apiRequest/ExportingParam'
import { GetAccounting, GetAccountingResponse } from '@/adapters/GetAccounting'
import { GetReporting, GetReportingResponse } from '@/adapters/GetReporting'
import { ReportingField } from '@/types/ReportingField'
import { ReportingExcelColumnNames } from '@/data/ReportingExcelColumnNames'
import { MasterCode } from '@/adapters/sheet/MasterCode'

@Component({
  components: { FormItems }
})
export default class ExportingExcelModal extends Vue {
  @Prop(String) modalType!: string

  field: { payment_complete_at: Array<Date | undefined>; partner?: Partner } = {
    payment_complete_at: [],
    partner: undefined
  }

  accountingOptions: Array<FormItemsOption> = [
    { prop: 'payment_complete_at', label: 'Payment Complete At', type: 'dateRange', class: 'date-range', value: undefined },
    {
      prop: 'partner',
      label: 'Partner',
      type: 'select',
      class: 'partner-select',
      filterable: true,
      options: getEnumOptions(Partner, getEnumValueArray(Partner)),
      value: undefined,
      clearable: true
    }
  ]

  accountingRules: Dictionary<Array<FormRule>> = {}

  async formValidate () {
    try {
      return await (this.$refs.searchForm as Vue & { validate: () => boolean | any }).validate()
    } catch (error) {
      return error
    }
  }

  async getAccountingList (): Promise<GetAccountingResponse> {
    const params: ExportingParam = {
      partner: this.field.partner
    }
    if (this.field.payment_complete_at[0]) {
      params.payment_complete_from = dayjs(this.field.payment_complete_at[0]).format('YYYY-MM-DDT00:00:00.0Z')
      params.payment_complete_to = dayjs(this.field.payment_complete_at[1]).format('YYYY-MM-DDT23:59:59.0Z')
    }
    const response: GetAccountingResponse = await GetAccounting.getInstance().request(params)
    return response
  }

  async getReportingList (): Promise<GetReportingResponse> {
    const params: ExportingParam = {
      partner: this.field.partner
    }
    if (this.field.payment_complete_at[0]) {
      params.payment_complete_from = dayjs(this.field.payment_complete_at[0]).format('YYYY-MM-DDT00:00:00.0Z')
      params.payment_complete_to = dayjs(this.field.payment_complete_at[1]).format('YYYY-MM-DDT23:59:59.0Z')
    }
    const response: GetReportingResponse = await GetReporting.getInstance().request(params)
    return response
  }

  refineReportingFields (originalList: Array<ReportingField>): Array<any> {
    let translatedList: Array<any> = []

    const masterCodes: Dictionary<string> = MasterCode.getMasterCodeLabels()

    originalList.forEach((field, index) => {
      translatedList[index] = {}
      Object.keys(field).forEach(key => {
        translatedList[index][ReportingExcelColumnNames[key]] = key === 'receiver_account_bank' ? masterCodes[`${field[key as keyof ReportingField]}`] : field[key as keyof ReportingField]
      })
    })

    return translatedList
  }

  async onSubmit (bookType: 'xlsx' | 'csv') {
    const sheetHandler = SheetHandler.getInstance()
    const isAccounting: boolean = this.modalType === 'Accounting'
    const response: GetAccountingResponse | GetReportingResponse = isAccounting ? await this.getAccountingList() : await this.getReportingList()
    const CLIENT_TIMEOUT_CODE: number = 20
    if (response.code === CLIENT_TIMEOUT_CODE) {
      return this.$message({ message: 'Please shorten the search period', type: 'error' })
    }
    const exportingList: Array<AccountingField | ReportingField> | undefined = response.data?.list
    const emptyExportingList: boolean = !exportingList?.length
    if (emptyExportingList || response.code !== 200) {
      const message = 'There is no Accounting List'
      return this.$message({ message, type: 'error' })
    }
    sheetHandler.sheetJson = isAccounting ? { accounting: exportingList as Array<AccountingField> } : { accounting: this.refineReportingFields(exportingList as Array<ReportingField>) }
    const completeFrom = dayjs(this.field.payment_complete_at[0]).format('YYYY-MM-DD')
    const completeTo = dayjs(this.field.payment_complete_at[1]).format('YYYY-MM-DD')
    const name = isAccounting ? `[당행] ${completeFrom}-${completeTo}_biz_accounting`
      : `${completeFrom}-${completeTo}_전자지급결제대행_내역(지급)${Partner[this.field.partner as Partner] === undefined ? '' : `_${Partner[this.field.partner as Partner]}`}`
    sheetHandler.saveSheetByJson(name, bookType)
  }
}
