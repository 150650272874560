import { sentbizApi } from '../axios'
import { ChangePasswordParam } from '@/types/apiRequest/ChangePasswordParam'

export class ChangePassword {
  private static instance: ChangePassword
  private constructor() {}
  public static getInstance(): ChangePassword {
    if (!this.instance) this.instance = new ChangePassword()
    return this.instance
  }

  public async password(data: ChangePasswordParam): Promise<any> {
    const requestOption: RequestOption = {
      url: 'corp-member/password',
      method: 'post',
      data
    }
    const response = await sentbizApi.request(requestOption)
    return response
  }
}
