export const fraudDescription =
  'Fraud Alert란 고객/가맹점에게 공유 되는 부정거래 관련 통계 수치입니다. 비자카드의 정책중, 아래 명시 되어 있는 조건 이하로 유지를 못할 경우 비자카드 감시 대상/Visa Fruad Monitoring Program (VFMP)에 포함되어 다양한 피해를 받을 수 있어 이를 방지하고자 공유 되는 참고 자료 입니다. '
export const disputeDescription =
  'Dispute Alert란 비자카드의 정책 중, 아래 명시되어 있는 조건 이하로 유지를 못 할 경우 비자카드 감시 대상/Visa Dispute Monitoring Program (VDMP)에 포함되어 다양한 피해를 받을 수 있습니다.'
export const fraudConditionDescription = `부정거래 비율 ($ 액수) = Chargeback-Fraud / 시스템탐지결과-Pass < 0.65%<br/>
부정거래 액수 ($ 액수) = Chargeback-Fraud < $50,000<br/>
<br/>
예시)  •Chargeback-Fraud ($3,000)<br/>
          •시스템탐지결과-Pass ($1,000,000)<br/>
<br/>
부정거래 비율 ($ 액수) = 0.3% ($3,000 / $1,000,000 * 100)<br/>
부정거래 액수 ($ 액수) = $3,000`

export const disputeConditionDescription = `분쟁 신청 비율 (# 건수) = [(분쟁_승소)+(분쟁_패소)] / 시스템탐지결과-Pass < 0.9%<br/>
분쟁 신청 건수 (# 건수) = [(분쟁_승소)+(분쟁_패소)] < 100<br/>
<br/>
예시) • 시스템탐지결과-Pass (1000건)<br/>
         •분쟁_승소 (2건), 분쟁_패소 (3건)<br/>
         <br/>
분쟁 신청 비율 (# 건수) = [(2)+(3)] / 1000 = 0.5%<br/>
분쟁 신청 건수 (# 건수) = [(2)+(3)] = 5`
