import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import '../element-variables.scss'
import './assets/styles/index.scss'
import DefaultLayout from '@/components/commons/DefaultLayout.vue'

Vue.config.productionTip = false

Vue.use(Element, { locale })
Vue.component('DefaultLayout', DefaultLayout)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
