export const ReportingExcelColumnNames: {[key: string]: string} = {
  store_classification: '취급점포코드',
  store_code: '취급점포소재지식별번호',
  transfer_classification: '전송구분코드',
  tid: '일련번호',
  send_date: '송금일자',
  send_currency_code: '송금통화코드',
  send_amount: '송금외화금액',
  send_trans_usd_rate: '송금USD환산환율',
  send_trans_usd_amount: '송금USD환산금액',
  send_request_date: '송금의뢰일자',
  sender_name: '송금의뢰인명',
  payment_method: '결제대행방식',
  sender_code_classification: '송금의뢰인주민사업자번호구분코드',
  sender_code: '송금의뢰인 식별번호',
  sender_nation_code: '송금의뢰인 국가코드',
  sender_currency_code: '송금의뢰인 통화코드',
  send_request_amount: '송금의뢰금액',
  send_request_trans_usd_rate: '송금의뢰USD환산환율',
  send_request_trans_usd_amount: '송금의뢰USD환산금액',
  sender_management_code: '송금의뢰인 관리번호',
  receiver_name: '송금수취인명',
  receiver_resident_classification: '송금수취인거주성구분코드',
  receiver_nation_code: '송금수취인국가코드',
  receiver_account_bank: '송금수취인 계좌개설은행명',
  receiver_account_number: '송금수취인 계좌번호'
}
