import store from '@/store'
import { Recipient, RecipientWithComment } from '@/types/Recipient'
import { ITransfersUseCase, TransfersUseCase } from '@/usecase/TransfersUseCase'

export interface ITransferDetailPresentation {
  onClickDetailCancel (): void
  updateTransferDetail (transferDetailField: RecipientWithComment): Promise<any>
}

export class TransferDetailPresentation implements ITransferDetailPresentation {
  private static instance: TransferDetailPresentation
  private constructor () {}

  public static getInstance (): TransferDetailPresentation {
    if (!this.instance) this.instance = new TransferDetailPresentation()
    return this.instance
  }

  transfersUseCase: ITransfersUseCase = new TransfersUseCase()

  public transferDetailField: Dictionary<RecipientWithComment> = {}
  public transferDetailModal: boolean = false

  onClickDetailCancel (): void {
    this.transferDetailModal = !this.transferDetailModal
  }

  updateTransferDetail (transferDetailField: RecipientWithComment): Promise<any> {
    const analogueComment = transferDetailField.analogue_comment
    delete transferDetailField.analogue_comment
    const recipient = transferDetailField
    const data: Dictionary<number | Recipient | string> = {
      remittance_id: Number(store.getters.getClickedTransferDetailId),
      recipient,
      analogue_comment: analogueComment || ''
    }

    const response: any = this.transfersUseCase.updateTransferDetail(data)
    store.commit('setClickedTransferDetailId', '')
    return response
  }
}
