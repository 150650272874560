import Currency from '@/enums/Currency'
import { Fee } from '@/types/CorpInformation'
import Country from '@/enums/Country'
import { CalculatorSourceType, ICalculatorSource } from '@/lib/calculator/Calculator'
import { Remittance } from '@/types'
import ReceiveMethod from '@/enums/ReceiveMethod'

export interface CalculateOption {
  baseAmount: number
  baseAmountCurrency: keyof typeof Currency
  sendAmountCurrency: keyof typeof Currency
  receiveAmountCurrency: keyof typeof Currency
  defaultBaseCurrency: keyof typeof Currency
  receiveMethod?: string
  recipientCountry?: keyof typeof Country
  fee: Fee
  isReceipt?: boolean
  version?: number
}

export class CalculateOptionV3 {
  source: ICalculatorSource
  constructor(source: ICalculatorSource) {
    this.source = source
  }

  private getReceiptOption(remittance: Remittance): CalculateOption {
    return {
      baseAmount: remittance.base_amount.balance,
      baseAmountCurrency: remittance.base_amount.currency,
      sendAmountCurrency: remittance.send_amount.currency,
      receiveAmountCurrency: remittance.receive_amount.currency,
      defaultBaseCurrency: 'USD',
      receiveMethod: ReceiveMethod[remittance.recipient.remittance_method_type as number],
      recipientCountry: remittance.recipient.country,
      fee: {
        fixed: remittance.fixed_fee,
        rate: remittance.rate_fee,
        ratio: remittance.ratio,
        min_amount: remittance.min_amount,
        min_fee: remittance.min_fee,
        fee_currency: remittance.fee_currency,
        country: remittance.recipient.country,
        currency: remittance.receive_amount.currency
      },
      isReceipt: true,
      version: remittance.version
    }
  }

  private getCurrencyReceiptOption(remittance: Remittance): CalculateOption {
    return {
      baseAmount: remittance.base_amount.balance,
      baseAmountCurrency: remittance.base_amount.currency,
      sendAmountCurrency: remittance.send_amount.currency,
      receiveAmountCurrency: remittance.receive_amount.currency,
      defaultBaseCurrency: 'USD',
      fee: {
        fixed: remittance.fixed_fee,
        rate: remittance.rate_fee,
        ratio: remittance.ratio,
        min_amount: remittance.min_amount,
        min_fee: remittance.min_fee,
        fee_currency: remittance.fee_currency,
        country: remittance.recipient.country,
        currency: remittance.receive_amount.currency
      },
      isReceipt: true,
      version: remittance.version
    }
  }

  get option(): CalculateOption {
    let option: CalculateOption | null = null
    switch (this.source.type) {
      case CalculatorSourceType.RECEIPT_INDIVIDUAL_RECEIPT:
      case CalculatorSourceType.RECEIPT_TRANSACTION_RECEIPT:
      case CalculatorSourceType.TRANSFER_CONFIRMATION:
        const remittanceHistoryGroupDetail = this.source.remittanceHistoryGroupDetail
        if (!remittanceHistoryGroupDetail) break
        option = this.getReceiptOption(remittanceHistoryGroupDetail)
        break
      case CalculatorSourceType.TRANSFER_HISTORY:
      case CalculatorSourceType.TRANSFER_CURRENCY_RECEIPT:
        const historyGroupDetail = this.source.remittanceHistoryGroupDetail
        if (!historyGroupDetail) break
        option = this.getCurrencyReceiptOption(historyGroupDetail)
        break
      default:
        break
    }
    if (!option) {
      const errorSourceType = CalculatorSourceType[this.source.type]
      throw new Error(`There is no option source [${errorSourceType}]`)
    }
    return option
  }
}
