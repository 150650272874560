import { Recipient } from '@/types/Recipient'
import { sentbizApi } from '../axios'

interface IUpdateTransferDetail {
  request (params: Dictionary<number | Recipient | string>): Promise<any>
}

export class UpdateTransferDetail implements IUpdateTransferDetail {
  private static instance: UpdateTransferDetail
  private constructor () {}

  public static getInstance (): UpdateTransferDetail {
    if (!this.instance) this.instance = new UpdateTransferDetail()
    return this.instance
  }

  async request (params: Dictionary<number | Recipient | string>): Promise<any> {
    const option: RequestOption = {
      url: 'hades/remittance/recipient',
      method: 'put',
      data: params
    }
    const response = await sentbizApi.request(option)
    return response
  }
}
