import Vue from 'vue'
import axios, { AxiosInstance } from 'axios'
import router from '@/router'
import store from '@/store'
import AxiosOptions from '@/adapters/axios/AxiosOptions'

const $this = new Vue()

export interface AxiosCreator {
  create (options: AxiosOptions): AxiosInstance;
}

export class AxiosCreator implements AxiosCreator {
  axiosInstance: AxiosInstance = axios.create()

  create (options: AxiosOptions): AxiosInstance {
    this.setRequestAction(options)
    this.setResponseAction(options)
    return this.axiosInstance
  }

  private setRequestAction (options: AxiosOptions) {
    this.axiosInstance.interceptors.request.use(
      config => {
        store.commit('enableLoading')
        config.headers.post['Content-Type'] = 'application/json'
        config.baseURL = options.api
        if (options.isAuthTokenRequired) {
          const auth = localStorage.getItem('auth')
          if (auth) config.headers.common.Authorization = `Bearer ${auth}`
        }
        return config
      },
      error => {
        store.commit('disableLoading')
        return Promise.reject(error)
      }
    )
  }

  private setResponseAction (options: AxiosOptions) {
    this.axiosInstance.interceptors.response.use(
      response => {
        store.commit('disableLoading')
        const isError = response.data.code && response.data.code !== 200
        if (isError) {
          const message = '요청 에러가 발생 했습니다.'
          $this.$message({
            message: `${message} [${response.data.code}]`,
            type: 'error'
          })
        }
        return response.data || {}
      },
      error => {
        store.commit('disableLoading')
        const statusCode = error.response.status
        let message = error.response ? `${statusCode} ${error.response.statusText}` : 'Network Error'
        if (options.isAuthTokenRequired) {
          const invalidToken = statusCode === 401
          if (invalidToken) {
            localStorage.clear()
            message = `${statusCode} 인증 정보가 올바르지 않습니다. 로그인 화면으로 이동합니다.`
            router.push('/signIn')
          }
        }
        $this.$message({ message, type: 'error' })
        return error.response
      }
    )
  }
}
