















import { Component, Watch } from 'vue-property-decorator'
import Routes from '@/router/Routes'
import ElMenuItem from '@/components/commons/ElMenuItem.vue'
import ElSubMenu from '@/components/commons/ElSubMenu.vue'
import RouterOption from '@/types/RouterOption'
import { SearchCorp, SearchCorpParam, SearchCorpResponse } from '@/adapters/affiliate/SearchCorp'
import { CorpStatus } from '@/enums/CorpStatus'
import { SearchAnalogueRemittance, SearchAnalogueRemittanceParam, SearchAnalogueRemittanceResponse } from '@/adapters/remittance/SearchAnalogueRemittance'
import { getEnumValueArray } from '@/lib/Utils'

@Component({
  components: {
    ElMenuItem,
    ElSubMenu
  }
})
export default class NaviMenu extends ElSubMenu {
  navs: Array<RouterOption> = []
  activePath = ''

  affiliateListCount: number = 0
  analogueCount: number = 0

  @Watch('$route', { immediate: true })
  setRoutePath ({ path = '' }): void {
    this.activePath = path
  }

  async created () {
    const searchCorpParam: SearchCorpParam = {
      page: 1,
      unit: 1,
      corp_status: getEnumValueArray(CorpStatus).filter(corpStatus => corpStatus !== `${CorpStatus.CONFIRMED}`)
    }
    const affiliateResponse: SearchCorpResponse = await SearchCorp.getInstance().request(searchCorpParam)
    this.affiliateListCount = affiliateResponse.count
    const remittanceSearchParam: SearchAnalogueRemittanceParam = {
      admin_menu_type: 5,
      page: 1,
      unit: 1,
      analogue_filter: 1
    }
    const analogueResponse: SearchAnalogueRemittanceResponse = await SearchAnalogueRemittance.getInstance().request(remittanceSearchParam)
    this.analogueCount = analogueResponse?.total_count
    this.navs = Routes.filter(route => {
      const isAffiliate = route.path === '/affiliate'
      const isTransfers = route.path === '/transfers'
      if (isAffiliate && route.children) {
        const affiliateListRoute: RouterOption | undefined = route.children.find(child => child.path === 'list')
        if (affiliateListRoute) affiliateListRoute.suffix = `${this.affiliateListCount}건`
      } else if (isTransfers && route.children) {
        const transfersAnalogRoute : RouterOption | undefined = route.children.find(child => child.path === ':analogue')
        if (transfersAnalogRoute) transfersAnalogRoute.suffix = `${this.analogueCount?.toString()}건`
      }
      route.children = route.children?.filter(child => child.show)
      return route.show
    })
  }
}
