import { sentbizApiWithoutAuth } from '../axios'

export class ConfirmOtp {
  private static instance: ConfirmOtp
  private constructor () {}
  public static getInstance (): ConfirmOtp {
    if (!this.instance) this.instance = new ConfirmOtp()
    return this.instance
  }

  public async request (data: Otp): Promise<any> {
    const requestOption: RequestOption = {
      url: 'corp-member/otp',
      method: 'post',
      data
    }
    const response = await sentbizApiWithoutAuth.request(requestOption)
    return response
  }
}
