




















import Vue from 'vue'
import FilterButton from '@/dome/components/filter/inputs/FilterButton.vue'

export default Vue.extend({
  name: 'TransactionFilterWrapper',
  components: { FilterButton }
})
