import { writeFile, utils, WorkSheet, ColInfo, JSON2SheetOpts, BookType } from 'xlsx'

export interface SheetHandler {
  getInstance (): SheetHandler;
  sheetJson: Dictionary<Array<Dictionary<any>>>;
  saveSheetByJson (name: string): void;
}

export class SheetHandler implements SheetHandler {
  private static instance: SheetHandler
  private constructor () {
    this.sheetJson = {}
  }

  public static getInstance (): SheetHandler {
    if (!this.instance) this.instance = new SheetHandler()
    return this.instance
  }

  public sheetJson: Dictionary<Array<Dictionary<any>>>

  private getColumnOptions (sheetJson: Array<Dictionary<any>>): Array<ColInfo> {
    const columnInfos: Array<ColInfo> = []
    Object.keys(sheetJson[0]).forEach(columnKey => {
      const columnKeyWidth: number = columnKey.length + 3
      const columnNameWidth: number = `${sheetJson[0][columnKey]}`.length * 1.6 + 1
      columnInfos.push({ wch: Math.floor(Math.max(columnKeyWidth, columnNameWidth)) })
    })
    return columnInfos
  }

  private jsonToSheet (sheetJson: Array<Dictionary<any>>, option: JSON2SheetOpts = {}): WorkSheet {
    return utils.json_to_sheet(sheetJson, option)
  }

  public saveSheetByJson (name = 'sentbiz_accounting', bookType: BookType = 'xlsx') {
    const workBook = utils.book_new()
    Object.keys(this.sheetJson).forEach(sheetName => {
      const eachSheetJson = this.sheetJson[sheetName]
      const eachSheet: WorkSheet = this.jsonToSheet(eachSheetJson)
      eachSheet['!cols'] = this.getColumnOptions(eachSheetJson)
      utils.book_append_sheet(workBook, eachSheet, sheetName)
    })
    writeFile(workBook, `${name}.${bookType}`, { bookType })
  }
}
