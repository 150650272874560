import { CorpFee } from '@/presentation/affiliated/CorpFee'
import FormItemsOption from '@/types/FormItemsOption'
import { CountryPhoneCodes } from '@/data/CountryPhoneCodes'
import { staticValues } from '@/lib/StaticValues'
import Country from '@/enums/Country'

export interface FeeModal {
  defaultFeeField: CorpFee
  defaultFeeOptions: Array<FormItemsOption>
  countryFeeFields: Array<CorpFee>
  countryFeeOptions: Array<Array<FormItemsOption>>

  setFees(feesParam: Array<CorpFee>): void

  addCountryFee(): void

  removeCountryFee(index: number): void

  onChangeRatio(index?: number): void

  onSubmit(): Array<CorpFee>
}

export class FeeModal implements FeeModal {
  defaultFeeField: CorpFee = {
    country: 'ZZ',
    currency: undefined,
    version: 3,
    fee_currency: 'USD',
    fixed: 0,
    rate: 0,
    ratio: 100,
    receiver_ratio: 0,
    min_amount: undefined,
    min_fee: undefined
  }

  defaultFeeOptions: Array<FormItemsOption> = [
    {
      prop: 'country',
      label: 'Country',
      type: 'text',
      size: 'medium',
      value: 'ZZ',
      disabled: true
    },
    {
      prop: 'currency',
      label: 'Currency',
      type: 'select',
      size: 'medium',
      disabled: true,
      options: [{ label: 'ZZZ', value: 'ZZZ' }],
      value: 'ZZZ'
    },
    {
      prop: 'version',
      label: 'Version',
      type: 'select',
      size: 'medium',
      options: [
        { label: '3', value: 3 },
        { label: '4', value: 4 }
      ],
      value: 3
    },
    {
      prop: 'fee_currency',
      label: 'Fee Currency',
      type: 'select',
      size: 'medium',
      filterable: true,
      options: [
        { label: 'USD', value: 'USD' },
        { label: 'KRW', value: 'KRW' }
      ],
      value: 'USD'
    },
    {
      prop: 'fixed',
      label: 'Fixed',
      type: 'text',
      subType: 'number',
      size: 'medium',
      step: 0.01,
      min: 0,
      value: undefined
    },
    {
      prop: 'rate',
      label: 'Rate',
      type: 'text',
      subType: 'number',
      size: 'medium',
      suffix: '%',
      step: 0.001,
      max: 100,
      min: 0,
      value: undefined
    },
    {
      prop: 'ratio',
      label: 'Sender Charge',
      type: 'text',
      subType: 'number',
      size: 'medium',
      suffix: '%',
      step: 0.001,
      max: 100,
      min: 0,
      emitHandlerName: 'onChangeRatio',
      value: undefined
    },
    {
      prop: 'receiver_ratio',
      label: 'Recipient Charge',
      type: 'text',
      subType: 'number',
      size: 'medium',
      suffix: '%',
      max: 100,
      min: 0,
      disabled: true,
      value: undefined
    },
    {
      prop: 'min_amount',
      label: 'Cutline for Min. Fee',
      type: 'text',
      subType: 'number',
      size: 'medium',
      value: undefined
    },
    {
      prop: 'min_fee',
      label: 'Minimum Fee',
      type: 'text',
      subType: 'number',
      size: 'medium',
      value: undefined
    }
  ]

  countryFeeFields: Array<CorpFee> = []
  countryFeeOptions: Array<Array<FormItemsOption>> = []

  private countryFeeOption: Array<FormItemsOption> = [
    {
      prop: 'country',
      label: 'Country',
      type: 'select',
      size: 'medium',
      filterable: true,
      remote: true,
      options: this.getCountryOptions().sort((a, b) => {
        const nameA = a.label
        const nameB = b.label
        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0
      }),
      value: undefined,
      emitHandlerName: 'onSelectCountry'
    },
    {
      prop: 'currency',
      label: 'Currency',
      type: 'select',
      size: 'medium',
      options: [],
      value: undefined
    },
    {
      prop: 'version',
      label: 'Version',
      type: 'select',
      size: 'medium',
      options: [
        { label: '3', value: 3 },
        { label: '4', value: 4 }
      ],
      value: 3
    },
    {
      prop: 'fee_currency',
      label: 'Fee Currency',
      type: 'select',
      size: 'medium',
      filterable: true,
      options: [
        { label: 'USD', value: 'USD' },
        { label: 'KRW', value: 'KRW' }
      ],
      value: 'USD'
    },
    {
      prop: 'fixed',
      label: 'Fixed',
      type: 'text',
      subType: 'number',
      size: 'medium',
      step: 0.01,
      min: 0,
      value: undefined
    },
    {
      prop: 'rate',
      label: 'Rate',
      type: 'text',
      subType: 'number',
      size: 'medium',
      suffix: '%',
      step: 0.001,
      max: 100,
      min: 0,
      value: undefined
    },
    {
      prop: 'ratio',
      label: 'Sender Charge',
      type: 'text',
      subType: 'number',
      size: 'medium',
      suffix: '%',
      max: 100,
      min: 0,
      emitHandlerName: 'onChangeRatio',
      value: undefined
    },
    {
      prop: 'receiver_ratio',
      label: 'Recipient Charge',
      type: 'text',
      subType: 'number',
      size: 'medium',
      suffix: '%',
      max: 100,
      min: 0,
      disabled: true,
      value: undefined
    },
    {
      prop: 'min_amount',
      label: 'Cutline for Min. Fee',
      type: 'text',
      subType: 'number',
      size: 'medium',
      value: undefined
    },
    {
      prop: 'min_fee',
      label: 'Minimum Fee',
      type: 'text',
      subType: 'number',
      size: 'medium',
      value: undefined
    }
  ]

  getFeeCurrencyOption(staticCurrencies: Array<string>) {
    const hasReceivableCountry = staticCurrencies && staticCurrencies.length
    if (!hasReceivableCountry) return [{ label: 'USD', value: 'USD' }]
    return staticCurrencies.map(currency => {
      return {
        label: currency,
        value: currency
      }
    })
  }

  setFees(feesParam: Array<CorpFee>): void {
    if (!feesParam.length) return
    const fees: Array<CorpFee> = JSON.parse(JSON.stringify(feesParam))
    fees.forEach(fee => {
      fee.rate = Number((fee.rate * 100).toPrecision(2))
      fee.ratio = Number((fee.ratio * 100).toPrecision(2))
      fee.receiver_ratio = Number((100 - fee.ratio).toPrecision(2))
    })
    this.defaultFeeField = fees.find(fee => fee.country === 'ZZ') || fees[0]
    if (fees.length <= 1) return
    this.countryFeeFields = fees.filter(fee => fee.country !== 'ZZ')
    this.countryFeeOptions = this.countryFeeFields.map(fee => {
      const newCountryFeeOption: Array<FormItemsOption> = JSON.parse(JSON.stringify(this.countryFeeOption))
      const country = fee.country
      if (!country) return newCountryFeeOption
      const currencyOption = this.getFeeCurrencyOption(staticValues.receivableCountryCurrencies[country])
      const currencyTargetOption = newCountryFeeOption.find(option => option.prop === 'currency')
      if (!currencyTargetOption) return newCountryFeeOption
      currencyTargetOption.options = currencyOption
      const feeCurrencyOption = this.getFeeCurrencyOption(staticValues.transferableCountryCurrencies[country])
      const feeCurrencyTargetOption = newCountryFeeOption.find(option => option.prop === 'fee_currency')
      if (!feeCurrencyTargetOption) return newCountryFeeOption
      feeCurrencyTargetOption.options = feeCurrencyOption
      return newCountryFeeOption
    })
  }

  getCountryOptions() {
    return CountryPhoneCodes.map(country => {
      return {
        label: country.name,
        value: country.iso
      }
    })
  }

  getFeeCurrencyOptions(index: number) {
    const country = this.countryFeeFields[index].country as keyof typeof Country
    const staticCurrencies = staticValues.transferableCountryCurrencies[country]
    if (!country || !staticCurrencies || !staticCurrencies.length)
      return [
        { label: 'USD', value: 'USD' },
        { label: 'KRW', value: 'KRW' }
      ]
    return staticValues.transferableCountryCurrencies[country].map(currency => {
      return {
        label: currency,
        value: currency
      }
    })
  }

  getCurrencyOptions(index: number) {
    const country = this.countryFeeFields[index].country as keyof typeof Country
    const staticCurrencies = staticValues.receivableCountryCurrencies[country]
    if (!country || !staticCurrencies || !staticCurrencies.length) return [{ label: 'USD', value: 'USD' }]
    return staticValues.receivableCountryCurrencies[country].map(currency => {
      return {
        label: currency,
        value: currency
      }
    })
  }

  addCountryFee(): void {
    const defaultCountryFee: CorpFee = {
      fixed: 0,
      rate: 0,
      ratio: 100,
      receiver_ratio: 0,
      min_amount: undefined,
      min_fee: undefined,
      fee_currency: 'USD',
      country: undefined,
      currency: undefined,
      version: this.defaultFeeField.version || 3
    }
    this.countryFeeFields.push(defaultCountryFee)
    const newCountryFeeOption: Array<FormItemsOption> = JSON.parse(JSON.stringify(this.countryFeeOption))
    this.countryFeeOptions.push(newCountryFeeOption)
  }

  removeCountryFee(index: number): void {
    this.countryFeeOptions.splice(index, 1)
    this.countryFeeFields.splice(index, 1)
  }

  onChangeRatio(index?: number): void {
    const value = index === undefined ? this.defaultFeeField.ratio : this.countryFeeFields[index].ratio
    const receiverRatio = 100 - value
    if (index === undefined) this.defaultFeeField.receiver_ratio = receiverRatio
    else this.countryFeeFields[index].receiver_ratio = receiverRatio
  }

  onSelectCountry(index: number) {
    const selectedFeeCurrencyOption = this.countryFeeOptions[index].find(option => option.prop === 'fee_currency')
    const selectedCurrencyOption = this.countryFeeOptions[index].find(option => option.prop === 'currency')
    if (!selectedFeeCurrencyOption || !selectedCurrencyOption) return
    const feeCurrencyOptions = this.getFeeCurrencyOptions(index)
    const currencyOptions = this.getCurrencyOptions(index)
    selectedFeeCurrencyOption.options = feeCurrencyOptions
    selectedCurrencyOption.options = currencyOptions
  }

  onSubmit(): Array<CorpFee> {
    this.countryFeeFields.forEach(fee => {
      if (!fee.version) fee.version = this.defaultFeeField.version
    })
    return [
      {
        ...this.defaultFeeField,
        country: 'ZZ',
        currency: 'ZZZ'
      },
      ...this.countryFeeFields
    ]
  }
}
