

























import { Prop, Watch } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { GetFxRates } from '@/adapters/fxRates/GetFxRates'
import Vue from 'vue'
import { Decimal } from 'decimal.js'
import { KrwFxRates } from '@/adapters/fxRates/KrwFxRates'
import BasicDialog from '@/components/commons/BasicDialog.vue'

@Component({
  components: { BasicDialog }
})
export default class FxIdModal extends Vue {
  @Prop() ratesModal!: boolean
  @Prop() fxId!: number

  ratesTime!: string
  krwRates!: KrwFxRates
  fxTaskId!: string
  currencyList: Dictionary<string> = {
    aud: '호주 달러',
    bdt: '방글라데시 타카',
    cad: '캐나다 달러',
    cny: '중국 위안',
    eur: '유로',
    gbp: '영국 파운드',
    hkd: '홍콩 달러',
    idr: '인도네시아 루피아',
    inr: '인도 루피',
    jpy: '일본 엔',
    khr: '캄보디아 리엘',
    krw: '대한민국 원',
    lkr: '스리랑카 루피',
    myr: '말레이시아 링깃',
    npr: '네팔 루피',
    php: '필리핀 페소',
    pkr: '파키스탄 루피',
    sgd: '싱가포르 달러',
    thb: '태국 바트',
    try: '터키 리라',
    usd: '미국 달러',
    vnd: '베트남 동'
  }
  ratesDetailDictionary: Dictionary<any> = {}

  ratesDetail() {
    Decimal.set({ precision: 32 })
    const ratesDetail: Array<Dictionary<any>> = []
    const unitHundredCurrency = ['vnd', 'idr', 'jpy']
    const krwRates: Dictionary<any> = Object.assign({}, this.krwRates)
    let usdDetail: Dictionary<any> = {}
    Object.keys(krwRates).forEach(rateName => {
      if (!['id', '@type'].includes(rateName)) {
        const [currency] = rateName.split('_')
        const name = this.currencyList[currency]
        if (typeof name === 'string' && name.includes('currency')) return
        const isUnitHundredCurrency: boolean = unitHundredCurrency.includes(currency)
        const baseAmount: string = isUnitHundredCurrency ? '100' : '1'
        const baseCurrency: string = `${baseAmount} ${currency.toUpperCase()}`
        const operator: string = ' = '
        const bigRateAmount = new Decimal(parseFloat(krwRates[rateName]))
        const rateAmount: string = isUnitHundredCurrency ? bigRateAmount.mul(100).toString() : krwRates[rateName]
        if (name === this.currencyList.usd) {
          usdDetail = Object.assign({}, { name, baseCurrency, operator, rateAmount })
          return
        }
        ratesDetail.push({ name, baseCurrency, operator, rateAmount })
      }
    })
    ratesDetail.sort((rateA, rateB) => {
      return rateA.name < rateB.name ? -1 : rateA.name > rateB.name ? 1 : 0
    })
    this.ratesDetailDictionary = [usdDetail, ...ratesDetail]
  }

  @Watch('fxId')
  async setRateTime() {
    const fxIdString = this.fxId.toString()
    this.fxTaskId = fxIdString
    this.ratesTime = `${fxIdString.slice(0, 4)}-${fxIdString.slice(4, 6)}-${fxIdString.slice(6, 8)} ${fxIdString.slice(
      8,
      10
    )}:${fxIdString.slice(10, 12)}`
    await this.setFxRates()
    this.ratesDetail()
  }

  makeRatesModalFalse() {
    this.$emit('onUnvisible')
  }

  async setFxRates(): Promise<void> {
    this.krwRates = await GetFxRates.getKrwMidRatesV3(this.fxTaskId)
  }
}
