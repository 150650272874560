import RemittanceStatus from '@/types/apiRequest/RemittanceStatus'
import { sentbizApi } from '../axios'

export interface IUpdateRemittanceStatus {
  request (params: RemittanceStatus): Promise<any>
}
export class UpdateRemittanceStatus implements IUpdateRemittanceStatus {
  private static instance: UpdateRemittanceStatus
  private constructor () {}

  public static getInstance (): UpdateRemittanceStatus {
    if (!this.instance) this.instance = new UpdateRemittanceStatus()
    return this.instance
  }

  async request (params: RemittanceStatus): Promise<any> {
    const option: RequestOption = {
      url: 'hades/remittance/status',
      method: 'put',
      data: params
    }
    const response: any = await sentbizApi.request(option)
    return response
  }
}
