

















import Vue from 'vue'
import { Component, Watch, Prop } from 'vue-property-decorator'
import FormItems from '@/components/shared/FormItems.vue'
import FormItemsOption from '../../types/FormItemsOption'

@Component({
  components: { FormItems }
})
export default class SearchComponent extends Vue {
  @Prop(Boolean) private amountModal!: boolean

  formItems: Array<FormItemsOption> = [
    {
      prop: 'selectCategory',
      type: 'select',
      useSelectAll: false,
      filterable: true,
      value: '',
      emitHandlerName: 'onChangeSelectCategory',
      options: [
        { label: '송금 그룹 ID', value: 'group_code' },
        { label: 'Fx ID', value: 'fx_task_id' },
        { label: '고객사 ID', value: 'corp_id' },
        { label: '고객사 이름', value: 'corp_name' },
        { label: 'by amount', value: 'byAmount' }
      ]
    },
    {
      prop: 'searchInputWithIcon',
      type: 'text',
      suffixIcon: 'el-icon-search',
      value: undefined,
      useSearch: true
    }
  ]

  searchOption: string = ''

  refreshOptions () {
    this.formItems[0].value = ''
  }

  onClickSearch () {
    const clickedObject = {
      [this.searchOption]: this.formItems[1].value
    }
    this.$emit('clickedSearch', clickedObject)
  }

  onChangeSelectCategory (value: string) {
    this.$emit('changedSelect', value)
    this.searchOption = value
  }

  @Watch('amountModal')
  onModalChanged () {
    if (!this.amountModal) this.refreshOptions()
  }
}
