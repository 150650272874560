










import Vue from 'vue'

export default Vue.extend({
  name: 'FilterCheckbox',
  props: {
    label: {
      type: String,
      required: true
    },
    initialValue: {
      type: Boolean,
      required: false,
      default: false
    }
  }
})
