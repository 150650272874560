

























import DefaultLayout from '@/components/commons/DefaultLayout.vue'
import Vue from 'vue'
import BasicDialog from '@/components/commons/BasicDialog.vue'
import DomeTransactionDetailsModal from '@/dome/container/DomeTransactionalInfo/DomeTransactionDetailsModal.vue'
import FraudTransactionsContainer from '@/dome/container/FraudTransactionsContainer.vue'
import { FraudTransactionActions, FraudTransactionGetters } from '@/dome/store/fraudTransactionState'
import { mapActions, mapGetters } from 'vuex'
import { IFraudScoreReason, IFraudTransactionDetail } from '@/dome/types/FraudTypes'
import { IDomeTransactionalTables } from '@/dome/types/IDomeTransactionalInfo'
import { FraudStatus, PaymentTypeLabel } from '@/dome/constants/FraudStatus'
import dayjs from 'dayjs'
import LayoutSideButton from '@/dome/components/fraudTransactions/buttons/LayoutSideButton.vue'
import TransactionFilterContainer from '@/dome/container/TransactionFilterContainer.vue'
import SubDashboardContainer from '@/dome/container/SubDashboardContainer.vue'
import FraudExcelContainer from '@/dome/container/FraudExcelContainer.vue'
import SelectCorpContainer from '@/dome/container/SelectCorpContainer.vue'

export default Vue.extend({
  components: {
    SelectCorpContainer,
    FraudExcelContainer,
    SubDashboardContainer,
    TransactionFilterContainer,
    LayoutSideButton,
    FraudTransactionsContainer,
    DefaultLayout,
    BasicDialog,
    DomeTransactionDetailsModal
  },
  data() {
    return {
      isPopoverOpen: false,
      isDetailsModalOpen: false,
      tableData: {},
      hasApplied: false,
      actionToStringObject: {
        [FraudStatus.ALL]: '',
        [FraudStatus.FRAUD_PASS]: 'FRAUD_PASS',
        [FraudStatus.FRAUD_BLOCK]: 'FRAUD_BLOCK',
        [FraudStatus.DISPUTE_WON]: '분쟁_승소',
        [FraudStatus.DISPUTE_LOST]: '분쟁_패소',
        [FraudStatus.CHARGEBACK_ACCEPT]: 'CHARGEBACK_ACCEPT',
        [FraudStatus.CHARGEBACK_FRAUD]: 'CHARGEBACK_FRAUD'
      }
    }
  },
  computed: {
    ...mapGetters('fraudTransactionState', {
      fraudTransactionDetail: FraudTransactionGetters.FraudTransactionDetail
    }),
    filterIcon(): string {
      return this.hasApplied ? 'el-icon-success' : 'el-icon-s-operation'
    }
  },
  methods: {
    ...mapActions('fraudTransactionState', {
      fetchFraudTransactionDetail: FraudTransactionActions.FetchFraudTransactionDetail
    }),
    async onClickTransaction(id: number) {
      await this.fetchFraudTransactionDetail(id)
      this.tableData = this.convertDetailToTables(this.fraudTransactionDetail)
      this.isDetailsModalOpen = true
    },
    parseEnumName(enumName: string): string {
      return enumName
        .toLowerCase()
        .replace('_', ' ')
        .replace(/^.|(?<=\s)./g, c => c.toUpperCase())
    },
    stringifyFraudReasons(reasons: Array<IFraudScoreReason>): string {
      let result: string = ''

      reasons.forEach(reason => {
        result += `${reason.name}:${reason.value}${reason.details.length ? `${reason.details}` : ''}<br>`
      })

      return result
    },
    makeDashWhenUndefined(value: string): string {
      return value.length ? value : '-'
    },
    convertDetailToTables(detail: IFraudTransactionDetail): IDomeTransactionalTables {
      return {
        '결제 정보': [
          {
            label: '결제 금액',
            value: `${this.makeDashWhenUndefined(detail.amount.toLocaleString())} ${this.makeDashWhenUndefined(
              detail.currency
            )}`,
            className: 'amount'
          },
          { label: '거래 상태', value: `${this.actionToStringObject[detail.fraud_status]}` },
          { label: '거래일', value: `${dayjs(detail.created_at).format('YYYY-MM-DD')}` },
          { label: '고객명', value: `${this.makeDashWhenUndefined(detail.customer_name)}` },
          { label: '이메일', value: `${this.makeDashWhenUndefined(detail.customer_email)}` },
          { label: '결제 타입', value: `${PaymentTypeLabel[detail.payment_type]}` },
          {
            label: '카드 번호',
            value: `${detail.card_number.length ? detail.card_number.match(/.{1,4}/g)?.join(' ') : '-'}`
          }
        ],
        '거래 정보': [
          { label: 'TID', value: `${this.makeDashWhenUndefined(detail.tid)}` },
          { label: '주문번호', value: `${this.makeDashWhenUndefined(detail.order_number)}` },
          { label: '상품명', value: `${detail.fraud_payment_items[0] ? detail.fraud_payment_items[0].name : '-'}` }
        ],
        'Fraud 정보': [
          { label: 'Fraud ID', value: `${this.makeDashWhenUndefined(detail.fraud_id)}` },
          { label: 'Fraud 상태', value: `${this.actionToStringObject[detail.fraud_status]}` },
          { label: 'Fraud 점수', value: `${Math.round(detail.fraud_score)}` },
          { label: 'Fraud 상세', value: '', details: `${this.stringifyFraudReasons(detail.fraud_score_reasons)}` }
        ],
        '상점 정보': [
          { label: '가맹점명', value: `${this.makeDashWhenUndefined(detail.seller_name)}` },
          { label: 'Seller ID', value: `${this.makeDashWhenUndefined(detail.seller_id)}` }
        ]
      }
    },
    setFilterApplied() {
      this.hasApplied = true
      this.isPopoverOpen = false
    },
    setFilterReset() {
      this.hasApplied = false
    }
  }
})
