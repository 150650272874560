





































import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'

@Component
export default class SearchAmountModal extends Vue {
  @Prop() private amountModal!: boolean;
  stringModal: string = 'KRW'
  inputFrom = ''
  inputTo = ''

  onClickClose () {
    this.$emit('onClickClose')
  }

  onClickSearch () {
    this.$emit('onClickSearch', this.getInputAmount)
  }

  get getInputAmount () {
    const inputAmount: Object = {
      inputFrom: this.inputFrom,
      inputTo: this.inputTo
    }
    return inputAmount
  }
}
