import { AffiliateDocument, Corp } from '@/adapters/affiliate/Corp'
import { SearchCorp, SearchCorpParam, SearchCorpResponse } from '@/adapters/affiliate/SearchCorp'
import { UpdateCorpMemo } from '@/adapters/affiliate/UpdateCorpMemo'
import { CorpStatus } from '@/enums/CorpStatus'
import { FileTransferHandler } from '@/lib/FileTransferHandler'
import { getEnumValueArray } from '@/lib/Utils'
import AffiliateTable from '@/model/AffiliateTable'
import DataTableOption from '@/types/DataTableOption'
import FormItemsOption from '@/types/FormItemsOption'
import { ElMessage } from 'element-ui/types/message'
import { ElMessageBoxShortcutMethod } from 'element-ui/types/message-box'
import { AffiliateCompany } from './AffiliateCompany'

export default class SuperAffiliateList implements AffiliateTable {
    fileTransferHandler: FileTransferHandler = FileTransferHandler.getInstance()

    statusFormField: { status: CorpStatus | Array<CorpStatus> } = {
      status: CorpStatus.PROCESSING
    }

    radioLabelList: Array<string> = [
      'ALL',
      'Sign Up In Progress',
      'Submitted, Waiting for Approval'
    ]

    radioOptions: Array<{ label: string, value: Array<number> | number, key?: string }> = [
      {
        label: this.radioLabelList[0],
        value: getEnumValueArray(CorpStatus).filter(corpStatus => corpStatus !== `${CorpStatus.CONFIRMED}`),
        key: 'all'
      },
      {
        label: this.radioLabelList[1],
        value: getEnumValueArray(CorpStatus).filter(corpStatus => corpStatus !== `${CorpStatus.CONFIRMED}` && corpStatus !== `${CorpStatus.PROCESSING}`),
        key: 'processing'
      },
      {
        label: this.radioLabelList[2],
        value: CorpStatus.PROCESSING
      }
    ]

    statusFormOptions: Array<FormItemsOption> = [
      {
        prop: 'status',
        label: '상태',
        type: 'radioGroup',
        options: this.radioOptions,
        value: CorpStatus.PROCESSING.toString(),
        emitHandlerName: 'onChangeStatus'
      }
    ]

    corpList: Array<Corp> = []
    tableData: Array<AffiliateCompany> = []

    page = 1
    unit = 5
    totalCount = 0

    tableOptions: Dictionary<DataTableOption> = {}

    isCorpModalOpen = false
    isCorpMemoModalOpen = false
    corpMemoModalContent = 'sss'

    selectedCorpId = ''
    selectedCorpDocuments: Array<AffiliateDocument> = []

    confirm: ElMessageBoxShortcutMethod
    message: ElMessage
    constructor (confirm: ElMessageBoxShortcutMethod, message: ElMessage) {
      this.confirm = confirm
      this.message = message
    }

    setTableData () {}

    getCorp (): Corp | undefined {
      return this.corpList.find(corp => corp.corp_id === this.selectedCorpId)
    }

    async updateMemo (): Promise<void> {
      const param = { id: Number(this.selectedCorpId), memo: this.corpMemoModalContent }
      const response = await UpdateCorpMemo.getInstance().request(param)
      if (response.code !== 200) return
      this.message({ message: 'Successfully completed.', type: 'success' })
    }

    async getSearchCorp (corpStatus: CorpStatus | Array<CorpStatus>): Promise<SearchCorpResponse> {
      const searchCorpParam: SearchCorpParam = {
        page: this.page,
        unit: this.unit,
        corp_status: corpStatus
      }
      const response: SearchCorpResponse = await SearchCorp.getInstance().request(searchCorpParam)
      return response
    }

    async initializeTableData (): Promise<void> {}
}
