import RemittanceStatus from '@/types/apiRequest/RemittanceStatus'
import RemittanceSearch from '@/types/apiRequest/RemittanceSearch'
import { Recipient } from '@/types/Recipient'
import RemittancePartner from '@/types/apiRequest/RemittancePartner'
import { SearchTransfer } from '@/adapters/transfers/SearchTransfer'
import { UpdateTransferDetail } from '@/adapters/transfers/UpdateTransferDetail'
import { UpdateRemittanceStatus } from '@/adapters/transfers/UpdateRemittanceStatus'
import { UpdateRemittancePartner } from '@/adapters/transfers/UpdateRemittancePartner'
import { Remittance } from '@/types'
import { IndividualReceiptHandler, IndividualReceiptParam } from '@/lib/pdf/cert/IndividualReceiptHanlder'
import { CorpInformation } from '@/types/CorpInformation'
import { GetCorpInformation } from '@/adapters/corp/GetCorpInformation'
import { GetFxRates } from '@/adapters/fxRates/GetFxRates'

export interface TransferRequestParam extends RemittanceSearch {
  adminMenuType: any
}

export interface ITransfersUseCase {
  requestTransfer(remittanceSearchParam: RemittanceSearch, adminMenuType: any): Promise<any>
  updateTransferDetail(data: Dictionary<number | Recipient | string>): Promise<any>
  updateRemittanceStatus(updateRemittanceStatusParam: RemittanceStatus): Promise<any>
  updateRemittancePartner(data: RemittancePartner): Promise<any>
  makeIndividualReceipt(remittance: Remittance, isRefund?: boolean): Promise<void>
}

export class TransfersUseCase implements ITransfersUseCase {
  async requestTransfer(remittanceSearchParam: RemittanceSearch, adminMenuType: any): Promise<any> {
    const param: TransferRequestParam = Object.assign({}, remittanceSearchParam, { adminMenuType })
    const remittances = await SearchTransfer.getInstance().request(param)
    return remittances
  }

  async updateTransferDetail(data: Dictionary<number | Recipient | string>): Promise<any> {
    const response = await UpdateTransferDetail.getInstance().request(data)
    return response
  }

  updateRemittanceStatus(updateRemittanceStatusParam: RemittanceStatus): Promise<any> {
    return UpdateRemittanceStatus.getInstance().request(updateRemittanceStatusParam)
  }

  updateRemittancePartner(data: RemittancePartner): Promise<any> {
    return UpdateRemittancePartner.getInstance().request(data)
  }

  async makeIndividualReceipt(remittance: Remittance, isRefund?: boolean): Promise<void> {
    const corpInfo: CorpInformation | undefined = await GetCorpInformation.get({
      corp_id: remittance.corp_id.toString()
    })
    if (!corpInfo) return
    const fxTaskId = remittance.fx_task_id?.toString()
    if (!fxTaskId) return
    const version = remittance.version
    const rateFeeParam = {
      currency: remittance.receive_amount.currency,
      rate_fee: remittance.rate_fee
    }
    const [fxRates, krwFxRates] = await Promise.all([
      GetFxRates.get(fxTaskId.toString()),
      version === 4 ? GetFxRates.getKrwAppliedRatesV4(fxTaskId, rateFeeParam) : GetFxRates.getKrwMidRatesV3(fxTaskId)
    ])
    const isRefundParam: boolean = !!isRefund
    const individualReceiptParam: IndividualReceiptParam = {
      remittance,
      fxRates,
      krwFxRates,
      corpInfo,
      isRefund: isRefundParam
    }
    const individualReceipt = new IndividualReceiptHandler(individualReceiptParam)

    await individualReceipt.printDocument()
  }
}
