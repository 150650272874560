

























































import Vue from 'vue'
import { FraudStatus } from '@/dome/constants/FraudStatus'

export default Vue.extend({
  name: 'ChangeStatusColumn',
  props: {
    label: {
      type: String,
      required: true
    }
  },
  computed: {
    fraudStatus () {
      return FraudStatus
    }
  }
})
