





































import Vue, { PropType } from 'vue'
import SubDashboardContent from '@/dome/components/subDashboard/SubDashboardContent.vue'
import SubDashboardAlert from '@/dome/components/subDashboard/SubDashboardAlert.vue'
import { FraudAlertLabel } from '@/dome/constants/FraudStatus'

export default Vue.extend({
  name: 'SubDashboardWrapper',
  components: { SubDashboardAlert, SubDashboardContent },
  props: {
    statics: {
      type: Object as PropType<Dictionary<string>>,
      required: true
    },
    alertLabel: {
      type: Object as PropType<{ fraud: FraudAlertLabel; dispute: FraudAlertLabel }>,
      required: true
    }
  }
})
