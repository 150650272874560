import { sentbizApi } from '../axios'
import { IApiRequest } from '../axios/IApiRequest'

export interface ResetUserPasswordParams {
  id: number;
  email: string;
}

export interface ResetUserPassword extends IApiRequest {
  getInstance (): ResetUserPassword

  request (params: ResetUserPasswordParams): Promise<any>
}

export class ResetUserPassword implements ResetUserPassword {
  private static instance: ResetUserPassword

  private constructor () {}

  public static getInstance (): ResetUserPassword {
    if (!this.instance) this.instance = new ResetUserPassword()
    return this.instance
  }

  async request (params: ResetUserPasswordParams): Promise<any> {
    const requestOption: RequestOption = {
      url: 'hades/corp/member/reset',
      method: 'put',
      params
    }
    const response = await sentbizApi.request(requestOption)
    return response
  }
}
