





























import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import FormItems from '@/components/shared/FormItems.vue'
import FormItemsOption from '@/types/FormItemsOption'
import { getEnumOptions, getEnumValueArray } from '@/lib/Utils'
import Partner from '@/enums/Partner'
import { SetExternalIdParams } from '@/adapters/transfers/model/SetExternalIdParams'
import { SetExternalId } from '@/adapters/transfers/SetExternalId'

@Component({
  components: { FormItems }
})
export default class SetExternalIdModal extends Vue {
  field: SetExternalIdParams = {
    remittance_id: 0,
    partner: 0,
    external_id: '',
    transaction_id: '',
    tracking_number: '',
    tracking_sender: '',
    description: ''
  }

  setExternalOptions: Array<FormItemsOption> = [
    { prop: 'remittance_id', label: 'Remittance ID', type: 'text', value: '' },
    {
      prop: 'partner',
      label: 'Partner',
      type: 'select',
      filterable: true,
      options: getEnumOptions(Partner, getEnumValueArray(Partner)),
      value: undefined,
      clearable: true
    },
    { prop: 'external_id', label: 'External ID', type: 'text', value: '' },
    { prop: 'transaction_id', label: 'Transaction ID', type: 'text', value: '' },
    { prop: 'tracking_number', label: 'Tracking Number', type: 'text', value: '' },
    { prop: 'tracking_sender', label: 'Tracking Sender', type: 'text', value: '' },
    { prop: 'description', label: 'Description', type: 'text', value: '' }
  ]

  async setExternalId (): Promise<void> {
    const params: SetExternalIdParams = this.field
    const response = await SetExternalId.getInstance().request(params)
    const isSuccessResponse: boolean = response.code === 200
    const message = isSuccessResponse ? 'Successfully Completed' : 'Failed'
    const type = isSuccessResponse ? 'success' : 'error'
    this.$message({ message, type })
  }

  async onSubmit () {
    await this.setExternalId()
    this.$emit('close')
  }
}
