<template>
  <div class="default-wrapper">
    <el-row
      class="sub-head"
      type="flex"
    >
      <el-col
        class="sub-title"
      >
        <h2 v-html="title" />
      </el-col>
      <slot name="side" />
    </el-row>

    <slot />
  </div>
</template>

<script>
export default {
  name: 'DefaultLayout',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="scss">
.dome-transactional-info {
  min-width: 1366px;
  max-width: 1600px;
  
  .sub-title h2 {
    font-size: 36px !important;
    color: #1B1B1C;
  }
}
</style>