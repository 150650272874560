













import Vue from 'vue'
import FraudTooltip from '@/dome/components/FraudTooltip.vue'

export default Vue.extend({
  name: 'SubDashboardContent',
  components: { FraudTooltip },
  props: {
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    },
    hasTooltip: {
      type: Boolean,
      required: false,
      default: false
    },
    tooltipContent: {
      type: String,
      required: false,
      default: ''
    }
  }
})
