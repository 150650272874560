export interface ITextObjectTablePresentation {
  textObjectTitle: string
  textObjectContent: Dictionary<any>
  textObjectModal: boolean

  setContent (title: string, content: Dictionary<any>, modal: boolean): void
}

export class TextObjectTablePresentation implements ITextObjectTablePresentation {
  private static instance: TextObjectTablePresentation
  private constructor () {}

  public static getInstance (): TextObjectTablePresentation {
    if (!this.instance) this.instance = new TextObjectTablePresentation()
    return this.instance
  }

  public textObjectTitle: string = '';
  public textObjectContent: Dictionary<any> = {};
  public textObjectModal: boolean = false;

  public setContent (title: string, content: Dictionary<any>, modal: boolean): void {
    this.textObjectTitle = title
    this.textObjectContent = content
    this.textObjectModal = modal
  }
}
