export enum AffiliatedDocumentType {
  INTRODUCE_ENTREPRENEUR = 1,
  INTRODUCE_CORPERATION,
  USAGE_TERMS,
  USAGE_AGREEMENT = 10,
  TRADE_CONFIRMATION,
  PRIVACY_AGREEMENT,
  BUSINESS_REGISTRATION,
  OWNER_ID_CARD,
  INVOICE_CONTRACT,
  CORP_ACCOUNT = 20,
  CORP_CERTIFIED,
  CORP_SIGNATURE,
  CORP_SHAREHOLDERS,
  ENTRE_ACCOUNT = 30,
  ENTRE_SIGNATURE
}
