import { Remittance } from '@/types'
import { sentbizApi } from '../axios'
import { IApiRequest } from '../axios/IApiRequest'

export interface SearchAnalogueRemittanceParam {
  admin_menu_type: number
  page: number
  unit: number
  analogue_filter: number
}

export interface SearchAnalogueRemittanceResponse {
  total_count: number
  list: Array<Remittance>
}

export interface SearchAnalogueRemittance extends IApiRequest {
  getInstance (): SearchAnalogueRemittance
  request (params: SearchAnalogueRemittanceParam): Promise<SearchAnalogueRemittanceResponse>
}

export class SearchAnalogueRemittance implements SearchAnalogueRemittance {
  private static instance: SearchAnalogueRemittance
  private constructor () {}
  public static getInstance (): SearchAnalogueRemittance {
    if (!this.instance) this.instance = new SearchAnalogueRemittance()
    return this.instance
  }

  async request (params: SearchAnalogueRemittanceParam): Promise<SearchAnalogueRemittanceResponse> {
    const requestOption: RequestOption = {
      url: 'hades/remittance/search',
      method: 'post',
      data: params
    }
    const response = await sentbizApi.request(requestOption)
    return response?.data
  }
}
