

























import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { FraudTransactionActions, FraudTransactionGetters } from '@/dome/store/fraudTransactionState'
import SubDashboardWrapper from '@/dome/components/subDashboard/SubDashboardWrapper.vue'
import DomeAlertModal from '@/dome/container/DomeTransactionalInfo/DomeAlertModal.vue'
import dayjs from 'dayjs'
import { IFraudStaticsAlert } from '@/dome/types/FraudTypes'
import { FraudAlertLabel } from '@/dome/constants/FraudStatus'

export default Vue.extend({
  name: 'SubDashboardContainer',
  components: { SubDashboardWrapper, DomeAlertModal },
  data() {
    return {
      isDomeAlertModalOpen: false,
      domeAlertModalType: '',
      domeAlertModalState: FraudAlertLabel.NONE,
      domeAlertModalStateLabel: '',
      value1: '',
      value2: '',
      searchedPeriod: '',
      stateLangObject: {
        [FraudAlertLabel.NORMAL]: '정상',
        [FraudAlertLabel.WARNING]: '주의필요',
        [FraudAlertLabel.DANGER]: '위험',
        [FraudAlertLabel.WATCHING]: '비자감시 대상'
      },
      stateClassObject: {
        [FraudAlertLabel.NORMAL]: 'normal',
        [FraudAlertLabel.WARNING]: 'warning',
        [FraudAlertLabel.DANGER]: 'danger',
        [FraudAlertLabel.WATCHING]: 'visa'
      }
    }
  },
  computed: {
    ...mapGetters('fraudTransactionState', {
      statics: FraudTransactionGetters.FraudStatics,
      staticsAlert: FraudTransactionGetters.FraudStaticsAlert,
      selectedCorp: FraudTransactionGetters.CorpID
    }),
    staticLabels(): Dictionary<string> {
      const fraudStaticsResponse = this.statics
      const billingAmount = Number(fraudStaticsResponse.total_amount)
      const blockAmount = Number(fraudStaticsResponse.block_amount)
      const blockCount = Number(fraudStaticsResponse.block_count)
      const totalCount = Number(fraudStaticsResponse.total_count)
      const billingLabel = this.convertAbbrAmount(billingAmount)
      const blockLabel = this.convertAbbrAmount(blockAmount)
      const blockCountLabel = this.convertAbbrAmount(blockCount)
      const totalCountLabel = this.convertAbbrAmount(totalCount)
      return {
        billing: `$ ${billingLabel}`,
        fraud_block_billing: `$ ${blockLabel}`,
        fraud_block_and_trading_volume: `${blockCountLabel} / ${totalCountLabel}`,
        fraud_block_trade_rate: Number(fraudStaticsResponse.total_count)
          ? `${((Number(fraudStaticsResponse.block_count) / Number(fraudStaticsResponse.total_count)) * 100).toFixed(
              2
            )}%`
          : '0%'
      }
    },
    statusButtonLabel(): { fraud: FraudAlertLabel; dispute: FraudAlertLabel } {
      if (!this.selectedCorp) {
        return {
          fraud: FraudAlertLabel.NONE,
          dispute: FraudAlertLabel.NONE
        }
      }
      const statics: IFraudStaticsAlert = this.staticsAlert
      const fraudRatio = ((statics.chargeback_fraud_amount / statics.pass_amount) * 100) / 0.65
      const fraudAmount = statics.chargeback_fraud_amount / 50000
      const disputeRatio = (((statics.dispute_won_count + statics.dispute_lost_count) / statics.pass_count) * 100) / 0.9
      const disputeCount = (statics.dispute_won_count + statics.dispute_lost_count) / 100
      const disputeCountAlert: number = this.decideAlertLabel(disputeCount)
      const disputeAmountAlert: number = this.decideAlertLabel(disputeRatio)
      const fraudCountAlert: number = this.decideAlertLabel(fraudRatio)
      const fraudAmountAlert: number = this.decideAlertLabel(fraudAmount)
      const disputeAlert = Math.min(disputeCountAlert, disputeAmountAlert)
      const fraudAlert = Math.min(fraudCountAlert, fraudAmountAlert)
      return {
        dispute: disputeAlert,
        fraud: fraudAlert
      }
    }
  },
  async created() {
    const fromDate: Date = dayjs().startOf('month').startOf('day').toDate()
    const toDate: Date = dayjs().endOf('day').toDate()
    this.searchedPeriod = `${dayjs(fromDate).format('YYYY.MM.DD')} ~ ${dayjs(toDate).format('YYYY.MM.DD')}`
    await this.getStaticsAlert()
    await this.getStatics()
  },
  methods: {
    ...mapActions('fraudTransactionState', {
      getStatics: FraudTransactionActions.FetchFraudStatics,
      getStaticsAlert: FraudTransactionActions.FetchFraudStaticsAlert
    }),
    decideAlertLabel(targetAlert: number): FraudAlertLabel {
      return targetAlert < 0.5
        ? FraudAlertLabel.NORMAL
        : targetAlert < 0.7
        ? FraudAlertLabel.WARNING
        : targetAlert < 1
        ? FraudAlertLabel.DANGER
        : FraudAlertLabel.WATCHING
    },
    convertAbbrAmount(targetAmount: number): string {
      let digits = ['K', 'M', 'B']
      let digit = ''
      for (const digitIndex in digits) {
        if (targetAmount <= 999999999) break
        targetAmount = Math.floor(targetAmount / 1000)
        digit = digits[digitIndex]
      }
      return `${Math.round(targetAmount).toLocaleString()}${digit}`
    },
    makeFirstLetterCapital(target: string): string {
      return target.charAt(0).toUpperCase() + target.slice(1)
    },
    calcFraudModalInfo(): { value1: string; value2: string } {
      return {
        value1: ((this.staticsAlert.chargeback_fraud_amount / this.staticsAlert.pass_amount) * 100).toFixed(2),
        value2: (this.staticsAlert.chargeback_fraud_amount / 1000).toFixed(2)
      }
    },
    calcDisputeModalInfo(): { value1: string; value2: string } {
      return {
        value1: (
          ((this.staticsAlert.dispute_won_count + this.staticsAlert.dispute_lost_count) /
            this.staticsAlert.pass_count) *
          100
        ).toFixed(2),
        value2: `${this.staticsAlert.dispute_won_count + this.staticsAlert.dispute_lost_count}`
      }
    },
    onFraudAlertClick(): void {
      if (!this.selectedCorp) return
      this.domeAlertModalType = 'fraud'
      this.domeAlertModalState = this.statusButtonLabel.fraud
      const calculatedModalInfo = this.calcFraudModalInfo()
      this.value1 = calculatedModalInfo.value1
      this.value2 = calculatedModalInfo.value2
      this.isDomeAlertModalOpen = true
    },
    onDisputeAlertClick(): void {
      if (!this.selectedCorp) return
      this.domeAlertModalType = 'dispute'
      this.domeAlertModalState = this.statusButtonLabel.dispute
      const calculatedModalInfo = this.calcDisputeModalInfo()
      this.value1 = calculatedModalInfo.value1
      this.value2 = calculatedModalInfo.value2
      this.isDomeAlertModalOpen = true
    }
  }
})
