










































































import Vue from 'vue'
import Component from 'vue-class-component'
import DataTable from '@/components/shared/DataTable.vue'
import AffiliateCompanyList from '@/presentation/affiliated/AffiliateCompanyList'
import BasicDialog from '@/components/commons/BasicDialog.vue'
import CorpModal from '@/components/affiliate/CorpModal.vue'
import FormItems from '@/components/shared/FormItems.vue'
@Component({
  components: { FormItems, CorpModal, BasicDialog, DataTable }
})
export default class AffiliatedList extends Vue {
  isReady = false
  companies: AffiliateCompanyList = new AffiliateCompanyList(this.$confirm, this.$message)

  async reload () {
    await this.companies.initializeTableData()
  }

  async onChangeStatus (value: any) {
    this.isReady = false
    this.companies.statusFormField.status = value
    await this.reload()
    this.isReady = true
  }

  async onChangePage () {
    await this.reload()
  }

  async updateMemo () {
    await this.companies.updateMemo()
    await this.reload()
    this.companies.isCorpMemoModalOpen = false
  }

  async closeCorpModal () {
    this.companies.isCorpModalOpen = false
  }

  async created () {
    await this.reload()
    this.isReady = true
  }
}
