import RemittancePartner from '@/types/apiRequest/RemittancePartner'
import { sentbizApi } from '../axios'

export interface IUpdateRemittancePartner {
  request (params: RemittancePartner): Promise<any>
}

export class UpdateRemittancePartner implements IUpdateRemittancePartner {
  private static instance: UpdateRemittancePartner
  private constructor () {}

  public static getInstance (): UpdateRemittancePartner {
    if (!this.instance) this.instance = new UpdateRemittancePartner()
    return this.instance
  }

  async request (params: RemittancePartner): Promise<any> {
    const option: RequestOption = {
      url: 'hades/remittance/resend',
      method: 'post',
      data: params
    }
    const response: any = await sentbizApi.request(option)
    return response
  }
}
