



























import Vue, { PropType } from 'vue'
import FilterButton from '@/dome/components/filter/inputs/FilterButton.vue'
import { FraudAlertLabel } from '@/dome/constants/FraudStatus'
import { locales } from '@/dome/constants/locales'

export default Vue.extend({
  name: 'SubDashboardAlert',
  components: { FilterButton },
  props: {
    alertLabel: {
      type: Object as PropType<{ fraud: FraudAlertLabel; dispute: FraudAlertLabel }>,
      required: true
    }
  },
  computed: {
    fraudLabel (): string {
      return locales.fraudAlert[this.alertLabel.fraud]
    },
    disputeLabel (): string {
      return locales.fraudAlert[this.alertLabel.dispute]
    },
    fraudProperty (): string {
      return FraudAlertLabel[this.alertLabel.fraud].toLowerCase()
    },
    disputeProperty (): string {
      return FraudAlertLabel[this.alertLabel.dispute].toLowerCase()
    }
  }
})
