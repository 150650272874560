























































































import Vue from 'vue'
import Component from 'vue-class-component'
import DataTable from '@/components/shared/DataTable.vue'
import { CompletedAffiliateCompanyList } from '@/presentation/affiliated/CompletedAffiliateCompanyList'
import CorpModal from '@/components/affiliate/CorpModal.vue'
import BasicDialog from '@/components/commons/BasicDialog.vue'
import CorpWalletActivities from '@/components/affiliate/CorpWalletActivities.vue'
import AdjustWalletBalance from '@/components/affiliate/AdjustWalletBalance.vue'

@Component({
  components: { AdjustWalletBalance, CorpWalletActivities, BasicDialog, CorpModal, DataTable }
})
export default class CompletedAffiliatedList extends Vue {
  companies: CompletedAffiliateCompanyList = new CompletedAffiliateCompanyList(this.$confirm, this.$message)

  async reload () {
    await this.companies.initializeTableData()
  }

  async onChangePage () {
    await this.reload()
  }

  async updateMemo () {
    await this.companies.updateMemo()
    await this.reload()
    this.companies.isCorpMemoModalOpen = false
  }

  async closeCorpModal () {
    this.companies.isCorpModalOpen = false
  }

  async created () {
    this.companies.statusFormField.status = 20
    await this.reload()
  }
}
