type RemittanceStatusLabel = { [key: number]: string }
export const remittanceStatusLabel: RemittanceStatusLabel = {
  0: '상태가 없습니다.',
  1: '시스템 장애가 발생했습니다.',
  2: '시스템 장애가 발생했습니다.',
  3: '송금 제약 사항입니다.',
  4: '송금액이 올바르지 않습니다.',
  5: '피싱 제약 사항 입니다.',
  6: '중국 송금 제약 사항입니다.',
  7: '중국 송금 제약 사항입니다.',
  8: '중국 송금 제약 사항입니다.',
  10: '서버 에러',
  11: '출금 에러',
  20: '취소',
  21: '사용자에 의한 취소',
  22: '입금일 24시간 지나서 취소',
  23: '은행 실명인증 프로세스에서 실패',
  30: '환불된 송금건 입니다.',
  31: 'AML로 인해 환불된 송금건 입니다.',
  100: '첫 송금 신청',
  101: '송금 시작',
  110: '인증이 완료 되지 않았습니다.',
  111: '입금 대기 중',
  112: '자동 출금 대기 중',
  113: '자동 출금 완료',
  120: '월렛에서 출금',
  121: '월렛에 충전',
  130: '입금 진행 중',
  131: '입금 완료',
  135: 'AML 에러 입니다.',
  136: 'SANCTION',
  138: '시스템 큐 에러',
  140: '파트너 송금 시작',
  141: '닌자터틀의 경우 먼저 AML, 실명인증후 변경될 상태',
  142: '입금이 완료되었으나 송금 진행중에 오류가 발생함',
  143: '송금이 진행중입니다.',
  145: '은행에 신청하기 전인 아날로그 송금입니다.',
  146: '은행에 신청한 아날로그 송금입니다.',
  150: '관리자 확인 필요',
  151: '실명 확인 에러',
  160: '보내는 사람 AML 에러',
  161: '받는 사람 AML 에러',
  162: '시스템 AML 에러',
  163: '보내는 사람 AML SANCTION 에러',
  164: '받는 사람 AML SANCTION 에러',
  165: 'VT0301 에러',
  180: '시간 만료',
  200: '송금이 완료되었습니다.',
  250: '파트너에서 REJECTED',
  1000: '파트너의 알 수 없는 오류'
}
