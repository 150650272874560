


















































import Vue from 'vue'
import Component from 'vue-class-component'
import { GetRemittanceBase } from '@/adapters/remittanceBase/GetRemittanceBase'
import { IRemittanceBase } from '@/adapters/remittanceBase/model/IRemittanceBase'
import { KoRemittanceBase } from '@/data/KoRemittanceBase'
import { koEnum } from '@/lokalise/enumLocale/ko'
import { RemittanceBase } from '@/types/RemittanceBase'
import { UpdateRemittanceBase, UpdateRemittanceBaseParam } from '@/adapters/remittanceBase/UpdateRemittanceBase'

@Component
export default class AnalogMoneyLimit extends Vue {
  responseData: Array<IRemittanceBase> = []
  koTypeData: Array<KoRemittanceBase> = []
  ruleData: { [key: number]: RemittanceBase } = {}

  moneyLimitRule: Array<Object> = [
    {
      required: true,
      message: '금액을 입력해주세요.'
    },
    {
      type: 'number',
      message: '숫자만 입력해주세요.'
    }
  ]

  /**
   * 저장 버튼을 눌렀을 때, 폼의 유효화 검사를 시행하고 update remittance API를 호출한다.
   * 
   * 과정에서 에러가 발생할 경우, 에러 메시지를 표출한다.
   */
  async onSubmit () {
    try {
      await (this.$refs['limit-form'] as Vue & {
        validate: () => boolean;
      }).validate()
      this.updateRemittanceBase(Object.values(this.ruleData))
      this.$message({ message: '저장되었습니다.', type: 'success' })
    } catch (error) {
      this.$message({ message: '모두 입력해주세요.', type: 'error' })
      return error
    }
  }

  /**
   * koTypeData 값을 토대로 각 data의 remittanceBase를 ruleData에 할당한다.
   */
  setRuleData () {
    this.koTypeData.forEach((data, index: number) => {
      this.ruleData[index] = data.remittanceBase
    })
  }

  /**
   * RemittanceBase 배열을 파라미터로 받아 UpdateRemittanceBase API를 호출한다.
   * 
   * @param {Array<RemittanceBase>} currencyDataField API의 파라미터로 사용할 RemittanceBase 배열
   */
  async updateRemittanceBase (currencyDataField: Array<RemittanceBase>) {
    const data: UpdateRemittanceBaseParam = {
      list: currencyDataField
    }

    await UpdateRemittanceBase.getInstance().request(data)
  }

  /**
   * API로부터 remittanceBase를 받아 koTypeData에 변환, 할당한다.
   */
  async getRemittanceBase () {
    const response = await GetRemittanceBase.getInstance().request()
    this.responseData = response
    this.responseData.forEach((data) => {
      this.koTypeData.push({
        remittanceBase: data,
        koCountry: koEnum[data.country]
      })
    })
    this.koTypeData.sort((former, latter) => {
      const formerName = former.koCountry
      const latterName = latter.koCountry
      return formerName < latterName ? -1 : formerName > latterName ? 1 : 0
    })
    this.setRuleData()
  }

  /**
   * AnalogMoneyLimit의 created lifecycle에 getRemittanceBase 함수를 호출한다.
   */
  created () {
    this.getRemittanceBase()
  }
}
