export enum WalletActivityEnum {
  WALLET_NONE = 0,
  WITHDRAW_BY_REMITTANCE = 1,
  WITHDRAW_BY_WALLET_REFUND = 2,
  DEPOSIT_BY_VIRTUAL_ACCOUNT = 30,
  DEPOSIT_BY_AUTO_DEBIT = 31,
  DEPOSIT_BY_ADMIN = 33,
  DEPOSIT_BY_PROMOTION = 34,
  DEPOSIT_BY_SYSTEM = 35,
  // 이하, 글로벌 월렛 관련
  DEPOSIT_BY_PAYMENT = 36,
  CREATED_BY_PAYMENT = 40,
  CANCELED_BY_PAYMENT = 50,
  EXPIRED_BY_PAYMENT = 60
}
