




























import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'FilterRadioGroup',
  props: {
    labels: {
      type: Array as PropType<Array<string>>,
      required: true
    },
    initialValue: {
      type: String,
      required: false,
      default: ''
    },
    isButton: {
      type: Boolean,
      required: false,
      default: false
    },
    isPairColumn: {
      type: Boolean,
      required: false,
      default: false
    },
    isSmallPair: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      value: this.initialValue || this.labels[0]
    }
  }
})
