enum StatusType {
  UNKNOWN_TRANSFER_STATE_LABEL,
  WAITING_DEPOSIT,
  INPROGRESS,
  WAITING_TRANSFER,
  RECEIVABLE,
  REFUNDED,
  CANCELED,
  EXPIRED,
  WAITING_RECEIVE,
  FAILED_TRANSFER,
  DONE
}

export default StatusType
