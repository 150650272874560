import { IApiRequest } from '@/adapters/axios/IApiRequest'
import { CorpRegister } from '@/presentation/affiliated/CorpRegister'
import { sentbizApi } from '@/adapters/axios'

export interface RequestRegister extends IApiRequest {
  getInstance (): RequestRegister
  request (params: CorpRegister): Promise<any>
}
export class RequestRegister implements RequestRegister {
  private static instance: RequestRegister
  private constructor () {}
  public static getInstance (): RequestRegister {
    if (!this.instance) this.instance = new RequestRegister()
    return this.instance
  }

  async request (params: CorpRegister): Promise<any> {
    const requestOption: RequestOption = {
      url: 'hades/corp',
      method: 'post',
      data: params
    }
    const response = await sentbizApi.request(requestOption)
    return response
  }
}
