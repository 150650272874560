import { sentbizApi } from '@/adapters/axios'
import { ApproveCorpParams } from '@/adapters/affiliate/model/ApproveCorpParams'

export class CorpActions {
  private static instance: CorpActions
  private constructor () {}
  public static getInstance (): CorpActions {
    if (!this.instance) this.instance = new CorpActions()
    return this.instance
  }

  async approve (params:ApproveCorpParams): Promise<any> {
    const requestOption: RequestOption = {
      url: 'hades/corp/approval',
      method: 'post',
      data: params
    }
    const response = await sentbizApi.request(requestOption)
    return response
  }

  async deactivate (corpId: string): Promise<any> {
    const requestOption: RequestOption = {
      url: 'hades/corp/deactivate',
      method: 'post',
      data: { corp_id: corpId }
    }
    const response = await sentbizApi.request(requestOption)
    return response
  }
}
