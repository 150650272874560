export interface CalculatorNumber {
  setRoundPosition (roundPosition: number): void
  mul (operand: number): CalculatorNumber
  div (operand: number): CalculatorNumber
  plus (operand: number): CalculatorNumber
  minus (operand: number): CalculatorNumber
  toNumber (): number
}

export class CalculatorNumber implements CalculatorNumber {
  private readonly operand: number
  private roundPosition: number = Math.pow(10, 6)

  constructor (operand: number) {
    this.operand = operand
  }

  public setRoundPosition (roundPosition: number) {
    this.roundPosition = Math.pow(10, roundPosition)
  }

  public mul (operand: number): CalculatorNumber {
    const result = this.operand * operand
    return new CalculatorNumber(Math.round((result) * this.roundPosition) / this.roundPosition)
  }

  public div (operand: number): CalculatorNumber {
    const result = this.operand / operand
    return new CalculatorNumber(Math.round((result) * this.roundPosition) / this.roundPosition)
  }

  public plus (operand: number): CalculatorNumber {
    const result = this.operand + operand
    return new CalculatorNumber(Math.round((result) * this.roundPosition) / this.roundPosition)
  }

  public minus (operand: number): CalculatorNumber {
    const result = this.operand - operand
    return new CalculatorNumber(Math.round((result) * this.roundPosition) / this.roundPosition)
  }

  public toNumber (): number {
    return this.operand
  }
}
