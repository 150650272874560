import { IWalletUseCase, WalletUseCase } from '@/usecase/wallet/WalletUseCase'
import { GetWalletActivitiesParam } from '@/adapters/wallet/model/GetWalletActivitiesParam'
import { WalletActivityEnum } from '@/enums/WalletActivityEnum'
import { ActivityTableData } from '@/types/wallet/ActivityTableData'
import DataTableOption from '@/types/DataTableOption'
import { SheetHandler } from '@/lib/SheetHandler'
import { Corp } from '@/adapters/affiliate/Corp'
import dayjs from 'dayjs'
import { IWalletActivitiesPresentation } from './model/IWalletActivitiesPresentation'

export class WalletActivitiesPresentation implements IWalletActivitiesPresentation {
  sheetUseCase!: SheetHandler
  useCase: IWalletUseCase = new WalletUseCase()
  corpId: string = ''
  page = 1
  unit = 100
  walletActivityType: Array<WalletActivityEnum> = []

  walletActivities: Array<ActivityTableData> = []
  walletActivitiesTableOptions: Dictionary<DataTableOption> = {
    transaction_time: { type: 'text', label: 'Time' },
    transaction: { type: 'text', label: 'Transaction', className: 'transaction-type' },
    transaction_amount: { type: 'text', label: 'Amount', className: 'transaction-amount' },
    balance: { type: 'text', label: 'Balance' },
    memo: { type: 'text', label: 'Memo (Internal)' }
  }

  excelWalletActivities: Array<ActivityTableData> = []

  private get walletActivitiesParam (): GetWalletActivitiesParam {
    return {
      corp_id: Number(this.corpId),
      page: this.page,
      transaction: this.walletActivityType,
      unit: this.unit
    }
  }

  public setCorpId (corpId: string) {
    this.corpId = corpId
  }

  public setWalletActivityType (walletActivityTypes: Array<WalletActivityEnum>) {
    if (walletActivityTypes) this.walletActivityType = walletActivityTypes
  }

  private getTransactionCategoryByEnum (transaction: WalletActivityEnum): string {
    switch (transaction) {
      case WalletActivityEnum.WITHDRAW_BY_REMITTANCE:
        return 'Remittance'
      case WalletActivityEnum.WITHDRAW_BY_WALLET_REFUND:
        return 'Withdrawal'
      case WalletActivityEnum.DEPOSIT_BY_ADMIN:
      case WalletActivityEnum.DEPOSIT_BY_AUTO_DEBIT:
      case WalletActivityEnum.DEPOSIT_BY_PAYMENT:
      case WalletActivityEnum.DEPOSIT_BY_PROMOTION:
      case WalletActivityEnum.DEPOSIT_BY_SYSTEM:
      case WalletActivityEnum.DEPOSIT_BY_VIRTUAL_ACCOUNT:
        return 'Deposit'
      default:
        return ''
    }
  }

  private async getWalletActivities (param: GetWalletActivitiesParam) {
    const walletActivities = await this.useCase.getWalletActivities(param)
    return walletActivities.map(walletActivity => {
      const walletActivityType = WalletActivityEnum[walletActivity.transaction] as keyof typeof WalletActivityEnum
      const walletActivityGroupType = this.getTransactionCategoryByEnum(walletActivity.transaction)
      const isPositive = walletActivityGroupType === 'Deposit'
      return {
        transaction_time: walletActivity.local_transaction_time || '',
        transaction: `${walletActivityGroupType}\n(${walletActivityType})`,
        transaction_amount: {
          class: isPositive ? 'positive' : 'negative',
          label: `${isPositive ? '+' : '-'} ${walletActivity.transaction_amount.toLocaleString()}` || '0'
        },
        balance: walletActivity.wallet_balance.toLocaleString() || '0',
        memo: walletActivity.memo
      }
    })
  }

  public async setWalletActivities (): Promise<void> {
    this.walletActivities = await this.getWalletActivities(this.walletActivitiesParam)
  }

  public async downloadExcel (corp: Corp) {
    this.sheetUseCase = SheetHandler.getInstance()
    const activitiesParam: GetWalletActivitiesParam = this.walletActivitiesParam
    const maxSearchNumber = 10000000
    activitiesParam.unit =  maxSearchNumber
    const excelWalletActivities: Array<ActivityTableData> = await this.getWalletActivities(activitiesParam)
    const walletActivitiesForSheet: Array<Dictionary<string>> = excelWalletActivities.map(activity => {
      return {
        transaction_time: activity.transaction_time,
        transaction: activity.transaction,
        transaction_amount: activity.transaction_amount.label,
        balance: activity.balance,
        memo: activity.memo
      }
    })
    this.sheetUseCase.sheetJson = { walletActivities: walletActivitiesForSheet }
    const wallet = await this.useCase.getWallet({ corp_id: Number(corp.corp_id) })
    const sheetName = `${corp.name}_${wallet[0].currency}_${dayjs().format('YYYYMMDD')}`
    this.sheetUseCase.saveSheetByJson(sheetName)
  }
}
