export enum FraudStatus {
  ALL = 0,
  FRAUD_PASS = 200,
  FRAUD_BLOCK = 210,
  DISPUTE_WON = 300,
  DISPUTE_LOST = 310,
  CHARGEBACK_ACCEPT = 400,
  CHARGEBACK_FRAUD = 410
}

export enum FraudTimelineActionStatus {
  ACTION_FRAUD_PASS = 10,
  ACTION_FRAUD_BLOCK = 20,
  ACTION_DISPUTE_WON = 30,
  ACTION_DISPUTE_LOST = 40,
  ACTION_CHARGEBACK_ACCEPT = 50,
  ACTION_CHARGEBACK_FRAUD = 60,
  ACTION_MEMO_CREATE = 100,
  ACTION_MEMO_UPDATE = 110
}

export enum PaymentType {
  ELECTRONIC_FUND_TRANSFER = 1,
  CASH,
  CHECK,
  CREDIT_CARD,
  CRYPTO_CURRENCY,
  DIGITAL_WALLET,
  IN_APP_PURCHASE,
  POINTS,
  VOUCHER,
  GIFT_CARD
}

// TODO locale 로 이관
export const PaymentTypeLabel = {
  [PaymentType.ELECTRONIC_FUND_TRANSFER]: '이체',
  [PaymentType.CASH]: '현금',
  [PaymentType.CHECK]: '수표',
  [PaymentType.CREDIT_CARD]: '신용카드',
  [PaymentType.CRYPTO_CURRENCY]: '가상화폐',
  [PaymentType.DIGITAL_WALLET]: '디지털 월렛',
  [PaymentType.IN_APP_PURCHASE]: '인앱결제',
  [PaymentType.POINTS]: '포인트결제',
  [PaymentType.VOUCHER]: '바우처결제',
  [PaymentType.GIFT_CARD]: '기프트카드'
}

export enum FraudAlertLabel {
  NONE = 0,
  NORMAL = 1,
  WARNING,
  DANGER,
  WATCHING
}
