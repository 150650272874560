import Vue from 'vue'
import VueRouter from 'vue-router'
import { RouteConfig } from 'vue-router/types/router'
import Routes from '@/router/Routes'

if (!process || process.env.NODE_ENV !== 'test') {
  Vue.use(VueRouter)
}

const allowPaths = ['/signIn']
const routes: Array<RouteConfig> = Routes

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  const isPreventNext = !allowPaths.includes(to.path) && (!localStorage.getItem('email') || !localStorage.getItem('auth'))
  if (isPreventNext) return next('/signIn')
  next()
})

export default router
