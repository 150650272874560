








import Vue from 'vue'
import FilterCheckBoxGroup from '@/dome/components/filter/inputs/FilterCheckBoxGroup.vue'

export default Vue.extend({
  name: 'FilterCurrencyContainer',
  components: { FilterCheckBoxGroup },
  data () {
    return {
      labels: ['USD', 'KRW']
    }
  },
  methods: {
    onChange (values: Array<string>) {
      this.$emit('onChange', values)
    }
  }
})
