import { FraudAlertLabel, FraudStatus, PaymentType } from '@/dome/constants/FraudStatus'
import { Dictionary } from 'vue-router/types/router'

export const locales: Dictionary<Dictionary<string>> = {
  payment_type: {
    [PaymentType.ELECTRONIC_FUND_TRANSFER]: '이체',
    [PaymentType.CASH]: '현금',
    [PaymentType.CHECK]: '수표',
    [PaymentType.CREDIT_CARD]: '신용카드',
    [PaymentType.CRYPTO_CURRENCY]: '가상화폐',
    [PaymentType.DIGITAL_WALLET]: '디지털 월렛',
    [PaymentType.IN_APP_PURCHASE]: '인앱결제',
    [PaymentType.POINTS]: '포인트결제',
    [PaymentType.VOUCHER]: '바우처결제',
    [PaymentType.GIFT_CARD]: '기프트카드'
  },
  systemStatus: {
    [FraudStatus.FRAUD_PASS]: 'PASS',
    [FraudStatus.FRAUD_BLOCK]: 'BLOCK',
    [FraudStatus.DISPUTE_WON]: 'PASS',
    [FraudStatus.DISPUTE_LOST]: 'BLOCK',
    [FraudStatus.CHARGEBACK_ACCEPT]: 'PASS',
    [FraudStatus.CHARGEBACK_FRAUD]: 'BLOCK'
  },
  fraudStatus: {
    [FraudStatus.FRAUD_PASS]: 'FRAUD PASS',
    [FraudStatus.FRAUD_BLOCK]: 'FRAUD BLOCK',
    [FraudStatus.DISPUTE_WON]: 'DISPUTE WON',
    [FraudStatus.DISPUTE_LOST]: 'DISPUTE LOST',
    [FraudStatus.CHARGEBACK_ACCEPT]: 'CHARGEBACK ACCEPT',
    [FraudStatus.CHARGEBACK_FRAUD]: 'CHARGEBACK FRAUD'
  },
  transactionColumns: {
    tid: 'TID',
    system_status: 'System 탐지 결과',
    fraud_score: '위험점수',
    fraud_status: '거래 상태',
    amount: '거래 금액',
    currency: '거래 통화',
    order_number: '주문번호',
    corp_name: '가맹점명',
    seller_id: 'Seller ID',
    merchandise_name: '상품명',
    customer_name: '고객명',
    customer_email: '이메일',
    card_number: '카드번호',
    created_at: '거래일',
    payment_type: '결제타입',
    fraud_code: 'Fraud ID',
    reasons: 'Fraud 상세'
  },
  fraudAlert: {
    [FraudAlertLabel.NONE]: '가맹점 미선택',
    [FraudAlertLabel.NORMAL]: '정상',
    [FraudAlertLabel.WARNING]: '주의필요',
    [FraudAlertLabel.DANGER]: '위험',
    [FraudAlertLabel.WATCHING]: '비자감시 대상'
  }
}
