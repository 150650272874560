














import Vue, { PropType } from 'vue'
import AlertGraphSection from '@/dome/components/DomeTransactionalInfo/AlertGraphSection.vue'
import ConditionDescriptionSection from '@/dome/components/DomeTransactionalInfo/ConditionDescriptionSection.vue'
import { IDomeAlertModalProps } from '@/dome/types/IDomeAlertModal'
import {
  fraudDescription,
  disputeDescription,
  fraudConditionDescription,
  disputeConditionDescription
} from '@/dome/constants/AlertModal'

export default Vue.extend({
  components: { AlertGraphSection, ConditionDescriptionSection },
  props: {
    type: {
      type: String as PropType<'fraud' | 'dispute'>,
      required: true
    },
    value1: {
      type: String,
      required: true
    },
    value2: {
      type: String,
      required: true
    },
    searchedPeriod: {
      type: String,
      required: true
    }
  },
  data(): {
    descriptionObject: Dictionary<string>
    conditionDescriptionObject: Dictionary<string>
    modalPropsObject: Dictionary<Array<IDomeAlertModalProps>>
    modalProps: Array<IDomeAlertModalProps>
    colorObject: Dictionary<string>
  } {
    return {
      descriptionObject: {
        fraud: fraudDescription,
        dispute: disputeDescription
      },
      conditionDescriptionObject: {
        fraud: fraudConditionDescription,
        dispute: disputeConditionDescription
      },
      modalPropsObject: {
        fraud: [
          {
            title: '부정 거래 비율',
            value: '',
            graphMaxValue: '0.65',
            graphUnit: '%',
            expressiveUnit: '$액수',
            width: '',
            color: ''
          },
          {
            title: '부정 거래',
            value: '',
            graphMaxValue: '50.00',
            graphUnit: 'K',
            expressiveUnit: '$액수',
            width: '',
            color: ''
          }
        ],
        dispute: [
          {
            title: '분쟁 신청 비율',
            value: '',
            graphMaxValue: '0.90',
            graphUnit: '%',
            expressiveUnit: '건수',
            width: '',
            color: ''
          },
          {
            title: '분쟁 신청',
            value: '',
            graphMaxValue: '100',
            graphUnit: '건',
            expressiveUnit: '건수',
            width: '',
            color: ''
          }
        ]
      },
      modalProps: [],
      colorObject: {
        fraud: '#FFA336',
        dispute: '#877CFF'
      }
    }
  },
  created() {
    this.modalProps = this.modalPropsObject[this.type]

    this.modalProps[0].value = this.value1
    this.modalProps[1].value = this.value2

    this.modalProps.forEach(prop => {
      prop.width = this.calculateWidth(prop.value, prop.graphMaxValue)
      prop.color = Number(prop.width) >= 458 ? '#FF5B5B' : this.colorObject[this.type]
    })
  },
  methods: {
    calculateWidth(value: string, max: string): string {
      const numValue = Number(value)
      const numMax = Number(max)

      const width = (numValue / numMax) * 458

      return width > 458 ? '458' : `${width}`
    }
  }
})
