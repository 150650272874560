


























import Vue from 'vue'
import DefaultHeader from '@/components/commons/DefaultHeader.vue'
import NaviMenu from '@/components/commons/NaviMenu.vue'
import { SignOut } from '@/adapters/SignOut'
import router from './router'
import { initializeStaticValues } from '@/lib/StaticValues'

const ONE_MINUTE: number = 1000 * 60

export default Vue.extend({
  name: 'App',
  components: {
    DefaultHeader,
    NaviMenu
  },
  data() {
    return {
      isOpen: true,
      isInactive: false,
      userActivityThrottlerTimeout: 0,
      userActivityMessageTimeout: 0,
      userActivityTimeout: 0,
      INACTIVE_USER_TIME_THRESHOLD: ONE_MINUTE * 15,
      USER_ACTIVITY_THROTTLER_TIME: 1000
    }
  },
  computed: {
    isNav(): boolean {
      const unUsedNav = ['/signIn']
      const routePath = this.$route.path
      return !unUsedNav.includes(routePath)
    }
  },
  created(): void {
    this.setSideMenu()
  },
  beforeMount(): void {
    this.activateActivityTracker()
  },
  async beforeCreate() {
    const hasAuthInfo = !!localStorage.getItem('email') && !!localStorage.getItem('auth')
    if (!hasAuthInfo) return
    await initializeStaticValues()
  },
  beforeDestroy(): void {
    window.removeEventListener('mousemove', this.userActivityThrottler)
    window.removeEventListener('scroll', this.userActivityThrottler)
    window.removeEventListener('keydown', this.userActivityThrottler)
    window.removeEventListener('resize', this.userActivityThrottler)

    clearTimeout(this.userActivityMessageTimeout)
    clearTimeout(this.userActivityThrottlerTimeout)
  },
  methods: {
    setSideMenu() {
      this.isOpen = !this.isOpen
    },
    resetUserActivityTimeout(): void {
      clearTimeout(this.userActivityMessageTimeout)
      clearTimeout(this.userActivityTimeout)
      this.userActivityMessageTimeout = setTimeout(() => {
        this.$message({
          message: '15분 동안 서비스를 이용하지 않으신 경우 계정의 보안을 위하여 로그아웃 됩니다.',
          type: 'warning'
        })
        this.userActivityTimeout = setTimeout(async () => {
          const isDevEnv = process.env.NODE_ENV === 'development'
          if (isDevEnv) return
          const response: any = await new SignOut().request()
          if (response.code !== 200) {
            this.$message({
              message: '오류가 발생했습니다',
              type: 'error'
            })
            return
          }
          this.$alert('계정의 보안을 위하여 로그아웃 됩니다.', '세션 만료', {
            confirmButtonText: '확인'
          })
          await router.push('/signIn')
        }, ONE_MINUTE)
      }, this.INACTIVE_USER_TIME_THRESHOLD - ONE_MINUTE)
    },
    userActivityThrottler(): void {
      if (!this.userActivityThrottlerTimeout && this.isNav) {
        this.userActivityThrottlerTimeout = setTimeout(() => {
          this.resetUserActivityTimeout()

          clearTimeout(this.userActivityThrottlerTimeout)
          this.userActivityThrottlerTimeout = 0
        }, this.USER_ACTIVITY_THROTTLER_TIME)
      }
    },
    activateActivityTracker(): void {
      window.addEventListener('mousemove', this.userActivityThrottler)
      window.addEventListener('scroll', this.userActivityThrottler)
      window.addEventListener('keydown', this.userActivityThrottler)
      window.addEventListener('resize', this.userActivityThrottler)
    }
  }
})
