import { ExportingParam } from '@/types/apiRequest/ExportingParam'
import { ReportingField } from '@/types/ReportingField'
import { sentbizApi } from './axios'

export interface GetAccountingResponse {
  code: number;
  message: string;
  data?: {
    list: Array<ReportingField>
  }
}

export class GetAccounting {
  private static instance: GetAccounting
  private constructor () {}

  public static getInstance (): GetAccounting {
    if (!this.instance) this.instance = new GetAccounting()
    return this.instance
  }

  public async request (params: ExportingParam): Promise<GetAccountingResponse> {
    const option: RequestOption = {
      url: 'hades/accounting',
      method: 'get',
      params
    }
    const response: GetAccountingResponse = await sentbizApi.request(option)
    return response
  }
}
