import { sentbizApi } from './axios'
import { IApiRequest } from './axios/IApiRequest'

export interface CreateAdminParams {
  admin_name: string
  admin_id: string
  password: string
  nationality: string
  phone_number: number
  phone_code: number
  authority: Array<string>
}

export interface CreateAdmin extends IApiRequest {
  getInstance (): CreateAdmin
  request (params: CreateAdminParams): Promise<any>
}

export class CreateAdmin implements CreateAdmin {
  private static instance: CreateAdmin
  private constructor () {}
  public static getInstance (): CreateAdmin {
    if (!this.instance) this.instance = new CreateAdmin()
    return this.instance
  }

  public async request (params: CreateAdminParams): Promise<any> {
    const requestOption: RequestOption = {
      url: 'hades/admin',
      method: 'post',
      data: params
    }
    const response = await sentbizApi.request(requestOption)
    return response
  }
}
