import { IApiRequest } from '@/adapters/axios/IApiRequest'
import { sentbizApi } from '@/adapters/axios'
import { SignUpDocuments } from '@/adapters/affiliate/SignUpDocuments'

export interface SetSignUpDocuments extends IApiRequest {
  getInstance (): SetSignUpDocuments;
  request (params: SignUpDocuments): Promise<any>;
}

export class SetSignUpDocuments implements SetSignUpDocuments {
  private static instance: SetSignUpDocuments
  private constructor () {}
  public static getInstance (): SetSignUpDocuments {
    if (!this.instance) this.instance = new SetSignUpDocuments()
    return this.instance
  }

  async request (params: SignUpDocuments): Promise<any> {
    const requestOption: RequestOption = {
      url: 'corp/documents',
      method: 'put',
      data: params
    }
    const response: any = await sentbizApi.request(requestOption)
    return response
  }
}
