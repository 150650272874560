import { GetWallet } from '@/adapters/wallet/GetWallet'
import { GetWalletActivities } from '@/adapters/wallet/GetWalletActivities'
import { GetWalletActivitiesParam } from '@/adapters/wallet/model/GetWalletActivitiesParam'
import { GetWalletParam } from '@/adapters/wallet/model/GetWalletParam'
import dayjs from 'dayjs'
import { Wallet } from '@/types/wallet/Wallet'
import { WalletActivity } from '@/types/wallet/WalletActivity'
import { RefundFromWallet } from '@/adapters/wallet/RefundFromWallet'
import { RefundWalletParam } from '@/adapters/wallet/model/RefundWalletParam'
import { IAdjustWallet } from '@/presentation/wallet/model/IAdjustWallet'

export interface IWalletUseCase {
  getWallet (params: GetWalletParam): Promise<Array<Wallet>>;

  getWalletActivities (params: GetWalletActivitiesParam): Promise<Array<WalletActivity>>;
  refundFromWallet (walletId: number, adjustWallet: IAdjustWallet): Promise<boolean>;
}

export class WalletUseCase implements IWalletUseCase {
  private walletInfo!: Array<Wallet>

  public async getWallet (params: GetWalletParam): Promise<Array<Wallet>> {
    this.walletInfo = await GetWallet.getInstance().request(params)
    return this.walletInfo
  }

  public async getWalletActivities (params: GetWalletActivitiesParam): Promise<Array<WalletActivity>> {
    let response = await GetWalletActivities.getInstance().request(params)
    response.forEach(activity => {
      activity.local_transaction_time = dayjs(new Date(activity.transaction_time)).format('YYYY-MM-DD HH:mm:ss')
    })
    return response
  }

  public async refundFromWallet (walletId: number, adjustWallet: IAdjustWallet) {
    const emailSuffix = localStorage.getItem('email') ? ` (${localStorage.getItem('email')})` : ''
    const param: RefundWalletParam = {
      id: Number(walletId),
      balance: Number(adjustWallet.balance),
      memo: `${adjustWallet.memo}${emailSuffix}`
    }
    const response = await RefundFromWallet.getInstance().request(param)
    return response.code === 200
  }
}
