









import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'FraudTooltip',
  props: {
    size: {
      type: String as PropType<'small' | 'medium'>,
      required: false,
      default: 'small'
    },
    tooltipContent: {
      type: String,
      required: true
    },
    filterColor: {
      type: String,
      required: false,
      default: ''
    }
  }
})
