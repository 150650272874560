















































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import FormItems from '@/components/shared/FormItems.vue'
import FormItemsOption from '@/types/FormItemsOption'
import { regexToPatternString } from '@/data/Regex'
import { countries } from '@/data/Countries'
import RemittanceStatusEnum from '@/enums/RemittanceStatus'
import { getEnumOptions, getEnumValueArray } from '@/lib/Utils'
import { AnalogueFilterTypes } from '@/enums/AnalogueFilterTypes'

@Component({
  components: { FormItems }
})
export default class SearchModal extends Vue {
  @Prop(Object) searchParam!: Dictionary<any>
  field: Dictionary<any> = {}
  searchOptions: Dictionary<Array<FormItemsOption>> = {
    transfer: [
      { prop: 'remittance_id', label: 'Transfer ID', type: 'text', value: undefined },
      {
        prop: 'remittance_status',
        label: 'Transfer Status ID',
        type: 'select',
        value: undefined,
        options: getEnumOptions(RemittanceStatusEnum, getEnumValueArray(RemittanceStatusEnum)),
        clearable: true
      }
    ],
    recipient: [
      { prop: 'recipient_id', label: 'Recipient No.', type: 'text', value: undefined },
      { prop: 'recipient_name', label: 'Recipient Name', type: 'text', value: undefined },
      {
        prop: 'recipient_phone_number',
        label: 'Recipient Phone Number',
        placeHolder: 'e.g. 1012345678',
        type: 'text',
        value: undefined
      },
      {
        prop: 'recipient_nationality',
        label: 'Recipient Nationality',
        type: 'select',
        filterable: true,
        options: countries.map(country => {
          return { label: `${country.name} (${country.iso})`, value: country.iso }
        }),
        value: undefined
      }
    ],
    corporation: [
      { prop: 'corp_id', label: 'Corp ID', type: 'text', value: undefined },
      { prop: 'corp_name', label: 'Corp Name', type: 'text', value: undefined }
    ],
    transfer_group: [
      { prop: 'remittance_group_id', label: 'Remittance Group ID', type: 'text', value: undefined },
      { prop: 'fx_task_id', label: 'FX ID', type: 'text', value: undefined }
    ],
    external_partner: [
      { prop: 'external_id', label: 'External ID', type: 'text', value: undefined },
      { prop: 'external_partner', label: 'External Partner', type: 'text', value: undefined },
      { prop: 'external_status', label: 'External Status', type: 'text', value: undefined }
    ],
    date: [
      { prop: 'remittance_created_at', label: 'Created At', type: 'dateRange', class: 'date-range', value: undefined },
      { prop: 'remittance_updated_at', label: 'Updated At', type: 'dateRange', class: 'date-range', value: undefined }
    ],
    analogue: [
      {
        prop: 'analogue_filter',
        type: 'radio',
        formItemClass: 'radio-form-item',
        class: 'analogue-filter-radio',
        options: [
          { label: '모두 보기', value: `${AnalogueFilterTypes.ALL}` },
          { label: '아날로그 송금만 보기', value: `${AnalogueFilterTypes.ANALOGUE}` },
          { label: '파트너사 송금만 보기', value: `${AnalogueFilterTypes.PARTNERS}` }
        ],
        value: []
      }
    ]
  }

  optionalNumberRule = { required: false, pattern: regexToPatternString('number'), message: 'numbers only' }
  recipientRules: Dictionary<Array<FormRule>> = {
    remittance_id: [this.optionalNumberRule],
    remittance_group_id: [this.optionalNumberRule],
    remittance_status: [this.optionalNumberRule],
    recipient_phone_number: [this.optionalNumberRule],
    corp_id: [this.optionalNumberRule],
    fx_task_id: [this.optionalNumberRule]
  }

  onClickCancel () {
    this.$emit('cancel')
  }

  async formValidate () {
    try {
      return await (this.$refs.searchForm as Vue & { validate: () => boolean | any }).validate()
    } catch (error) {
      return error
    }
  }

  async onSubmit () {
    const isValidate: boolean = await this.formValidate()
    if (!isValidate) return
    this.$emit('submit', this.field)
  }

  created () {
    if (this.$route.params.processing === 'analogue') delete this.searchOptions.analogue
    Object.keys(this.searchOptions).forEach(propGroup => {
      const props: Array<FormItemsOption> = this.searchOptions[propGroup]
      props.forEach(formOption => {
        this.field[formOption.prop] = this.searchParam[formOption.prop] || null
      })
    })
    this.field = Object.assign({}, this.field)
  }
}
