import { ResetUserPassword, ResetUserPasswordParams } from '@/adapters/corp/ResetUserPassword'

export interface IResetPasswordUseCase {
  resetUserPassword (params: ResetUserPasswordParams): Promise<boolean>
}

export class ResetPasswordUseCase implements IResetPasswordUseCase {
  public async resetUserPassword (params: ResetUserPasswordParams): Promise<boolean> {
    const response = await ResetUserPassword.getInstance().request(params)
    return response.code === 200
  }
}
