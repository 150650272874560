export const koEnum: Dictionary<string> = {
  // Country
  AD: '안도라',
  AE: '아랍 에미리트',
  AM: '아르메니아',
  AO: '앙골라',
  AR: '아르헨티나',
  AT: '오스트리아',
  AU: '호주',
  AW: '아루바',
  AX: '올란드 제도',
  AZ: '아제르바이잔',
  BB: '바베이도스',
  BD: '방글라데시',
  BE: '벨기에',
  BF: '부르키나파소',
  BG: '불가리아',
  BH: '바레인',
  BJ: '베냉',
  BL: '생 바르텔레미',
  BN: '브루나이 다루살람',
  BO: '볼리비아',
  BR: '브라질',
  BS: '바하마',
  BT: '부탄',
  BW: '보츠와나',
  BZ: '벨리즈',
  CA: '캐나다',
  CG: '콩고 공화국',
  CH: '스위스',
  CI: '코트디부아르',
  CL: '칠레',
  CM: '카메룬',
  CN: '중국',
  CO: '콜롬비아',
  CR: '코스타리카',
  CV: '카보베르데',
  CW: '퀴라소',
  CY: '사이프러스',
  CZ: '체코',
  DE: '독일',
  DJ: '지부티',
  DK: '덴마크',
  DM: '도미니카 연방',
  DO: '도미니카 공화국',
  DZ: '알제리',
  EC: '에콰도르',
  EE: '에스토니아',
  EG: '이집트',
  ES: '스페인',
  ET: '에티오피아',
  FI: '핀란드',
  FK: '포클랜드 제도(말비나스)',
  FR: '프랑스',
  GA: '가봉',
  GB: '영국',
  GD: '그레나다',
  GE: '조지아',
  GF: '프랑스령 기아나',
  GG: '건지 섬',
  GH: '가나',
  GI: '지브롤터',
  GM: '감비아',
  GN: '기니',
  GR: '그리스',
  GT: '과테말라',
  GW: '기니비사우',
  GY: '가이아나',
  HK: '홍콩',
  HN: '온두라스',
  HR: '크로아티아',
  HT: '아이티',
  HU: '헝가리',
  ID: '인도네시아',
  IE: '아일랜드',
  IL: '이스라엘',
  IM: '맨 섬',
  IN: '인도',
  IS: '아이슬란드',
  IT: '이탈리아',
  JE: '저지 섬',
  JM: '자메이카',
  JO: '요르단',
  JP: '일본',
  KE: '케냐',
  KG: '키르기스스탄',
  KH: '캄보디아',
  KM: '코모로',
  KR: '대한민국',
  KW: '쿠웨이트',
  KY: '케이맨 제도',
  KZ: '카자흐스탄',
  LA: '라오스',
  LC: '세인트 루시아',
  LI: '리히텐슈타인',
  LK: '스리랑카',
  LR: '라이베리아',
  LS: '레소토',
  LT: '리투아니아',
  LU: '룩셈부르크',
  LV: '라트비아',
  MA: '모로코',
  MC: '모나코',
  MD: '몰도바(공화국)',
  MF: '생마르탱',
  MG: '마다가스카르',
  ML: '말리',
  MN: '몽골',
  MO: '마카오',
  MP: '북마리아나 제도',
  MR: '모리타니',
  MT: '몰타',
  MU: '모리셔스',
  MV: '몰디브',
  MW: '말라위',
  MX: '멕시코',
  MY: '말레이시아',
  MZ: '모잠비크',
  NA: '나미비아',
  NE: '니제르',
  NG: '나이지리아',
  NL: '네덜란드',
  NO: '노르웨이',
  NP: '네팔',
  NZ: '뉴질랜드',
  OM: '오만',
  PA: '파나마',
  PE: '페루',
  PG: '파푸아 뉴기니',
  PH: '필리핀',
  PK: '파키스탄',
  PL: '폴란드',
  PM: '생 피에르 미클롱',
  PT: '포르투갈',
  PY: '파라과이',
  QA: '카타르',
  RO: '루마니아',
  RU: '러시아',
  RW: '르완다',
  SA: '사우디아라비아',
  SB: '솔로몬 제도',
  SC: '세이셸',
  SE: '스웨덴',
  SG: '싱가포르',
  SI: '슬로베니아',
  SK: '슬로바키아',
  SL: '시에라리온',
  SM: '산 마리노',
  SN: '세네갈',
  SR: '수리남',
  ST: '상투메 프린시페',
  SV: '엘살바도르',
  SZ: '스와질란드',
  TD: '차드',
  TG: '토고',
  TH: '태국',
  TJ: '타지키스탄',
  TL: '동티모르',
  TM: '투르크메니스탄',
  TN: '튀니지',
  TR: '터키',
  TT: '트리니다드 토바고',
  TW: '대만',
  TZ: '탄자니아공화국',
  UA: '우크라이나',
  UG: '우간다',
  US: '미국',
  UY: '우루과이',
  UZ: '우즈베키스탄',
  VC: '세인트 빈센트 그레나딘',
  VG: '버진 아일랜드(영국)',
  VN: '베트남',
  VU: '바누아투',
  YT: '마요트',
  ZA: '남아프리카 공화국',
  ZM: '잠비아',
  // AffiliatedDocumentType
  INTRODUCE_ENTREPRENEUR: '서류 안내_개인사업자',
  INTRODUCE_CORPERATION: '서류 안내_법인사업자',
  USAGE_TERMS: '이용약관',
  USAGE_AGREEMENT: '센트비즈 이용약정서',
  TRADE_CONFIRMATION: '고객거래 확인서',
  PRIVACY_AGREEMENT: '개인정보등 활용 필수적 동의서',
  BUSINESS_REGISTRATION: '사업자 등록',
  OWNER_ID_CARD: '대표자 신분증',
  INVOICE_CONTRACT: '인보이스, 계약서 등 경상거래를 입증하는 자료',
  CORP_ACCOUNT: '정산 받으실 입금계좌 (법인)',
  CORP_CERTIFIED: '법인 등기부등본',
  CORP_SIGNATURE: '법인 인감증명서',
  CORP_SHAREHOLDERS: '주주명부',
  ENTRE_ACCOUNT: '정산 받으실 입금계좌 (개인)',
  ENTRE_SIGNATURE: '개인 인감증명서',
  // BusinessCategory
  CORPORATE_BUSINESS: '법인',
  ENTREPRENEUR: '개인',
  // BusinessDivision
  LARGE: '대기업',
  MEDIUM: '중견기업',
  SMALL: '중소기업',
  PUBLIC: '공기업',
  ORGANIZATION: '정부/비영리조직',
  FINANCE: '금융기관',
  ETC: '기타',
  // StockInfo
  PRIVATE: '비상장',
  KOSPI: '코스피',
  KOSDAQ: '코스닥',
  // FundSource
  BUSINESS: '사업소득',
  REALTY: '부동산 소득',
  PENSION: '연금 소득',
  FINANCE_SALE: '금융 소득(이자 및 배당)',
  INHERITANCE: '상속/증여',
  SEVERANCE: '퇴직 소득',
  REALTY_SALE: '부동산 등 유형 자산 처분',
  STOCK_SALE: '유가 증권 등 투기 자산 처분',
  // TransactionPurpose
  CALCULATE: '경상거래대금 정산 대행',
  // CorpStatus
  REGISTER: '가입 신청 됨',
  FORM_COMPANY_PROCESSING: '사업체 정보 작성중',
  FORM_CEO_PROCESSING: '대표자 및 계좌 정보 작성중',
  FORM_BUSINESS_PROCESSING: '사업체 실제 소유자 정보 작성중',
  FORM_COMPLETE: '서류 정보 작성 완료',
  PROCESSING: '서류 업로드 완료',
  CONFIRMED: '승인 완료',
  // RemittanceStatus
  ANALOGUE_CHECK: '아날로그 들어옴(Status 145)',
  ANALOGUE_PROCESSING: '은행 신청 완료함(Status 146)'
}
