import { RequestRegister } from '@/adapters/affiliate/RequestRegister'
import { regexToPatternString } from '@/data/Regex'
import Currency from '@/enums/Currency'
import { CorpRegister } from '@/presentation/affiliated/CorpRegister'
import { FormValidator } from '@/presentation/FormValidator'
import { IFormView } from '@/presentation/IFormView'
import FormItemsOption from '@/types/FormItemsOption'
import { Vue } from 'vue/types/vue'

export interface Register extends IFormView {
  field: CorpRegister
  isOpenFeeDialog: boolean

  onSubmit (form: any, $this?: Vue): Promise<void>
}

export class Register implements Register {
  private formValidator: FormValidator = new FormValidator()
  private requestRegister: RequestRegister = RequestRegister.getInstance()

  formName = 'registerForm'
  field: CorpRegister = {
    name: '',
    biz_license_number: '',
    fee_terms: '',
    fees: [],
    cut_off_time: '',
    special_terms: '',
    temp_email: '',
    symbol: '',
    base_currency: 'KRW',
    memo: '',
    analogue: false,
    kor_corp: false
  }

  forms: Array<FormItemsOption> = [
    {
      prop: 'name',
      label: '영문 상호(법인)명 Company Name',
      type: 'text',
      value: undefined
    },
    {
      prop: 'biz_license_number',
      label: '사업자 등록번호 Company Registration Number',
      type: 'text',
      minlength: 10,
      maxlength: 10,
      showLimit: true,
      value: undefined
    },
    { prop: 'fee_terms', label: '수수료 Fee', type: 'textarea', value: undefined },
    {
      prop: 'fees',
      label: '수수료 데이터 입력하기 Enter fee data',
      type: 'link',
      useFormItemContainer: false,
      value: undefined,
      emitHandlerName: 'openFeeDialog'
    },
    { prop: 'cut_off_time', label: '컷오프시간', type: 'textarea', value: undefined },
    { prop: 'special_terms', label: '특약', type: 'textarea', value: undefined },
    { prop: 'temp_email', label: '제휴신청 비밀번호 보낼 이메일 Temporary email to sign up', type: 'text', value: undefined },
    {
      prop: 'symbol',
      label: '회사코드 Company code',
      type: 'text',
      value: undefined,
      minlength: 3,
      maxlength: 3,
      showLimit: true,
      class: 'case-upper'
    },
    {
      prop: 'base_currency',
      label: '입금하는 환 Basic deposit currency for Sentbe Wallet',
      type: 'select',
      options: [
        { label: Currency[Currency.KRW], value: Currency[Currency.KRW] },
        { label: Currency[Currency.USD], value: Currency[Currency.USD] }
      ],
      value: undefined
    },
    { prop: 'memo', label: 'Memo', type: 'textarea', value: undefined },
    {
      prop: 'analogue',
      label: '아날로그허용회사. 파트너가 지원하지 않는 수취환의 계좌&국가에 아날로그 송금으로 보낼 수 있음.',
      type: 'checkbox',
      formItemClass: 'checkbox-form-item',
      value: undefined
    },
    {
      prop: 'kor_corp',
      label: 'Non-Korean company. Only analogue transfer can.',
      type: 'checkbox',
      formItemClass: 'checkbox-form-item',
      value: undefined
    }
  ]

  rules: Dictionary<Array<FormRule>> = {
    name: [{ required: true }, { pattern: regexToPatternString('address_line1'), message: 'english only' }],
    biz_license_number: [{ required: true, pattern: regexToPatternString('number'), message: 'invalid number format' }],
    fee_terms: [],
    cut_off_time: [],
    special_terms: [],
    temp_email: [{ required: true }, { pattern: regexToPatternString('email'), message: 'invalid email format' }],
    symbol: [{ required: true }, { pattern: regexToPatternString('english'), message: 'alphabet only' }],
    base_currency: [{ required: true }],
    memo: [],
    checkbox: []
  }

  isOpenFeeDialog = false

  async onSubmit (form: any, $this: Vue): Promise<void> {
    const isValid = await this.formValidator.validate(form)
    if (!isValid) return
    if (!this.field.fees.length) {
      await $this.$alert('Type fee data', { center: true })
      this.isOpenFeeDialog = true
      return
    }
    const message = 'Register & Send Email. Sure?'
    const isConfirm: boolean = await $this.$confirm(message, {
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      dangerouslyUseHTMLString: true,
      center: true
    }).then(() => true).catch(() => false)
    if (!isConfirm) return
    this.field.symbol = this.field.symbol.toUpperCase()
    this.field.kor_corp = !this.field.kor_corp
    const response = await this.requestRegister.request(this.field)
    const isSuccessResponse = response.code === 200
    if (!isSuccessResponse) {
      if (Math.floor(response.code / 10) === 2) await $this.$alert('회사코드 or 사업자등록번호 중복됨. Already registered ‘Company code or Company registration number’.', { center: true })
      return
    }
    $this.$message({ message: 'Successfully completed', type: 'success' })
    await $this.$router.push('list')
  }
}
