
















import Vue from 'vue'
import Component from 'vue-class-component'
import router from '@/router'

@Component
export default class DefaultHeader extends Vue {
  get email (): string | null {
    return localStorage.getItem('email')
  }

  get version (): string {
    return process.env.VUE_APP_VERSION || ''
  }

  logout (): void {
    localStorage.clear()
    router.push('/signIn')
  }
}
