











































































import Vue from 'vue'
import Component from 'vue-class-component'
import { AddAccount } from '@/presentation/affiliated/AddAccount'
import FormItemsModelInput from '@/components/shared/FormItemsModelInput.vue'
import { Prop } from 'vue-property-decorator'
import { Member } from '@/adapters/affiliate/Corp'
import { IResetPasswordUseCase, ResetPasswordUseCase } from '@/usecase/ResetPasswordUseCase'

@Component({
  components: { FormItemsModelInput }
})

export default class AccountManagement extends Vue {
  @Prop({
    type: String,
    required: true
  }) corpId!: string
  @Prop({
    type: Array,
    required: true
  }) corpMembers!: Array<Member>
  addAccount: AddAccount = new AddAccount(this.corpMembers, this.corpId)

  resetPasswordUseCase: IResetPasswordUseCase = new ResetPasswordUseCase()

  onClickReset (value: any): void {
    this.$confirm('비밀번호를 초기화하시겠습니까?', '비밀번호 초기화', {
      confirmButtonText: '비밀번호 초기화',
      cancelButtonText: '돌아가기',
      type: 'warning'
    }).then(() => {
      this.resetPasswordUseCase.resetUserPassword({ id: value.id, email: value.email }).then((result) => {
        if (result) this.$message({ message: '초기화되었습니다.', type: 'success' })
        else this.$message({ message: '오류가 발생했습니다.', type: 'warning' })
      })
    }).catch(() => {})
  }

  async onSubmit (): Promise<void> {
    const isEmptyAdmin = !this.addAccount.field.administrator.email || !this.addAccount.field.administrator.phone
    if (isEmptyAdmin) this.$message({ message: '계정 관리자를 추가해 주세요.', type: 'warning' })
    const isValid = await this.addAccount.onSubmit(this.$refs[this.addAccount.formName])
    if (!isValid) {
      this.$message({ message: '저장에 실패했습니다.', type: 'error' })
      return
    }
    this.$message({ message: '저장 완료되었습니다.', type: 'success' })
    this.$emit('onSubmitAccount')
  }
}
