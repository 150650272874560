import { AxiosCreator } from '@/adapters/axios/AxiosCreator'
import AxiosOptions from '@/adapters/axios/AxiosOptions'
import { AxiosInstance } from 'axios'

const sentbizApiOption: AxiosOptions = {
  api: process.env.VUE_APP_API,
  isAuthTokenRequired: true
}
export const sentbizApi: AxiosInstance = new AxiosCreator().create(sentbizApiOption)

const sentbizApiWithoutAuthOption: AxiosOptions = {
  api: process.env.VUE_APP_API,
  isAuthTokenRequired: false
}
export const sentbizApiWithoutAuth: AxiosInstance = new AxiosCreator().create(sentbizApiWithoutAuthOption)
