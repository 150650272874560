import { sentbizApi } from '../axios'
import { IApiRequest } from '@/adapters/axios/IApiRequest'
import { Remittance } from '@/types'

export interface RemittanceGroupDetailParams {
  remittance_group_id: string;
}

export interface RemittanceGroupDetail extends IApiRequest {
  getInstance (): RemittanceGroupDetail;

  request (params: RemittanceGroupDetailParams): Promise<Array<Remittance>>;
}

export class RemittanceGroupDetail implements RemittanceGroupDetail {
  private static instance: RemittanceGroupDetail
  private constructor () {}
  public static getInstance (): RemittanceGroupDetail {
    if (!this.instance) this.instance = new RemittanceGroupDetail()
    return this.instance
  }

  async request (params: RemittanceGroupDetailParams): Promise<Array<Remittance>> {
    const requestOption: RequestOption = {
      url: 'remittance/group/detail',
      method: 'post',
      data: params
    }
    const response = await sentbizApi.request(requestOption)
    return response.data.list
  }
}
