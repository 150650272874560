















import { Component } from 'vue-property-decorator'
import ElMenuItem from '@/components/commons/ElMenuItem.vue'
import RouterOption from '@/types/RouterOption'

@Component({
  components: {
    ElMenuItem
  }
})
export default class ElSubMenu extends ElMenuItem {
  menuList (nav: RouterOption) {
    return nav.children ? 'ElSubMenu' : 'ElMenuItem'
  }
}
