












import Vue from 'vue'
import FilterTextInput from '@/dome/components/filter/inputs/FilterTextInput.vue'

export default Vue.extend({
  name: 'FilterCodeContainer',
  components: { FilterTextInput },
  props: {
    codeFilter: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      labels: {
        tid: 'TID',
        order_number: '주문번호',
        card_number: '카드번호 뒤 4자리',
        seller_id: 'Seller ID',
        corp_name: '가맹점명'
      }
    }
  }
})
