import { countryMasterCodes } from '@/data/masterCodes/countryMasterCodes'

export class MasterCode {
  private static masterCodeLabels?: Dictionary<string> = undefined

  public static getMasterCodeLabels (): Dictionary<string> {
    const isSetMasterCodeLabelsAlready = !!this.masterCodeLabels
    if (isSetMasterCodeLabelsAlready) return this.masterCodeLabels as Dictionary<string>
    let masterCodeLabels: Dictionary<string> = {}
    Object.keys(countryMasterCodes).forEach((country: string) => {
      const swappedObject: Dictionary<string> = Object.entries(countryMasterCodes[country]).reduce((accMasterCodeLabels: Dictionary<string>, entry) => {
        const [label, masterCode] = entry
        const stringValue: string = masterCode.toString()
        accMasterCodeLabels[stringValue] = label
        return accMasterCodeLabels
      }, {})
      masterCodeLabels = Object.assign({}, masterCodeLabels, swappedObject)
    })
    this.masterCodeLabels = masterCodeLabels

    return this.masterCodeLabels
  }
}
