











import Vue, { PropType } from 'vue'
import AlertGraph from '@/dome/components/DomeTransactionalInfo/AlertGraph.vue'
import { IDomeAlertModalProps } from '@/dome/types/IDomeAlertModal'

export default Vue.extend({
  components: {
    AlertGraph
  },
  props: {
    modalProps: {
      type: Object as PropType<IDomeAlertModalProps>,
      required: true
    }
  }
})
