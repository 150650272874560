import Vue from 'vue'

export interface FormValidator {
  validate (form: any): Promise<boolean | any>;
}

export class FormValidator implements FormValidator {
  async validate (form: any): Promise<boolean | any> {
    try {
      return await (form as Vue & { validate: () => boolean | any }).validate()
    } catch (error) {
      return error
    }
  }
}
