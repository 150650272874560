import { IApiRequest } from '@/adapters/axios/IApiRequest'
import { sentbizApi } from '@/adapters/axios'
import Currency from '@/enums/Currency'
import { CorpFee } from '@/presentation/affiliated/CorpFee'

export interface UpdateCorpParams {
  analogue?: boolean
  id?: string
  fee_terms?: string
  cut_off_time?: string
  special_terms?: string
  symbol?: string
  base_currency?: keyof typeof Currency
  temp_email?: string
  biz_type?: string
  biz_item?: string
  assigned_virtual_account?: string
  fees?: Array<CorpFee>
  kor_corp?: boolean
}

export interface UpdateCorp extends IApiRequest {
  getInstance (): UpdateCorp
  request (params: UpdateCorpParams): Promise<any>
}
export class UpdateCorp implements UpdateCorp {
  private static instance: UpdateCorp
  private constructor () {}
  public static getInstance (): UpdateCorp {
    if (!this.instance) this.instance = new UpdateCorp()
    return this.instance
  }

  async request (params: UpdateCorpParams): Promise<any> {
    const requestOption: RequestOption = {
      url: 'hades/corp',
      method: 'put',
      data: params
    }
    const response = await sentbizApi.request(requestOption)
    return response
  }
}
