




























import Vue, { PropType } from 'vue'
import { IMemoLine } from '@/dome/types/IMemoTimeLine'
import { FraudTimelineActionStatus } from '@/dome/constants/FraudStatus'
import { mapActions } from 'vuex'
import { FraudTransactionActions } from '@/dome/store/fraudTransactionState'

export default Vue.extend({
  props: {
    memoLine: {
      type: Object as PropType<IMemoLine>,
      required: true
    },
    isLast: {
      type: Boolean
    }
  },
  data() {
    return {
      isPopoverVisible: false,
      isInputShown: false,
      inputContent: '',
      actionClassName: {
        [FraudTimelineActionStatus.ACTION_FRAUD_PASS]: 'el-icon-success',
        [FraudTimelineActionStatus.ACTION_FRAUD_BLOCK]: 'el-icon-error',
        [FraudTimelineActionStatus.ACTION_DISPUTE_WON]: 'el-icon-success',
        [FraudTimelineActionStatus.ACTION_DISPUTE_LOST]: 'el-icon-error',
        [FraudTimelineActionStatus.ACTION_CHARGEBACK_ACCEPT]: 'el-icon-success',
        [FraudTimelineActionStatus.ACTION_CHARGEBACK_FRAUD]: 'el-icon-error',
        [FraudTimelineActionStatus.ACTION_MEMO_CREATE]: 'el-icon-document',
        [FraudTimelineActionStatus.ACTION_MEMO_UPDATE]: 'el-icon-document'
      },
      actionToContentObject: {
        [FraudTimelineActionStatus.ACTION_FRAUD_PASS]: 'FRAUD_PASS',
        [FraudTimelineActionStatus.ACTION_FRAUD_BLOCK]: 'FRAUD_BLOCK',
        [FraudTimelineActionStatus.ACTION_DISPUTE_WON]: '분쟁_승소',
        [FraudTimelineActionStatus.ACTION_DISPUTE_LOST]: '분쟁_패소',
        [FraudTimelineActionStatus.ACTION_CHARGEBACK_ACCEPT]: 'CHARGEBACK_ACCEPT',
        [FraudTimelineActionStatus.ACTION_CHARGEBACK_FRAUD]: 'CHARGEBACK_FRAUD',
        [FraudTimelineActionStatus.ACTION_MEMO_CREATE]: '',
        [FraudTimelineActionStatus.ACTION_MEMO_UPDATE]: ''
      }
    }
  },
  computed: {
    isMemo(): boolean {
      return (
        `${this.memoLine.action}` === `${FraudTimelineActionStatus.ACTION_MEMO_CREATE}` ||
        `${this.memoLine.action}` === `${FraudTimelineActionStatus.ACTION_MEMO_UPDATE}`
      )
    },
    isMemoMine(): boolean {
      return this.memoLine.writer === localStorage.getItem('email')
    },
    showContent (): string {
      return this.isMemo ? this.memoLine.content : this.actionToContentObject[this.memoLine.action]
    }
  },
  methods: {
    ...mapActions('fraudTransactionState', {
      updateFraudTransactionMemo: FraudTransactionActions.UpdateFraudTransactionMemo,
      deleteFraudTransactionMemo: FraudTransactionActions.DeleteFraudTransactionMemo
    }),
    onClickMore(): void {
      this.inputContent = this.memoLine.content
      this.isInputShown = true
      this.isPopoverVisible = false
    },
    async onClickConfirm(): Promise<void> {
      await this.updateFraudTransactionMemo({
        id: this.memoLine.id,
        writer: localStorage.getItem('email'),
        content: this.inputContent
      })
      this.$emit('memoModified')
      this.isInputShown = false
    },
    async onClickDelete(): Promise<void> {
      this.$confirm('메모를 삭제하시겠습니까?', '경고', {
        confirmButtonText: '삭제',
        cancelButtonText: '취소',
        type: 'warning'
      })
        .then(async () => {
          await this.deleteFraudTransactionMemo(this.memoLine.id)
          this.$emit('memoDeleted')
        })
        .catch()
    }
  }
})
