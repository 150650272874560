

















































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Corp } from '@/adapters/affiliate/Corp'
import FormItems from '@/components/shared/FormItems.vue'
import FormItemsOption from '@/types/FormItemsOption'
import Currency from '@/enums/Currency'
import { getEnumOptions, getEnumValueArray } from '@/lib/Utils'
import FeeModal from '@/components/affiliate/FeeModal.vue'
import BasicDialog from '@/components/commons/BasicDialog.vue'
import { ResendSignUpEmail, ResendSignUpEmailParams } from '@/adapters/affiliate/ResendSignUpEmail'
import { UpdateCorp, UpdateCorpParams } from '@/adapters/affiliate/UpdateCorp'
import { CorpFeesApi } from '@/adapters/affiliate/CorpFeesApi'
import { regexpOptions, regexToPatternString } from '@/data/Regex'
import AccountManagement from '@/components/affiliate/AccountManagement.vue'
import { UpdateCorpName } from '@/adapters/corp/UpdateCorpName'
import { FormValidator } from '@/presentation/FormValidator'

@Component({
  components: { AccountManagement, BasicDialog, FeeModal, FormItems }
})
export default class CorpModal extends Vue {
  @Prop(Object) corp!: Corp

  formValidator: FormValidator = new FormValidator()
  field: UpdateCorpParams = {
    analogue: this.corp.analogue,
    fee_terms: this.corp.fee_terms,
    cut_off_time: this.corp.cut_off_time,
    special_terms: this.corp.special_terms,
    symbol: this.corp.symbol,
    base_currency: this.corp.base_currency,
    temp_email: this.corp.temp_email,
    biz_type: this.corp.biz_type,
    biz_item: this.corp.biz_item,
    assigned_virtual_account: this.corp.assigned_virtual_account,
    fees: this.corp.fees || [],
    kor_corp: !this.corp.kor_corp
  }

  forms: Array<FormItemsOption> = [
    {
      prop: 'account_modal',
      label: '계정 관리 Manage account',
      type: 'link',
      size: 'mini',
      useFormItemContainer: false,
      value: undefined,
      emitHandlerName: 'onClickAccountManagement'
    },
    { prop: 'fee_terms', label: '수수료 Fee', type: 'textarea', value: undefined },
    {
      prop: 'fee_modal',
      label: '수수료 데이터 입력하기 Enter fee data',
      type: 'link',
      size: 'mini',
      useFormItemContainer: false,
      value: undefined,
      emitHandlerName: 'onClickFeeData'
    },
    { prop: 'cut_off_time', label: '컷오프타임', type: 'textarea', value: undefined },
    { prop: 'special_terms', label: '특약', type: 'textarea', value: undefined },
    {
      prop: 'symbol',
      label: '회사 코드 Company code',
      type: 'text',
      value: undefined,
      minlength: 3,
      maxlength: 3,
      showLimit: true,
      class: 'case-upper'
    },
    {
      prop: 'base_currency',
      label: '입금하는 환 Basic deposit currency for Sentbe Wallet',
      type: 'select',
      options: getEnumOptions(Currency, getEnumValueArray(Currency), true),
      value: undefined
    },
    { prop: 'temp_email', label: '제휴신청 비밀번호 보낼 이메일 Temporary email to sign up', type: 'text', value: undefined },
    {
      prop: 'send_email',
      label: '재전송하기 (제휴 비밀번호 수정됨) Resend email',
      type: 'link',
      size: 'mini',
      useFormItemContainer: false,
      value: undefined,
      emitHandlerName: 'onClickResendEmail'
    },
    { prop: 'biz_type', label: '업태 Business category 1', type: 'text', value: undefined },
    { prop: 'biz_item', label: '종목 Business category 2', type: 'text', value: undefined },
    { prop: 'assigned_virtual_account', label: '가상계좌번호 Virtual account number', type: 'text', value: undefined },
    {
      prop: 'analogue',
      label: '아날로그허용회사. 파트너가 지원하지 않는 수취환의 계좌&국가에 아날로그 송금으로 보낼 수 있음.',
      type: 'checkbox',
      formItemClass: 'checkbox-form-item'
    },
    {
      prop: 'kor_corp',
      label: 'Non-Korean company. Only analogue transfer can.',
      type: 'checkbox',
      formItemClass: 'checkbox-form-item'
    }
  ]

  rules: Dictionary<Array<FormRule>> = {
    temp_email: [{ pattern: regexToPatternString('email'), message: 'invalid email format' }],
    symbol: [{ pattern: regexToPatternString('english'), message: 'alphabet only' }],
    assigned_virtual_account: [{ required: true }]
  }

  isFeeModalOpen = false
  isAccountModalOpen = false
  isButtonDisabled = false

  async updateCorpName (corpName: string) {
    const param = { id: this.corp.corp_id, name: corpName }
    const response = await UpdateCorpName.getInstance().request(param)
    const isSuccessResponse: boolean = response.code === 200
    if (!isSuccessResponse) return
    this.$message({ message: '변경 되었습니다.', type: 'success' })
    this.reload()
  }

  async editCorpName () {
    const confirmedValue = await this.$prompt('변경 할 회사 이름을 입력해 주세요', '회사명 변경(영문)', {
      confirmButtonText: '저장',
      showCancelButton: false,
      inputValidator: value => !!value,
      inputPattern: regexpOptions.corp_name,
      inputValue: this.corp.name,
      inputErrorMessage: '한글 및 특수문자는 입력 불가능 합니다.'
    }).catch(() => false) as any
    if (!confirmedValue) return
    await this.updateCorpName(confirmedValue.value)
  }

  onSubmitAccount () {
    this.isAccountModalOpen = false
    location.reload()
  }

  async onClickAccountManagement () {
    this.isAccountModalOpen = true
  }

  async onSubmitFees (fees: Array<any>) {
    this.isFeeModalOpen = false
    this.field.fees = fees
    const corpFeeApi = CorpFeesApi.getInstance()
    const updateCorpFeesParams = { corp_id: this.corp.corp_id, fees: this.field.fees }
    const response = await corpFeeApi.put(updateCorpFeesParams)
    if (response.code !== 200) return
    this.field.fees = await corpFeeApi.get({ corp_id: this.corp.corp_id })
    this.$message({ message: '업데이트 되었습니다.', type: 'success' })
  }

  onClickFeeData () {
    this.isFeeModalOpen = true
  }

  async onClickResendEmail () {
    if (!this.field.temp_email) return
    const message = `${this.field.temp_email}으로 제휴메일을 재전송 하시겠습니까?`
    const isConfirm: boolean = await this.$confirm(message, {
      confirmButtonText: '재전송하기',
      cancelButtonText: '취소',
      dangerouslyUseHTMLString: true,
      center: true
    }).then(() => true).catch(() => false)
    if (!isConfirm) return
    const params: ResendSignUpEmailParams = {
      id: this.corp.corp_id,
      temp_email: this.field.temp_email
    }
    const response = await ResendSignUpEmail.getInstance().request(params)
    if (response.code !== 200) return
    this.$message({ message: '가입 메일이 재전송 되었습니다.', type: 'success' })
  }

  closeCorpModal () {
    this.$emit('closeCorpModal')
  }

  reload () {
    this.$emit('reload')
  }

  async onSubmit () {
    this.isButtonDisabled = true
    this.field.symbol = this.field.symbol?.toUpperCase()
    const isKorCorpAndNoAccount = this.field.kor_corp && !this.field.assigned_virtual_account
    if (isKorCorpAndNoAccount) {
      return this.$alert('가상계좌번호를 입력해주세요', { center: true })
    }
    const updateCorpParams: UpdateCorpParams = Object.assign({}, { id: this.corp.corp_id }, this.field)
    updateCorpParams.kor_corp = !this.field.kor_corp
    delete updateCorpParams.fees
    const response = await UpdateCorp.getInstance().request(updateCorpParams)
    if (response.code !== 200) {
      if (Math.floor(response.code / 10) === 2) this.$alert('회사 코드가 중복되었습니다.', { center: true })
      return
    }
    this.$message({ message: '업데이트 되었습니다.', type: 'success' })
    this.reload()
    this.closeCorpModal()
  }
}
