import { CreateAdmin, CreateAdminParams } from '@/adapters/CreateAdmin'

export interface ICreateAccountUseCase {
  createAccount (params: CreateAdminParams): Promise<boolean>;
}

export class CreateAccountUseCase implements ICreateAccountUseCase {
  public async createAccount (params: CreateAdminParams): Promise<boolean> {
    let response = await CreateAdmin.getInstance().request(params)

    return response.code === 200
  }
}
