









import Vue from 'vue'
import { FraudStatus } from '@/dome/constants/FraudStatus'
import FilterCheckBoxGroup from '@/dome/components/filter/inputs/FilterCheckBoxGroup.vue'

export default Vue.extend({
  name: 'FilterStatusContainer',
  components: { FilterCheckBoxGroup },
  data () {
    return {
      statuses: {
        'Fraud Pass': FraudStatus.FRAUD_PASS,
        'Fraud Block': FraudStatus.FRAUD_BLOCK,
        'Chargeback Accept': FraudStatus.CHARGEBACK_ACCEPT,
        'Chargeback Fraud': FraudStatus.CHARGEBACK_FRAUD,
        '분쟁 승소': FraudStatus.DISPUTE_WON,
        '분쟁 패소': FraudStatus.DISPUTE_LOST
      }
    }
  },
  computed: {
    labels (): Array<string> {
      return Object.keys(this.statuses)
    }
  },
  methods: {
    onChange (values: Array<string>) {
      const statuses = this.statuses as Dictionary<FraudStatus>
      const statusValues = values.map(value => statuses[value])
      this.$emit('onChange', statusValues)
    }
  }
})
