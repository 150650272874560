import { sentbizApiWithoutAuth } from '../axios'

export class RequestOtp {
  private static instance: RequestOtp
  private constructor() {}
  public static getInstance(): RequestOtp {
    if (!this.instance) this.instance = new RequestOtp()
    return this.instance
  }

  public async signin(data: SignRequest): Promise<any> {
    const requestOption: RequestOption = {
      url: 'signin',
      method: 'post',
      data
    }
    const response = await sentbizApiWithoutAuth.request(requestOption)
    return response
  }

  public async signup(data: SignRequest): Promise<any> {
    const requestOption: RequestOption = {
      url: 'signup',
      method: 'post',
      data
    }
    const response = await sentbizApiWithoutAuth.request(requestOption)
    return response
  }
}
