import { sentbizApi } from '../axios'
import { IApiRequest } from '@/adapters/axios/IApiRequest'
import { CorpInformation } from '@/types/CorpInformation'

export interface CorpInformationApiParams {
  corp_id: string;
}

interface CorpInformationApi extends IApiRequest {
  getInstance (): CorpInformationApi;

  request (params: CorpInformationApiParams): Promise<CorpInformation>;
}

class CorpInformationApi implements CorpInformationApi {
  private static instance: CorpInformationApi

  private constructor () {
  }

  public static getInstance (): CorpInformationApi {
    if (!this.instance) this.instance = new CorpInformationApi()
    return this.instance
  }

  async request (params: CorpInformationApiParams): Promise<CorpInformation> {
    const requestOption: RequestOption = {
      url: 'corp',
      method: 'get',
      params
    }
    const response = await sentbizApi.request(requestOption)
    return response.data
  }
}

export class GetCorpInformation {
  private static corpInformation: Map<string, CorpInformation> = new Map<string, CorpInformation>()

  public static async get (corpInformationApiParams: CorpInformationApiParams): Promise<CorpInformation> {
    if (this.corpInformation.has(corpInformationApiParams.corp_id)) return this.corpInformation.get(corpInformationApiParams.corp_id) as CorpInformation
    const corpInformation: CorpInformation = await CorpInformationApi.getInstance().request(corpInformationApiParams)
    this.corpInformation.set(corpInformationApiParams.corp_id, corpInformation)
    return corpInformation
  }
}
