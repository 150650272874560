import { sentbizApi } from "@/adapters/axios";
import { AxiosResponse } from "axios";
import { postTransactionMemoParam } from "../store/fraudTransactionState";
import { IFraudFilter } from '@/dome/types/FraudTypes'
import { IFraudTransactionsRequest } from '@/dome/types/api/FraudApiTypes'
import { FraudStatus } from '@/dome/constants/FraudStatus'

export default {
  fetchCorpIds (): Promise<AxiosResponse> {
    const option: RequestOption = {
      url: 'hades/corp_basic',
      method: 'get',
      params: {
        use_fraud: true
      }
    }
    return sentbizApi.request(option)
  },
  fetchFraudStatics (requestParam: IFraudFilter): Promise<AxiosResponse> {
    const option: RequestOption = {
      url: 'hades/fraud-transaction/statics',
      method: 'post',
      data: requestParam
    }
    return sentbizApi.request(option)
  },
  fetchFraudTransactions (requestParam: IFraudTransactionsRequest): Promise<AxiosResponse> {
    const option: RequestOption = {
      url: 'hades/fraud-transaction/search',
      method: 'post',
      data: requestParam
    }
    return sentbizApi.request(option)
  },
  fetchFraudTransactionDetail (id: number): Promise<AxiosResponse> {
    const option: RequestOption = {
      url: `hades/fraud-transaction/${id}`,
      method: 'get'
    }
    return sentbizApi.request(option)
  },
  updateFraudTransactionStatus (params: { id: number; status: FraudStatus }): Promise<AxiosResponse> {
    const option: RequestOption = {
      url: 'hades/fraud-transaction/status',
      method: 'put',
      data: params
    }
    return sentbizApi.request(option)
  },
  postFraudTransactionMemo (param: postTransactionMemoParam): Promise<AxiosResponse> {
    const option: RequestOption = {
      url: 'hades/fraud-transaction/memo',
      method: 'post',
      data: param
    }
    return sentbizApi.request(option)
  },
  updateFraudTransactionMemo (param: postTransactionMemoParam): Promise<AxiosResponse> {
    const option: RequestOption = {
      url: 'hades/fraud-transaction/memo',
      method: 'put',
      data: param
    }
    return sentbizApi.request(option)
  },
  deleteFraudTransactionMemo (id: string): Promise<AxiosResponse> {
    const option: RequestOption = {
      url: `hades/fraud-transaction/memo/${id}`,
      method: 'delete'
    }
    return sentbizApi.request(option)
  }
}
