


























































import Vue from 'vue'
import Component from 'vue-class-component'
import { CreateAdminParams } from '@/adapters/CreateAdmin'
import { ICreateAccountUseCase, CreateAccountUseCase } from '@/usecase/CreateAccountUseCase'
@Component
export default class AdminCreateAccount extends Vue {
  createAccountUseCase: ICreateAccountUseCase = new CreateAccountUseCase()

  accountForm: CreateAdminParams = {
    admin_name: '',
    admin_id: '',
    password: '',
    nationality: 'KR',
    phone_number: 10,
    phone_code: 82,
    authority: ['master']
  }

  formRules: Object = {
    admin_name: [{
      required: true, message: '이름을 입력해주세요', trigger: 'blur'
    }],
    admin_id: [{
      required: true, message: '아이디를 입력해주세요', trigger: 'blur'
    }],
    password: [{
      required: true, message: '비밀번호를 입력해주세요', trigger: 'blur'
    }],
    phone_number: [{
      required: true, message: '전화번호를 입력해주세요', trigger: 'blur'
    }]
  }

  /**
   * '생성' 버튼을 눌렀을 때, 폼의 유효화 검사를 시행하고 유효할 경우 createAccount useCase로 계정 생성 API를 호출한다.
   */
  onSubmit (): void {
    (this.$refs.createAccountForm as Vue & { validate: (valid: any) => boolean }).validate(async (valid: any) => {
      if (valid) {
        this.accountForm.phone_number = Number(this.accountForm.phone_number)
        const response = await this.createAccountUseCase.createAccount(this.accountForm)
        if (response) this.$message('생성되었습니다.')
        else this.$message({ message: '오류가 발생했습니다.', type: 'warning' })
      } else {
        return false
      }
    })
  }
}
