import { CertDocumentPdfHandler } from '@/lib/pdf/cert/CertDocumentPdfHandler'
import { formattersMap } from '@/lib/Utils'
import { KrwFxRates } from '@/adapters/fxRates/KrwFxRates'
import dayjs from 'dayjs'
import { FxRates } from '@/adapters/fxRates/FxRates'
import { CalculateResult } from '@/lib/calculator/CalculateResult'
import { Remittance } from '@/types'
import { RemittanceByGroup } from '@/types/RemittanceByGroup'
import { CorpInformation } from '@/types/CorpInformation'
import StatusType from '@/enums/RemittanceStatusType'
import { CalculatorSourceType, Calculator, ICalculatorSource } from '@/lib/calculator/Calculator'

export interface TransactionReceiptParam {
  corpInfo: CorpInformation
  remittanceGroup: RemittanceByGroup
  remittanceDetails: Array<Remittance>
  fxRates: FxRates
  krwFxRates: KrwFxRates
  statusType: StatusType | Array<StatusType>
}

export class TransactionReceiptHandler extends CertDocumentPdfHandler {
  private readonly transactionReceiptParam: TransactionReceiptParam
  private readonly transactionsContent: Array<Dictionary<any>>
  private readonly textContents: Dictionary<any>
  private grandTotal: number = 0
  private subTotals: { amountKrw: number; fee: number; receiverFee: number; subTotal: number } = {
    amountKrw: 0,
    fee: 0,
    receiverFee: 0,
    subTotal: 0
  }

  constructor(transaction: TransactionReceiptParam) {
    super('landscape')
    this.transactionReceiptParam = transaction
    this.transactionsContent = this.makeTransactionContents()
    this.textContents = this.makeTextContents()
  }

  private makeTransactionContents(): Array<Dictionary<any>> {
    const krwFxRates = this.transactionReceiptParam.krwFxRates
    const rates = this.transactionReceiptParam.fxRates
    const transactionContents: Array<Dictionary<any>> = this.transactionReceiptParam.remittanceDetails.map(
      remittance => {
        const isVersion4 = remittance.version === 4
        const transactionDate = dayjs(remittance.created_at)
        const krwFxRatesKey: keyof KrwFxRates =
          `${remittance.receive_amount.currency.toLowerCase()}_krw` as keyof KrwFxRates
        const rate: number =
          remittance.receive_amount.currency.toLowerCase() === 'krw'
            ? 1
            : parseFloat(krwFxRates[krwFxRatesKey] as string)
        const appliedRate: number = isVersion4 ? rate * (1 + remittance.rate_fee) : rate
        const calculatorSource: ICalculatorSource = {
          type: CalculatorSourceType.RECEIPT_TRANSACTION_RECEIPT,
          remittanceHistoryGroupDetail: remittance
        }
        const calculator: Calculator = new Calculator(rates)
        calculator.setSource(calculatorSource)
        const calculated: CalculateResult = calculator.calculate()
        const fee = isVersion4 ? calculated.fixedCommission : calculated.commission
        const receiverFee = calculated.receiverCommissionSendCurrency
        const subTotalAmount = calculated.sendAmount
        const amountKrw = subTotalAmount - fee
        const receiveAmount = remittance.receive_amount.balance
        this.subTotals.amountKrw += amountKrw
        this.subTotals.fee += fee
        this.subTotals.subTotal += subTotalAmount
        this.subTotals.receiverFee += receiverFee
        this.grandTotal = this.subTotals.subTotal
        return {
          'Trans. Date': transactionDate.format('YYYY-MM-DD HH:mm'),
          'Trans. No.': remittance.id,
          Sending: `${remittance.base_amount.currency} ${formattersMap.number(remittance.base_amount.balance)}`, // base
          Amount: `${formattersMap.number(amountKrw)}`, // send
          Fee: formattersMap.number(fee),
          Deposit: formattersMap.number(subTotalAmount),
          'Recipient Fee': formattersMap.number(receiverFee),
          'FX Rate': `${formattersMap.number(appliedRate.toFixed(2))} ${krwFxRatesKey.toUpperCase().replace('_', '')}`,
          'Receive Amount': `${remittance.receive_amount.currency} ${formattersMap.number(receiveAmount)}`
        }
      }
    )
    transactionContents.sort((ta, tb) => ta['Trans. No.'] - tb['Trans. No.'])
    transactionContents.push({
      'Trans. Date': 'Sub Total ',
      'Trans. No.': '',
      Sending: '',
      Amount: formattersMap.number(this.subTotals.amountKrw),
      Fee: formattersMap.number(this.subTotals.fee),
      Deposit: formattersMap.number(this.subTotals.subTotal),
      'Recipient Fee': formattersMap.number(this.subTotals.receiverFee),
      'FX Rate': '',
      'Receive Amount': ''
    })
    return transactionContents
  }

  private makeTextContents(): Dictionary<any> {
    const paymentCompletedAt = dayjs(this.transactionReceiptParam.remittanceGroup.payment_completed_at)
    const updatedAt = dayjs(this.transactionReceiptParam.remittanceGroup.updated_at)
    const today = dayjs()
    const statusType = this.transactionReceiptParam.statusType
    const isRefunded = statusType === StatusType.REFUNDED
    const corpInfo = this.transactionReceiptParam.corpInfo
    const askText = isRefunded ? 'Refund process could take maximum 1 business day from the issuing date' : ''
    return {
      title: (isRefunded ? 'Refund' : 'Deposit') + ' Receipt',
      issueInfo: {
        DATE: isRefunded ? updatedAt.format('YYYY-MM-DD') : paymentCompletedAt.format('YYYY-MM-DD'),
        TO: corpInfo.corp_name
      },
      infoText: [
        `TRANSACTION GROUP NO. ${this.transactionReceiptParam.remittanceGroup.group_code}`,
        '* All amounts expressed in Korean Won (KRW) unless otherwise specified.'
      ],
      grandTotal: `Total Deposit Amount: KRW ${formattersMap.number(this.grandTotal)}`,
      askText,
      bankInfo: {
        'Bank Name': corpInfo.bank_account_name,
        'Account Number': corpInfo.bank_account_number
      },
      fileName: isRefunded
        ? `${today.format('YYYYMMDD')}_refund_receipt.pdf`
        : `${paymentCompletedAt.format('YYYYMMDD')}_deposit_receipt.pdf`
    }
  }

  private getAutoTableOption(tableList: any, startYDistance: number = 0): Dictionary<any> {
    const tableOptions: Dictionary<any> = {
      startY: this.getNextPositionY(startYDistance),
      startX: 10,
      theme: 'plain',
      styles: { halign: 'center', fontSize: 10, cellPadding: 1, lineWidth: 0.1 }
    }
    const createdTableOptions = this.createdTableHeaderAndBody(tableList)
    tableOptions.head = createdTableOptions.head
    tableOptions.body = createdTableOptions.body
    return tableOptions
  }

  private printPageNumbering(current: number, total: number) {
    const numbering = `${current} / ${total}`
    this.doc.setFontSize(10)
    this.doc.text(numbering, this.pageStandard.width - 30, 10)
  }

  private printFooterText() {
    // footer text
    const footerText = 'This is a computer-generated document. No signature is required.'
    const footerY = this.pageStandard.height - 5
    this.doc.setFontSize(11)
    this.doc.text(footerText, this.getAlignCenterPositionX(footerText) + 55, footerY)
  }

  async printDocument(): Promise<void> {
    await this.printLogoHeader()

    // Title
    const title = this.textContents.title?.toUpperCase() || ''
    this.doc.setFontSize(18)
    this.doc.text(title, this.getAlignCenterPositionX(title) + 5, this.getNextPositionY(1))

    // to from text
    const issueInfoTexts = this.textContents.issueInfo
    this.finalY = this.printTextObject(issueInfoTexts, 10, this.getNextPositionY(1))

    // info text
    this.doc.setFontSize(11)
    this.textContents.infoText.forEach((text: string) => {
      this.doc.text(text, 10, this.finalY)
      this.finalY += 6
    })

    // first page table
    const firstPageLimit = 15
    const nextPageLimit = 20
    const totalTransactions = this.transactionsContent.length
    const totalPage =
      totalTransactions > firstPageLimit ? Math.floor((totalTransactions - firstPageLimit) / nextPageLimit) + 2 : 1
    const firstPageList = this.transactionsContent.splice(0, firstPageLimit)
    let lastPageLength = firstPageList.length
    const autoTableOption = this.getAutoTableOption(firstPageList)
    this.doc.autoTable(autoTableOption)
    this.printFooterText()
    this.printPageNumbering(1, totalPage)
    if (totalPage > 1) {
      for (let page = 2; page <= totalPage; page++) {
        this.doc.addPage()
        this.finalY = 0
        await this.printLogoHeader()
        const nextPageList = this.transactionsContent.splice(0, nextPageLimit)
        lastPageLength = nextPageList.length
        if (lastPageLength) {
          const autoTableOption = this.getAutoTableOption(nextPageList, 1)
          this.doc.autoTable(autoTableOption)
        }
        this.printFooterText()
        this.printPageNumbering(page, totalPage)
      }
    }

    // grand total
    const distanceRate = 0.6
    const distanceFromTable = lastPageLength * distanceRate + distanceRate * 2.5
    const totalText = this.textContents.grandTotal
    this.doc.setFontSize(13)
    this.doc.text(totalText, 190, this.getNextPositionY(distanceFromTable))
    const totalNumberText = `Total Number of Transactions : ${totalTransactions - 1}`
    this.doc.setFontSize(12)
    this.doc.text(totalNumberText, 190, this.getNextPositionY(0.7))

    // bankInfo contents
    const isRefundReceipt = !!this.textContents.askText
    if (isRefundReceipt) {
      this.finalY = this.pageStandard.height - 35
      const askText = this.textContents.askText
      this.doc.setFontSize(11)
      this.doc.text(askText, 10, this.getNextPositionY(0))
      const bankInfoText = this.textContents.bankInfo
      const lineHeight = 2.5
      this.printTextObject(bankInfoText, 10, this.getNextPositionY(), 10, lineHeight)
    }

    this.doc.save(this.textContents.fileName)
    // window.open(URL.createObjectURL(this.doc.output('blob', { filename: this.textContents.fileName })))
  }
}
