import { ExportingParam } from '@/types/apiRequest/ExportingParam'
import { ReportingField } from '@/types/ReportingField'
import { sentbizApi } from './axios'

export interface GetReportingResponse {
  code: number;
  message: string;
  data?: {
    list: Array<ReportingField>
  }
}

export class GetReporting {
  private static instance: GetReporting
  private constructor () {}

  public static getInstance (): GetReporting {
    if (!this.instance) this.instance = new GetReporting()
    return this.instance
  }

  public async request (params: ExportingParam): Promise<GetReportingResponse> {
    const option: RequestOption = {
      url: 'hades/krbank/our/accounting',
      method: 'get',
      params
    }
    const response: GetReportingResponse = await sentbizApi.request(option)
    return response
  }
}
