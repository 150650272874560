



























import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class BasicDialog extends Vue {
  @Prop(String) title!: string
  @Prop(String) outerClass!: string
  @Prop(String) innerClass!: string
  @Prop(String) content!: string
  @Prop(Boolean) useDivider!: boolean
  @Prop(Boolean) appendToBody!: boolean
  @Prop({
    type: Boolean,
    required: false,
    default: false
  }) closeOnClickModal!: boolean
  @Prop(String) size!: undefined | null | 'mini' | 'large'
  visible = true
}
