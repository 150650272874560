import { IApiRequest } from '@/adapters/axios/IApiRequest'
import { sentbizApi } from '@/adapters/axios'
import { AffiliateDocument } from '@/adapters/affiliate/Corp'

export interface GetDocumentsResponse {
  corp_id: string
  list: Array<AffiliateDocument>
}

export interface GetDocuments extends IApiRequest {
  getInstance (): GetDocuments
  request (params: { corp_id: string }): Promise<GetDocumentsResponse>
}
export class GetDocuments implements GetDocuments {
  private static instance: GetDocuments
  private constructor () {}
  public static getInstance (): GetDocuments {
    if (!this.instance) this.instance = new GetDocuments()
    return this.instance
  }

  async request (params: { corp_id: string }): Promise<GetDocumentsResponse> {
    const requestOption: RequestOption = {
      url: 'hades/documents',
      method: 'get',
      params
    }
    const response = await sentbizApi.request(requestOption)
    return response?.data
  }
}
