







import Vue from 'vue'
import FilterAmount from '@/dome/components/filter/inputs/FilterAmount.vue'

export default Vue.extend({
  name: 'FilterAmountContainer',
  components: { FilterAmount },
  data () {
    return {
      amount: {
        from: 100000,
        to: 10000000,
        currency: 'KRW'
      }
    }
  },
  methods: {
    onChange () {
      this.$emit('onChange', this.amount)
    }
  }
})
