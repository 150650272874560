


















































































































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import DataTableOption, { tableType } from '@/types/DataTableOption'

@Component
export default class DataTable extends Vue {
  @Prop(String) columnAlign!: 'left' | 'center' | 'right'
  @Prop(Boolean) useSelection!: boolean
  @Prop(Array) tableData!: Array<Dictionary<any>>
  @Prop({
    type: Object,
    required: false,
    default() {
      return {}
    }
  })
  tableOptions!: Dictionary<DataTableOption>

  cellWidth(prop: string): string {
    const widths: Dictionary<number> = {
      transfer_id: 100,
      group_id: 120,
      corp: 200,
      received: 300,
      recipient: 250,
      amount: 300,
      status: 150,
      external_transfer: 300,
      deposit_at: 200,
      group_code: 120,
      fx_task_id: 150,
      affiliate: 150,
      base_amount: 150,
      total_fee: 150,
      total_amount: 150,
      created_at: 160,
      other_info: 260,
      payment_completed_at: 160,
      total_count: 170
    }
    return `${widths[prop] || widths.default}`
  }

  transferStatus: Dictionary<string> = {
    inprogress_count: '송금 진행중',
    failed_count: '송금 실패',
    completed_count: '송금 완료',
    refunded_count: '환불됨'
  }

  formatString(text: string) {
    return text.replace(/_/g, ' ')
  }

  getObjectTypeColumnPair(pair: Dictionary<any>) {
    return pair ? Object.keys(pair) : {}
  }

  handleSelectionChange(changeRows: any) {
    this.$emit('onSelectRow', changeRows)
  }

  isMatchOptionType(prop: string, type: tableType): boolean {
    return this.tableOptions[prop].type === type
  }

  tableRowClassName() {
    return ''
  }

  getStatusTitle(key: string) {
    return this.transferStatus[key]
  }

  get tableHead(): Array<string> {
    if (!this.tableData.length) return []
    const keys = this.tableData.reduce((prev, curr) => {
      return prev.concat(Object.keys(curr))
    }, [])
    const filteredKeys = keys.filter((key: string) => {
      return this.tableOptions && Object.keys(this.tableOptions).includes(key)
    })
    const result: Set<string> = new Set(filteredKeys)
    return Array.from(result)
  }
}
