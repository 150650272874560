













import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import OtpSignIn from '@/components/shared/OtpSignIn.vue'
import { initializeStaticValues } from '@/lib/StaticValues'

@Component({
  components: { OtpSignIn }
})
export default class Login extends Vue {
  get version(): string {
    return process.env.VUE_APP_VERSION || ''
  }

  async otpConfirmed(): Promise<void> {
    await initializeStaticValues()
    await this.$router.push('/')
  }
}
