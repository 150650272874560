import FormItemsOption from '@/types/FormItemsOption'
import { regexToPatternString } from '@/data/Regex'
import { FormValidator } from '@/presentation/FormValidator'
import { Form, IFormView } from '@/presentation/IFormView'
import { AddAccountField, MemberAccount } from '@/presentation/affiliated/AddAccountField'
import {
  CorpMembers,
  CreateCorpMembersParams, DeleteCorpMemberParam
} from '@/adapters/affiliate/CorpMembers'
import { Message, MessageBox } from 'element-ui'
import { CorpStatus } from '@/enums/CorpStatus'
import { CorpMemberPermission, Member } from '@/adapters/affiliate/Corp'
import { CountryPhoneCodes } from '@/data/CountryPhoneCodes'

export interface AddAccount extends IFormView {
  memberAccountFormOptions: Array<Array<FormItemsOption>>;
  memberAccountTitle: string;

  addMemberInfo (): void;
  subMemberInfo (index: number): void;
  onSubmit (formName: any): Promise<boolean | void>;
}

export class AddAccount implements AddAccount {
  private formValidator: FormValidator = new FormValidator()
  private corpMembers: Array<Member>
  private readonly corpId: string
  private readonly corpStatus: CorpStatus
  private readonly phoneCodes: Array<{ label: string, value: number, key: string }> = CountryPhoneCodes.map(country => {
    return { label: `${country.phone_code} (${country.name})`, value: country.phone_code, key: country.name }
  })

  formName = 'AddAccountDocuments'
  formClass = 'divided-form'

  private memberAccountField: MemberAccount = {
    email: '',
    phone_code: '',
    phone: ''
  }
  field: AddAccountField = {
    administrator: {
      email: '',
      phone_code: '',
      phone: ''
    },
    members: []
  }

  private required: FormRule = { required: true, message: 'this field is required', trigger: 'change' }
  private administratorAccountFormOption: Array<FormItemsOption> = [
    {
      prop: 'administrator.email',
      model: 'email',
      label: 'email',
      type: 'text',
      value: undefined,
      rules: [this.required, { pattern: regexToPatternString('email'), message: 'invalid email format' }]
    },
    {
      prop: 'administrator.phone_code',
      model: 'phone_code',
      label: ' ',
      placeHolder: ' ',
      type: 'select',
      class: 'phone-code-select',
      filterable: true,
      options: this.phoneCodes,
      value: undefined
    },
    {
      prop: 'administrator.phone',
      model: 'phone',
      label: 'Mobile Number',
      type: 'text',
      value: undefined,
      rules: [this.required, { pattern: regexToPatternString('number'), message: 'invalid phone number' }]
    },
    {
      prop: 'reset',
      label: 'Password',
      type: 'button',
      emitHandlerName: 'clickReset',
      formItemClass: 'reset-btn',
      placeHolder: 'Reset'
    }
  ]
  private memberAccountFormOption: Array<FormItemsOption> = [
    {
      prop: 'email',
      model: 'email',
      label: 'email',
      type: 'text',
      value: undefined,
      rules: [this.required, { pattern: regexToPatternString('email'), message: 'invalid email format' }]
    },
    {
      prop: 'phone_code',
      label: ' ',
      model: 'phone_code',
      class: 'phone-code-select',
      filterable: true,
      options: this.phoneCodes,
      type: 'select',
      value: undefined
    },
    {
      prop: 'phone',
      model: 'phone',
      label: 'Mobile Number',
      type: 'text',
      value: undefined,
      rules: [this.required, { pattern: regexToPatternString('number'), message: 'invalid phone number' }]
    },
    {
      prop: 'reset',
      label: 'Password',
      type: 'button',
      emitHandlerName: 'clickReset',
      formItemClass: 'reset-btn',
      placeHolder: 'Reset'
    }
  ]
  memberAccountFormOptions: Array<Array<FormItemsOption>> = []
  forms: Array<Form>

  constructor (corpMembers: Array<Member>, corpId: string) {
    this.corpId = corpId
    this.corpMembers = corpMembers
    this.corpStatus = corpMembers.length ? corpMembers[0].status : CorpStatus.PROCESSING
    this.setFields()
    this.forms = [
      {
        formItemOptions: this.administratorAccountFormOption
      }
    ]
  }

  private setFields (): void {
    const admin = this.corpMembers.find(member => member.permission === Number(CorpMemberPermission.ADMIN) && member.email)
    this.field.administrator = {
      id: admin?.id,
      email: admin?.email || '',
      phone_code: `${admin?.phone_code}` || ' ',
      phone: admin?.phone_number.toString() || ''
    }
    const members = this.corpMembers.filter(member => member.permission === Number(CorpMemberPermission.MEMBER))
    this.field.members = members.map(member => {
      const memberField = {
        id: member.id,
        email: member.email,
        phone_code: `${member.phone_code}`,
        phone: member.phone_number.toString()
      }
      this.addMemberInfo()
      return memberField
    })
  }

  addMemberInfo (): void {
    const addLength = this.memberAccountFormOptions.length
    const addMemberInfoFormOption = Array(...this.memberAccountFormOption).map(option => {
      const newOption = Object.assign({}, option)
      newOption.prop = `members.${addLength}.${newOption.prop}`
      return newOption
    })
    this.field.members.push(Object.assign({}, this.memberAccountField))
    this.memberAccountFormOptions.push(addMemberInfoFormOption)
  }

  async subMemberInfo (index: number): Promise<void> {
    if (!this.field.members[index]) return
    const isStoredMember: boolean = !!this.corpMembers.length
    if (isStoredMember) {
      const isConfirmed = await MessageBox.confirm('정말 삭제하시겠습니까?', {
        confirmButtonText: '삭제하기',
        confirmButtonClass: 'is-plain',
        cancelButtonText: '취소'
      }).then(() => true).catch(() => false)
      if (!isConfirmed) return
      const member = this.field.members[index]
      const deleteParam: DeleteCorpMemberParam = { corp_member_id: member.id as string }
      const response = await CorpMembers.getInstance().delete(deleteParam)
      const isSuccessResponse = response.code === 200
      if (!isSuccessResponse) return
      Message.success('삭제되었습니다.')
    }
    this.field.members.splice(index, 1)
    this.memberAccountFormOptions.length = 0
    this.field.members.forEach((member, index) => {
      const addMemberInfoFormOption = Array(...this.memberAccountFormOption).map(option => {
        const newOption = Object.assign({}, option)
        newOption.prop = `members.${index}.${newOption.prop}`
        return newOption
      })
      this.memberAccountFormOptions.push(addMemberInfoFormOption)
    })
  }

  async createCorpMembers (): Promise<boolean> {
    const duplicatedEmails: Array<string> = []
    const emailSet: Set<string> = new Set<string>()
    emailSet.add(this.field.administrator.email)
    const params: CreateCorpMembersParams = {
      list: [
        {
          id: this.field.administrator.id,
          corp_id: this.corpId,
          email: this.field.administrator.email,
          phone_code: Number(this.field.administrator.phone_code),
          phone_number: this.field.administrator.phone,
          permission: CorpMemberPermission.ADMIN,
          status: this.corpStatus
        },
        ...this.field.members.map(member => {
          if (emailSet.has(member.email)) duplicatedEmails.push(member.email)
          emailSet.add(member.email)
          return {
            id: member.id,
            corp_id: this.corpId,
            email: member.email,
            phone_code: Number(member.phone_code),
            phone_number: member.phone,
            permission: CorpMemberPermission.MEMBER,
            status: this.corpStatus
          }
        })
      ]
    }
    const isDuplicatedEmail = !!duplicatedEmails.length
    if (isDuplicatedEmail) {
      const message = `중복 입력된 이메일이 존재합니다.<br>${duplicatedEmails.join('<br>')}`
      await MessageBox.alert(message, {
        showCancelButton: true,
        showConfirmButton: false,
        dangerouslyUseHTMLString: true,
        cancelButtonText: '닫기',
        center: true
      }).catch(() => {})
      return false
    }
    const response = await CorpMembers.getInstance().put(params)
    const isSuccessResponse = response.code === 200
    if (isSuccessResponse) this.corpMembers = params.list
    return isSuccessResponse
  }

  submitLabel = 'Save'

  async onSubmit (formName: any): Promise<boolean | void> {
    let isValid = await this.formValidator.validate(formName)
    if (!isValid) return isValid
    isValid = await this.createCorpMembers()
    return isValid
  }
}
