import { IWalletUseCase, WalletUseCase } from '@/usecase/wallet/WalletUseCase'
import { Wallet } from '@/types/wallet/Wallet'
import { IAdjustWallet } from '@/presentation/wallet/model/IAdjustWallet'
import { IWalletPresentation } from './model/IWalletPresentation'

export class WalletPresentation implements IWalletPresentation {
  useCase: IWalletUseCase = new WalletUseCase()
  corpId: string = ''
  wallet!: Wallet
  adjustWalletField: IAdjustWallet = {
    balance: undefined,
    isSubtract: true,
    memo: ''
  }

  public setCorpId (corpId: string) {
    this.corpId = corpId
  }

  public async getWallet (): Promise<Wallet> {
    this.wallet = (await this.useCase.getWallet({ corp_id: Number(this.corpId) }))[0]
    return this.wallet
  }

  public async refundFromWallet (): Promise<boolean> {
    const response = await this.useCase.refundFromWallet(this.wallet.id, this.adjustWalletField)
    return response
  }
}
