

























import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      input: ''
    }
  },
  methods: {
    onClickInputBtn(): void {
      this.$emit('memoInputted', this.input)
      this.input = ''
    }
  }
})
