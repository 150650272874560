import { IApiRequest } from '@/adapters/axios/IApiRequest'
import { sentbizApi } from '@/adapters/axios'
import { CorpStatus } from '@/enums/CorpStatus'
import { Corp } from '@/adapters/affiliate/Corp'

export interface SearchCorpParam {
  page: number
  unit: number
  corp_id?: number
  corp_name?: number
  corp_status: CorpStatus | Array<CorpStatus>
  created_at?: {
    from: Date
    to: Date
  }
  deactivated_filter?: boolean
}

export interface SearchCorpResponse {
  count: number
  list: Array<Corp>
}

export interface SearchCorp extends IApiRequest {
  getInstance (): SearchCorp
  request (params: SearchCorpParam): Promise<SearchCorpResponse>
}
export class SearchCorp implements SearchCorp {
  private static instance: SearchCorp
  private constructor () {}
  public static getInstance (): SearchCorp {
    if (!this.instance) this.instance = new SearchCorp()
    return this.instance
  }

  async request (params: SearchCorpParam): Promise<SearchCorpResponse> {
    const requestOption: RequestOption = {
      url: 'hades/corps/search',
      method: 'post',
      data: params
    }
    const response = await sentbizApi.request(requestOption)
    return response?.data
  }
}
