






import Vue, { PropType } from 'vue'
import { FraudStatus } from '@/dome/constants/FraudStatus'

export default Vue.extend({
  name: 'FraudStatusColumn',
  props: {
    fraudStatus: {
      type: Number as PropType<FraudStatus>,
      required: true
    }
  },
  computed: {
    isSuccess (): boolean {
      return this.fraudStatus % 100 === 0
    },
    statusClass (): string {
      const modifierClass = this.isSuccess ? 'column_status_chip-success' : 'column_status_chip-fail'
      return `column_status_chip ${modifierClass}`
    },
    content (): string {
      return FraudStatus[this.fraudStatus as FraudStatus]
    }
  }
})
