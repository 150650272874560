import { RootState } from '@/types/RootState'
import { Module } from 'vuex'
import {
  IFraudStaticsAlert,
  IFraudCorp,
  IFraudFilter,
  IFraudStatics,
  IFraudTransaction,
  IFraudTransactionDetail
} from '../types/FraudTypes'
import { IFraudStaticsResponse } from '@/dome/types/api/FraudApiTypes'
import fraudApi from '@/dome/api/fraudApi'
import dayjs from 'dayjs'
import { FraudStatus } from '@/dome/constants/FraudStatus'
import { Message } from 'element-ui'
import { Dictionary } from 'vue-router/types/router'
import { GetFxRates } from '@/adapters/fxRates/GetFxRates'

export interface FraudTransactionState {
  page: number
  fraudStatics: IFraudStatics
  fraudStaticsAlert: IFraudStaticsAlert
  fraudTransactions: Array<IFraudTransaction>
  fraudTransactionsForSheet: Array<IFraudTransaction>
  totalFraudTransaction: number
  fraudTransactionDetail: IFraudTransactionDetail
  corpId: number
  filter: IFraudFilter
  corps: Array<IFraudCorp>
}

export enum FraudTransactionGetters {
  Page = 'PAGE',
  FraudStatics = 'FRAUD_STATICS',
  FraudStaticsAlert = 'FRAUD_STATICS_ALERT',
  FraudTransactions = 'FRAUD_TRANSACTIONS',
  FraudTransactionsForSheet = 'FRAUD_TRANSACTIONS_FOR_SHEET',
  TotalFraudTransaction = 'TOTAL_FRAUD_TRANSACTION',
  FraudTransactionDetail = 'FRAUD_TRANSACTION_DETAIL',
  FraudFilter = 'FRAUD_FILTER',
  CorpID = 'CORP_ID',
  Corps = 'CORPS'
}

export enum FraudTransactionMutations {
  SetPage = 'SET_PAGE',
  SetFraudStatics = 'SET_FRAUD_STATICS',
  SetFraudStaticsAlert = 'SET_FRAUD_STATICS_ALERT',
  SetFraudTransactions = 'SET_FRAUD_TRANSACTIONS',
  SetFraudTransactionsForSheet = 'SET_FRAUD_TRANSACTIONS_FOR_SHEET',
  SetTotalFraudTransaction = 'SET_TOTAL_FRAUD_TRANSACTION',
  SetFraudTransactionDetail = 'SET_FRAUD_TRANSACTION_DETAIL',
  SetFraudFilter = 'SET_FRAUD_FILTER',
  SetCorpID = 'SET_CORP_ID',
  SetCorps = 'SET_CORPS'
}

export enum FraudTransactionActions {
  FetchFraudStatics = 'FETCH_FRAUD_STATICS',
  FetchFraudStaticsAlert = 'FETCH_FRAUD_STATICS_ALERT',
  FetchFraudTransactions = 'FETCH_FRAUD_TRANSACTIONS',
  FetchFraudTransactionsForSheet = 'FETCH_FRAUD_TRANSACTIONS_FOR_SHEET',
  ApplyFraudFilter = 'APPLY_FRAUD_FILTER',
  FetchFraudTransactionDetail = 'FETCH_FRAUD_TRANSACTION_DETAIL',
  UpdateFraudTransactionStatus = 'UPDATE_FRAUD_TRANSACTION_STATUS',
  PostFraudTransactionMemo = 'POST_FRAUD_TRANSACTION_MEMO',
  UpdateFraudTransactionMemo = 'UPDATE_FRAUD_TRANSACTION_MEMO',
  DeleteFraudTransactionMemo = 'DELETE_FRAUD_TRANSACTION_MEMO',
  UpdateCorpId = 'UPDATE_CORP_ID',
  FetchCorps = 'FETCH_CORPS'
}

export interface postTransactionMemoParam {
  payment_id: number
  writer: string
  content: string
}

export const fraudTransactionState: Module<FraudTransactionState, RootState> = {
  namespaced: true,
  state: {
    page: 1,
    fraudStatics: {
      total_count: 0,
      total_amount: 0,
      block_count: 0,
      block_amount: 0,
      chargeback_accept_count: 0,
      chargeback_fraud_count: 0,
      chargeback_fraud_amount: 0,
      dispute_lost_amount: 0,
      dispute_won_amount: 0,
      dispute_won_count: 0,
      dispute_lost_count: 0,
      fraud_count: 0,
      pass_amount: 0,
      pass_count: 0
    },
    fraudStaticsAlert: {
      total_amount: 0,
      block_amount: 0,
      block_count: 0,
      total_count: 0,
      dispute_won_count: 0,
      dispute_lost_count: 0,
      dispute_won_amount: 0,
      dispute_lost_amount: 0,
      chargeback_fraud_count: 0,
      chargeback_fraud_amount: 0,
      pass_amount: 0,
      pass_count: 0
    },
    fraudTransactions: [],
    fraudTransactionsForSheet: [],
    totalFraudTransaction: 0,
    fraudTransactionDetail: {
      id: 0,
      amount: 0,
      currency: '',
      system_status: 0,
      created_at: new Date(),
      customer_name: '',
      customer_email: '',
      payment_type: 0,
      card_number: '',
      tid: '',
      order_number: '',
      fraud_payment_items: [],
      merchandise_name: '',
      fraud_id: '',
      fraud_score: 0,
      fraud_score_reasons: [
        {
          name: '',
          value: '',
          details: ''
        }
      ],
      seller_name: '',
      seller_id: '',
      fraud_status: 0,
      fraud_timeline: [
        {
          id: 0,
          action: 0,
          param: {
            writer: '',
            content: '',
            fraud_status: 0
          },
          created_at: new Date()
        }
      ]
    },
    corpId: 0,
    filter: {},
    corps: []
  },
  mutations: {
    [FraudTransactionMutations.SetPage](state, page: number) {
      state.page = page
    },
    [FraudTransactionMutations.SetFraudStatics](state, fraudStatics: IFraudStatics) {
      state.fraudStatics = fraudStatics
    },
    [FraudTransactionMutations.SetFraudStaticsAlert](state, fraudStaticsAlert: IFraudStaticsAlert) {
      state.fraudStaticsAlert = fraudStaticsAlert
    },
    [FraudTransactionMutations.SetFraudTransactions](state, fraudTransactions: Array<IFraudTransaction>) {
      state.fraudTransactions = fraudTransactions
    },
    [FraudTransactionMutations.SetFraudTransactionsForSheet](state, fraudTransactions: Array<IFraudTransaction>) {
      state.fraudTransactionsForSheet = fraudTransactions
    },
    [FraudTransactionMutations.SetTotalFraudTransaction](state, totalCount: number) {
      state.totalFraudTransaction = totalCount
    },
    [FraudTransactionMutations.SetFraudTransactionDetail](state, fraudTransactionDetail) {
      state.fraudTransactionDetail = fraudTransactionDetail.data.list[0]
    },
    [FraudTransactionMutations.SetFraudFilter](state, filter) {
      state.filter = filter
    },
    [FraudTransactionMutations.SetCorpID](state, id: number) {
      state.corpId = id
    },
    [FraudTransactionMutations.SetCorps](state, corps: Array<IFraudCorp>) {
      state.corps = corps
    }
  },
  getters: {
    [FraudTransactionGetters.Page](state): number {
      return state.page
    },
    [FraudTransactionGetters.FraudStatics](state): IFraudStatics {
      return state.fraudStatics
    },
    [FraudTransactionGetters.FraudStaticsAlert](state): IFraudStaticsAlert {
      return state.fraudStaticsAlert
    },
    [FraudTransactionGetters.FraudTransactions](state): Array<IFraudTransaction> {
      return state.fraudTransactions
    },
    [FraudTransactionGetters.FraudTransactionsForSheet](state): Array<IFraudTransaction> {
      return state.fraudTransactionsForSheet
    },
    [FraudTransactionGetters.TotalFraudTransaction](state): number {
      return state.totalFraudTransaction
    },
    [FraudTransactionGetters.FraudTransactionDetail](state): IFraudTransactionDetail {
      return state.fraudTransactionDetail
    },
    [FraudTransactionGetters.FraudFilter](state): IFraudFilter {
      return state.filter
    },
    [FraudTransactionGetters.CorpID](state): number {
      return state.corpId
    },
    [FraudTransactionGetters.Corps](state): Array<IFraudCorp> {
      return state.corps
    }
  },
  actions: {
    async [FraudTransactionActions.FetchFraudStatics]({ getters, commit }): Promise<void> {
      const filter: IFraudFilter = getters[FraudTransactionGetters.FraudFilter]
      filter.corp_id = getters[FraudTransactionGetters.CorpID]
      if (!filter.corp_id) delete filter.corp_id
      const response = await fraudApi.fetchFraudStatics(filter)
      const fraudStaticsResponse: IFraudStaticsResponse = response.data?.list[0]
      const fraudStatics: IFraudStatics = {
        ...fraudStaticsResponse,
        chargeback_fraud_count: Number(fraudStaticsResponse.chargeback_fraud_count),
        chargeback_accept_count: Number(fraudStaticsResponse.chargeback_accept_count),
        block_count: Number(fraudStaticsResponse.block_count),
        fraud_count: Number(fraudStaticsResponse.fraud_count),
        dispute_won_count: Number(fraudStaticsResponse.dispute_won_count),
        dispute_lost_count: Number(fraudStaticsResponse.dispute_lost_count),
        total_count: Number(fraudStaticsResponse.total_count)
      }
      commit(FraudTransactionMutations.SetFraudStatics, fraudStatics)
    },
    async [FraudTransactionActions.FetchFraudStaticsAlert]({ getters, commit }): Promise<void> {
      const corpId = getters[FraudTransactionGetters.CorpID]
      if (!corpId) return
      const filter: IFraudFilter = {
        corp_id: corpId,
        period: {
          from: dayjs().startOf('month').startOf('day').add(9, 'hour').toDate(),
          to: dayjs().endOf('day').add(9, 'hour').toDate()
        }
      }
      const response = await fraudApi.fetchFraudStatics(filter)
      const fraudStaticsResponse: IFraudStaticsResponse = response.data?.list[0]
      const fraudStaticsAlert: IFraudStaticsAlert = {
        ...fraudStaticsResponse,
        dispute_won_amount: Number(fraudStaticsResponse.dispute_won_amount),
        dispute_lost_amount: Number(fraudStaticsResponse.dispute_lost_amount),
        chargeback_fraud_count: Number(fraudStaticsResponse.chargeback_fraud_count),
        block_count: Number(fraudStaticsResponse.block_count) || 0,
        dispute_won_count: Number(fraudStaticsResponse.dispute_won_count) || 0,
        dispute_lost_count: Number(fraudStaticsResponse.dispute_lost_count) || 0,
        total_count: Number(fraudStaticsResponse.total_count) || 0
      }
      commit(FraudTransactionMutations.SetFraudStaticsAlert, fraudStaticsAlert)
    },
    async [FraudTransactionActions.FetchFraudTransactions]({ getters, commit }, page: number = 1): Promise<void> {
      const filter: IFraudFilter = getters[FraudTransactionGetters.FraudFilter]
      filter.corp_id = getters[FraudTransactionGetters.CorpID]
      if (!filter.corp_id) delete filter.corp_id
      const requestData = {
        ...filter,
        page,
        unit: 20
      }
      const response = await fraudApi.fetchFraudTransactions(requestData)
      const totalCount = response.data.total_count
      commit(FraudTransactionMutations.SetTotalFraudTransaction, Number(totalCount))
      commit(FraudTransactionMutations.SetFraudTransactions, response.data.list)
    },
    async [FraudTransactionActions.FetchFraudTransactionsForSheet]({ getters, commit }): Promise<void> {
      const filter: IFraudFilter = getters[FraudTransactionGetters.FraudFilter]
      filter.corp_id = getters[FraudTransactionGetters.CorpID]
      if (!filter.corp_id) delete filter.corp_id
      const requestData = {
        ...filter,
        page: 1,
        unit: 1000
      }
      const response = await fraudApi.fetchFraudTransactions(requestData)
      commit(FraudTransactionMutations.SetFraudTransactionsForSheet, response.data.list)
    },
    async [FraudTransactionActions.ApplyFraudFilter]({ commit, dispatch }, filter: IFraudFilter) {
      const filterObject: Dictionary<any> = JSON.parse(JSON.stringify(filter))
      if (filterObject.amount?.currency === 'KRW') {
        const usdKrwRates = Number((await GetFxRates.get()).usd_krw)
        filterObject.amount.currency = 'USD'
        filterObject.amount.from = filterObject.amount.from / usdKrwRates
        filterObject.amount.to = filterObject.amount.to / usdKrwRates
      }
      Object.keys(filterObject).forEach((key: string) => {
        if (!filterObject[key] || !Object.keys(filterObject[key]).length) {
          delete filterObject[key]
          
        }
      })
      commit(FraudTransactionMutations.SetFraudFilter, filterObject)
      dispatch(FraudTransactionActions.FetchFraudStatics)
      dispatch(FraudTransactionActions.FetchFraudTransactions)
    },
    async [FraudTransactionActions.FetchFraudTransactionDetail]({ commit }, id: number): Promise<void> {
      const response = await fraudApi.fetchFraudTransactionDetail(id)
      commit(FraudTransactionMutations.SetFraudTransactionDetail, response)
    },
    async [FraudTransactionActions.UpdateFraudTransactionStatus](
      { getters, dispatch },
      params: { id: number; status: FraudStatus }
    ): Promise<void> {
      await fraudApi.updateFraudTransactionStatus(params)
      const page = getters[FraudTransactionGetters.Page]
      dispatch(FraudTransactionActions.FetchFraudTransactions, page)
      dispatch(FraudTransactionActions.FetchFraudStatics)
    },
    async [FraudTransactionActions.PostFraudTransactionMemo](
      { dispatch, state },
      param: postTransactionMemoParam
    ): Promise<void> {
      const response = await fraudApi.postFraudTransactionMemo(param)
      if (!response) return
      await dispatch(FraudTransactionActions.FetchFraudTransactionDetail, state.fraudTransactionDetail.id)
    },
    async [FraudTransactionActions.UpdateFraudTransactionMemo](
      { dispatch, state },
      param: postTransactionMemoParam
    ): Promise<void> {
      const response = await fraudApi.updateFraudTransactionMemo(param)
      if (!response) return
      await dispatch(FraudTransactionActions.FetchFraudTransactionDetail, state.fraudTransactionDetail.id)
    },
    async [FraudTransactionActions.DeleteFraudTransactionMemo]({ dispatch, state }, id: string): Promise<void> {
      const response = await fraudApi.deleteFraudTransactionMemo(id)
      if (!response) {
        Message({
          message: '오류가 발생했습니다.',
          type: 'error'
        })
        return
      }
      Message({
        message: '삭제되었습니다.',
        type: 'success'
      })
      await dispatch(FraudTransactionActions.FetchFraudTransactionDetail, state.fraudTransactionDetail.id)
    },
    async [FraudTransactionActions.FetchCorps]({ commit }) {
      const response = await fraudApi.fetchCorpIds()
      const corps: Array<IFraudCorp> = response.data.corp_basic
      commit(FraudTransactionMutations.SetCorps, corps)
    },
    async [FraudTransactionActions.UpdateCorpId]({ commit, dispatch }, corpId: number) {
      commit(FraudTransactionMutations.SetCorpID, corpId)
      dispatch(FraudTransactionActions.FetchFraudStatics)
      dispatch(FraudTransactionActions.FetchFraudTransactions)
      dispatch(FraudTransactionActions.FetchFraudStaticsAlert)
    }
  }
}
