import { sentbizApi } from '@/adapters/axios'
import { SetExternalIdParams } from '@/adapters/transfers/model/SetExternalIdParams'

export class SetExternalId {
  private static instance: SetExternalId
  private constructor () {}

  public static getInstance (): SetExternalId {
    if (!this.instance) this.instance = new SetExternalId()
    return this.instance
  }

  public async request (params: SetExternalIdParams): Promise<any> {
    const option: RequestOption = {
      url: 'hades/remittance/external',
      method: 'post',
      data: params
    }
    const response = await sentbizApi.request(option)
    return response
  }
}
