import RemittanceGroupSearch from '@/types/apiRequest/RemittanceGroupSearch'
import { sentbizApi } from '../axios'

interface ISearchGroupTransfer {
  request (params: RemittanceGroupSearch): Promise<any>
}

export class SearchGroupTransfer implements ISearchGroupTransfer {
  private static instance: SearchGroupTransfer
  private constructor () {}

  public static getInstance (): SearchGroupTransfer {
    if (!this.instance) this.instance = new SearchGroupTransfer()
    return this.instance
  }

  async request (params: RemittanceGroupSearch): Promise<any> {
    const option: RequestOption = {
      url: '/hades/remittance/group/search',
      method: 'post',
      data: params
    }
    const response: any = await sentbizApi.request(option)
    return response
  }
}
