import { CorpStatus } from '@/enums/CorpStatus'
import router from '@/router'
import DataTableOption, { ButtonType } from '@/types/DataTableOption'
import { tagType } from '@/types/RemittanceByGroup'
import dayjs from 'dayjs'
import { CompletedAffiliateTableElement } from './AffiliateCompany'
import SuperAffiliateList from './SuperAffiliateList'
import { Corp } from '@/adapters/affiliate/Corp'

export class CompletedAffiliateCompanyList extends SuperAffiliateList {
  tableOptions: Dictionary<DataTableOption> = {
    corp_id: { type: 'text', label: 'Company ID', width: 100 },
    corp_name: { type: 'object', label: 'Compnay Name', width: 150 },
    transfer_history: { type: 'button', width: 140 },
    total_usd_amount: { type: 'text', label: 'Cummulative Amount(USD)', width: 150, align: 'right' },
    remittance_count: { type: 'text', label: 'Cummulative No. of Transfers(no. of groups)', width: 110, align: 'right' },
    wallets_balance: { type: 'link', label: 'Wallet Balance(KRW)', width: 150, align: 'right' },
    adjust_wallet: { type: 'button', width: 140 },
    memo: { type: 'object', label: 'Memo', width: 400 },
    approved_at: { type: 'text', label: 'Signup Approval Date', width: 110 }
  }

  tableData: Array<CompletedAffiliateTableElement> = []

  unit = 20

  isWalletModalOpen = false
  selectedCorp?: Corp = undefined
  isWalletAdjustModalOpen = false

  setTableData () {
    this.tableData = this.corpList.map(corp => {
      const memoCallback = () => {
        this.selectedCorpId = corp.corp_id
        this.corpMemoModalContent = corp.memo
        this.isCorpMemoModalOpen = true
      }
      const buttonMemo: ButtonType = {
        icon: 'el-icon-plus',
        size: 'mini',
        circle: true,
        plain: true,
        callback: memoCallback
      }
      const textMemo: ButtonType = {
        size: 'mini',
        type: 'text',
        label: corp.memo,
        class: 'memo',
        callback: memoCallback
      }
      const memo: Dictionary<ButtonType> = corp.memo ? { _link: textMemo } : { _button: buttonMemo }
      const analogue: { _tag: tagType } | undefined = corp.analogue
        ? { _tag: { label: 'analogue', type: 'danger', size: 'mini' } }
        : undefined
      return {
        corp_id: corp.corp_id,
        corp_name: {
          _link: {
            label: corp.name,
            callback: () => {
              this.selectedCorpId = corp.corp_id
              this.isCorpModalOpen = true
            }
          },
          ...analogue
        },
        transfer_history: {
          label: 'Transfer History',
          callback: () => {
            router.push({ path: '/transfers/all', query: { corp_id: corp.corp_id } })
          }
        },
        total_usd_amount: corp.total_usd_amount.toLocaleString(),
        remittance_count: `${corp.remittance_count.toLocaleString()}(${corp.remittance_group_count})`,
        wallets_balance: {
          label: corp.wallets[0]?.balance.toLocaleString(),
          callback: () => {
            this.selectedCorp = corp
            this.isWalletModalOpen = true
          }
        },
        adjust_wallet: {
          label: 'Adjust Balance',
          callback: () => {
            this.selectedCorp = corp
            this.isWalletAdjustModalOpen = true
          }
        },
        memo,
        approved_at: dayjs(corp.approved_at).format('YYYY-MM-DD')
      }
    })
    this.tableData = Array(...this.tableData)
    this.tableData.sort((prev: CompletedAffiliateTableElement, next: CompletedAffiliateTableElement): number => {
      return parseInt(next.corp_id) - parseInt(prev.corp_id)
    })
  }

  async initializeTableData (): Promise<void> {
    const response = await this.getSearchCorp(CorpStatus.CONFIRMED)
    this.totalCount = response.count
    this.corpList = response.list
    await this.setTableData()
  }
}
