import { IApiRequest } from '@/adapters/axios/IApiRequest'
import { sentbizApi } from '@/adapters/axios'

export interface DeleteDocuments extends IApiRequest {
  getInstance (): DeleteDocuments
  request (params: { id: number; corp_id: string }): Promise<any>
}
export class DeleteDocuments implements DeleteDocuments {
  private static instance: DeleteDocuments
  private constructor () {}
  public static getInstance (): DeleteDocuments {
    if (!this.instance) this.instance = new DeleteDocuments()
    return this.instance
  }

  async request (params: { id: number; corp_id: string }): Promise<any> {
    const requestOption: RequestOption = {
      url: 'hades/document',
      method: 'delete',
      data: params
    }
    const response = await sentbizApi.request(requestOption)
    return response?.data
  }
}
