import { sentbizApi } from '../axios'
import { IApiRequest } from '../axios/IApiRequest'
import { GetWalletParam } from '@/adapters/wallet/model/GetWalletParam'
import { Wallet } from '@/types/wallet/Wallet'

export interface GetWallet extends IApiRequest {
    getInstance (): GetWallet;
    request (params: GetWalletParam): Promise<Array<Wallet>>;
}

export class GetWallet implements GetWallet {
    private static instance: GetWallet

    private constructor () {}

    public static getInstance (): GetWallet {
      if (!this.instance) this.instance = new GetWallet()
      return this.instance
    }

    async request (params: GetWalletParam): Promise<Array<Wallet>> {
      const requestOption: RequestOption = {
        url: 'wallet',
        method: 'get',
        params
      }
      const response = await sentbizApi.request(requestOption)
      return response.data.list
    }
}
