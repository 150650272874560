import { IApiRequest } from '@/adapters/axios/IApiRequest'
import { sentbizApi } from '@/adapters/axios'
import { CorpFee } from '@/presentation/affiliated/CorpFee'

export interface CorpFeesApi extends IApiRequest {
  getInstance (): CorpFeesApi
  get (params: { corp_id: string }): Promise<Array<CorpFee>>
  put (params: { corp_id: string, fees: Array<CorpFee> }): Promise<any>
}

export class CorpFeesApi implements CorpFeesApi {
  private url = 'hades/corp/fee'
  private static instance: CorpFeesApi
  private constructor () {}
  public static getInstance (): CorpFeesApi {
    if (!this.instance) this.instance = new CorpFeesApi()
    return this.instance
  }

  async put (params: { corp_id: string, fees: Array<CorpFee> }): Promise<any> {
    const requestOption: RequestOption = {
      url: this.url,
      method: 'put',
      data: params
    }
    const response = await sentbizApi.request(requestOption)
    return response
  }

  async get (params: { corp_id: string }): Promise<Array<CorpFee>> {
    const requestOption: RequestOption = {
      url: this.url,
      method: 'get',
      params
    }
    const response = await sentbizApi.request(requestOption)
    return response.data.fees
  }
}
