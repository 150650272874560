












































import Vue from 'vue'
import Component from 'vue-class-component'
import FormItems from '@/components/shared/FormItems.vue'
import { Register } from '@/presentation/affiliated/Register'
import BasicDialog from '@/components/commons/BasicDialog.vue'
import FeeModal from '@/components/affiliate/FeeModal.vue'

@Component({
  components: { FeeModal, BasicDialog, FormItems }
})
export default class AffiliatedRegister extends Vue {
  register: Register = new Register()

  openFeeDialog () {
    this.register.isOpenFeeDialog = true
  }

  onSubmitFees (fees: Array<any>) {
    this.register.isOpenFeeDialog = false
    this.register.field.fees = fees
  }

  async onSubmit () {
    await this.register.onSubmit(this.$refs[this.register.formName], this)
  }
}
